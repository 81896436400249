import React from 'react';
import Tab from 'components/ui-components/tabs/tab';
import styled from 'styled-components/macro';
import { palette } from 'components/styles';

export const RaiseTab = styled(({ isActive, ...rest }) => <Tab {...rest} />)`
  padding: 6px 12px;
  color: ${props => (props.isActive ? palette.navyBackground : palette.pureBlack)};
  transition: all 450ms ease-in-out;

  &:hover {
    background-color: ${palette.navyHover};
  }
`;
