import React from 'react';
import styled from 'styled-components/macro';
import { basicCard } from 'components/mixins';

interface CardProps {
  className?: string;
  children?: React.ReactChild;
}

export const Card: React.FC<CardProps> = props => {
  const { className, children } = props;
  return <StyledCard className={className}>{children}</StyledCard>;
};

const StyledCard = styled.div`
  ${basicCard}
`;

export default Card;
