import React from 'react';
import { TooltipTitle } from './elements';

interface Props {
  children?: React.ReactNode;
}

export const Title: React.FunctionComponent<Props> = ({ children }) => {
  return <TooltipTitle>{children}</TooltipTitle>;
};

export default Title;
