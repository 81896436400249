import styled from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';
import { xsBody, microBody, body } from 'components/mixins';
import { Container, DropdownIcon } from 'components/ui-components';
import { motion } from 'framer-motion';

export const PageWrapper = styled.div`
  flex: 1;
`;

export const FilterControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;

  > div {
    max-width: 100%;
    flex: auto;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      flex: 50%;
      max-width: 50%;
    }
  }

  p {
    ${xsBody(palette.pureBlack)};
  }
`;

export const ListTypeControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const PaginationControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const FilterControlsContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
  position: sticky;
  top: 0;
  background-color: ${palette.background};
  padding-bottom: 16px;
  padding-top: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  z-index: 20;

  > div {
    max-width: 100%;
    flex: auto;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      flex: 50%;
      max-width: 50%;
    }
  }

  p {
    ${xsBody(palette.pureBlack)};
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    position: relative;
    box-shadow: none;
  }
`;

export const FilterControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 8px;
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${palette.pureWhite};
`;

export const ProspectListContainer = styled(Container)`
  margin-bottom: 24px;

  @media only screen and (max-width: ${breakpoints['tablet-lg']}) {
    padding: 0;
  }
`;

interface PaginationControlProps {
  enabled: boolean;
}

export const PaginationControl = styled.button<PaginationControlProps>`
  padding: 3px 0;
  min-width: 24px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${palette.pureWhite};
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};

  svg {
    stroke: ${props => (props.enabled ? palette.pureBlack : palette.gray3)};
  }
`;

interface PaginationPageProps {
  active: boolean;
}

export const PaginationPage = styled.button<PaginationPageProps>`
  padding: 3px 0;
  min-width: 24px;
  border: none;
  background-color: ${props => (props.active ? palette.navyBackground : palette.pureWhite)};
  color: ${props => (props.active ? palette.pureWhite : palette.pureBlack)};
  text-align: center;
  cursor: pointer;

  p {
    ${microBody((props: PaginationPageProps) => (props.active ? palette.pureWhite : palette.pureBlack))}
  }
`;

export const SortWrapper = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    label {
      margin-right: 8px;
    }
  }
`;

export const MobileSortDropdown = styled(DropdownIcon)`
  display: block;
  margin-left: 8px;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: none;
  }
`;

export const LogoWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

export const LogoCover = styled.div`
  height: 86px;
  width: 100%;
  position: absolute;
  bottom: -86px;
  background-color: ${palette.background};
  left: 0;
  z-index: 2;

  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    height: 128px;
    bottom: -128px;
  }
`;

export const MotionLogo = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86px;
  width: 100%;
  z-index: -1;
  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    height: 128px;
  }
`;

export const MotionLine = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: 0;
  z-index: 10;

  > div {
    height: 3px;
    width: 50%;
    background-color: ${palette.navyBackground};
    transition: all 350ms ease-in-out;
  }
`;

export const FancyText = styled(motion.p)`
  ${xsBody(palette.pureBlack)};
  margin-top: 24px;
  color: ${palette.navyBackground};
  text-align: center;
  z-index: 99;
  letter-spacing: 1.5px;

  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    ${body(palette.pureBlack)};
    letter-spacing: 7.5px;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${body(palette.pureBlack)};
    letter-spacing: 6.5px;
  }

  @media only screen and (min-width: ${breakpoints.container}) {
    ${body(palette.pureBlack)};
    letter-spacing: 9.5px;
    white-space: nowrap;
  }
`;

interface ContainerProps {
  center: boolean;
}

export const WelcomeContainer = styled(Container)<ContainerProps>`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  padding-top: ${props => (props.center ? 0 : '56px')};
  height: 100vh;

  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    padding-top: ${props => (props.center ? 0 : '156px')};
    max-width: 527px;
  }
`;
