import styled, { css } from 'styled-components/macro';
import { Button } from 'components/ui-components';
import { breakpoints, palette } from 'components/styles';
import { bodyShort, xsBody } from 'components/mixins';
import { motion } from 'framer-motion';

export const TitleTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 8px;
  min-width: 0;

  > p {
    font-weight: 600;
    margin-right: 8px;
    margin-bottom: 0;
  }
`;

export const GivingHistoryListItem = styled(motion.li)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray3};

  &:first-child {
    padding-top: 0;
  }

  > div:first-child {
    margin-right: 16px;
  }
`;

export const GivingHistoryTitle = styled.p`
  ${xsBody(palette.pureBlack)}
  margin-bottom: 4px;
  font-style: ${props => props.fontStyle};
`;

export const ModalContent = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    flex-direction: row;
    padding: 32px 0;
  }

  > div:first-child {
    display: none;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      display: block;
      flex: 0 0 118px;
    }
  }

  > div:last-child {
    flex: 1;
    width: 100%;
  }
`;

export const SeeMoreButton = styled(Button)`
  margin-top: 32px;
`;

export const GivingCardContent = styled.div`
  height: 328px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    height: 396px;
  }
`;

export const GivingAggregateGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const GivingTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  height: 100%;
`;

const GivingYearsColumnStyles = css`
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;

  > li {
    padding: 12px 8px;
  }
`;

const GivingYearsRowStyles = css`
  flex-direction: row;
  margin-bottom: 20px;
`;

export const GivingYearsList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;

  ${props => (props.flexDirection === 'column' ? GivingYearsColumnStyles : GivingYearsRowStyles)};
`;

const activeListItemStyle = css`
  background-color: ${palette.brandBlue};
  min-width: 3px;
  left: 0;
`;

const disabledListItemStyle = css`
  cursor: not-allowed;
  opacity: 40%;
`;

const leftStyles = css`
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: 1px;
  background-color: ${palette.gray3};
`;

const bottomStyles = css`
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: -4px;
  left: 0;
  background-color: transparent;
`;

export const GivingYearsListItem = styled.li`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    ${props => (props.borderPosition === 'left' ? leftStyles : bottomStyles)};
    ${props => (props.active ? activeListItemStyle : '')};
  }

  button {
    ${bodyShort(palette.pureBlack)};
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: ${props => (props.active ? '600' : '400')};
    ${props => (props.disabled ? disabledListItemStyle : '')};
  }
`;

export const GivingTileContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GivingTabs = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  padding: 0 0 16px 0;
  margin: 0 0 16px 0;
  border-bottom: 1px solid ${palette.gray3};
`;

export const GivingTabListItem = styled.li`
  button {
    ${bodyShort(palette.pureBlack)};
    margin-right: 32px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-weight: ${props => (props.active ? '700' : '400')};
  }
`;

export const MobileGivingYearsList = styled(GivingYearsList)`
  display: flex;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: none;
  }
`;
