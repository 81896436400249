import React, { useState } from 'react';
import map from 'lodash/map';
import { SvgFilter } from 'components/svg-components';
import { palette } from 'components/styles';
import {
  FiltersCenterModal,
  CheckboxWrapper,
  FilterButton,
  GroupLabel,
  ModalContent,
  FilterGroupWrapper,
  FilterLoader,
  FilterCount
} from './elements';
import { CenterModal, Checkbox, Button, MultiSelect, Dropdown } from 'components/ui-components';

export const ProspectListFilters = props => {
  const { filterOptions, onFilterChange, onFilterClear, onAllFilterClear, totalCount, searching } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const dismissCenterModal = () => {
    setModalOpen(false);
  };

  const filterChecked = (groupKey, value) => {
    onFilterChange(groupKey, value);
  };

  const renderFilterOptions = (checks, checkedValues, groupKey) => {
    return map(checks, (option, index) => {
      return (
        <CheckboxWrapper key={index}>
          <Checkbox
            checked={checkedValues.includes(option.key)}
            onChange={() => filterChecked(groupKey, option.key)}
          ></Checkbox>
          <Checkbox.Label>{option.label}</Checkbox.Label>
        </CheckboxWrapper>
      );
    });
  };

  const renderCheckboxFilters = (group, index) => {
    return (
      <FilterGroupWrapper key={index}>
        <GroupLabel>{group.label}</GroupLabel>
        {renderFilterOptions(group.checks, group.checkedValues, group.url_property)}
      </FilterGroupWrapper>
    );
  };

  const renderDropdownFilters = (group, index) => {
    return (
      <FilterGroupWrapper key={index}>
        <GroupLabel>{group.label}</GroupLabel>
        <Dropdown
          options={group.checks.map(check => ({ value: check.key, label: check.label, key: check.key }))}
          value={group.checkedValues.length === 0 ? '' : group.checkedValues[0]}
          onSelectionChange={option => {
            group.checkedValues = [];
            filterChecked(group.url_property, option);
          }}
          clearable={true}
        />
      </FilterGroupWrapper>
    );
  };

  const renderMultiSelectFilters = (group, index) => {
    return (
      <FilterGroupWrapper key={index}>
        <GroupLabel>{group.label}</GroupLabel>
        <MultiSelect
          options={group.checks}
          checkedValues={group.checkedValues}
          clearSelectionHandler={() => onFilterClear(group.url_property)}
          selectionHandler={itemKey => filterChecked(group.url_property, itemKey)}
        />
      </FilterGroupWrapper>
    );
  };
  const renderFilterGroups = () => {
    return map(filterOptions, (group, index) => {
      const groupTypes = {
        CHECKBOX: renderCheckboxFilters,
        MULTI: renderMultiSelectFilters,
        DROPDOWN: renderDropdownFilters
      };

      return groupTypes[group.type](group, index);
    });
  };

  const renderFilterCount = () => {
    let count = 0;
    let renderCount;

    for (const group of filterOptions) {
      if (group.checkedValues && group.checkedValues.length > 0 && group.checkedValues[0] !== '') {
        count = count += group.checkedValues.length;
      }
    }

    if (count > 0) {
      renderCount = (
        <FilterCount>
          <p>{count}</p>
        </FilterCount>
      );
    }

    return renderCount;
  };

  return (
    <>
      <FilterButton buttonStyle="tertiary" onClick={() => setModalOpen(true)}>
        <SvgFilter />
        <p>Filters</p>
        {renderFilterCount()}
      </FilterButton>
      <FiltersCenterModal opened={modalOpen}>
        <CenterModal.Header toggleOpened={dismissCenterModal}>
          <h2>Filters</h2>
        </CenterModal.Header>
        <ModalContent>{renderFilterGroups()}</ModalContent>
        <CenterModal.Footer>
          <div>
            <Button buttonStyle="secondary" onClick={onAllFilterClear}>
              <Button.Label fontWeight={400} color={palette.pureBlack}>{`Clear All`}</Button.Label>
            </Button>
          </div>
          <Button buttonStyle="primary" onClick={dismissCenterModal}>
            {!searching && <Button.Label color={palette.pureWhite}>{`VIEW ${totalCount} PROSPECTS`}</Button.Label>}
            {searching && (
              <Button.Label color={palette.pureWhite}>
                {`VIEW`}
                <FilterLoader size={16} color={palette.pureWhite} />
                {`PROSPECTS`}
              </Button.Label>
            )}
          </Button>
        </CenterModal.Footer>
      </FiltersCenterModal>
    </>
  );
};

ProspectListFilters.propTypes = {};

export default ProspectListFilters;
