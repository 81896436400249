import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import times from 'lodash/times';
import findIndex from 'lodash/findIndex';
import { motion } from 'framer-motion';
import { ArrowRight } from 'react-feather';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

import { BasicTile, Tooltip } from 'components/ui-components';
import { CenterModal } from 'components/ui-components';
import {
  TitleTooltipWrapper,
  GivingHistoryListItem,
  ModalContent,
  SeeMoreButton,
  GivingCardContent,
  GivingTabs,
  GivingTabListItem,
  GivingTileWrapper,
  GivingTileContent,
  GivingHistoryTitle,
  GivingAggregateGroup,
  GivingYearsList,
  GivingYearsListItem,
  MobileGivingYearsList
} from './elements';

const getGivingYears = () => {
  const thisYear = moment().year();
  const years = [];

  times(thisYear - 2018, index => {
    years.push(thisYear - index);
  });

  return years;
};
const givingYears = getGivingYears();

const ProspectGivingTile = props => {
  const { prospect } = props;
  const [activeGivingTab, setActiveGivingTab] = useState('OVERVIEW');
  const [activeYear, setActiveYear] = useState('0');
  const [yearList, setYearList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackViewGiving = useTrackEvent(appInsights, 'PROFILE | See More Giving History', prospect);
  const dismissCenterModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const data = givingYears.map(year => {
      let formattedYearData = { disabled: true };
      const foundYear = find(prospect.givingHistory, { year: year });
      formattedYearData = foundYear ? { ...foundYear, disabled: false } : { ...formattedYearData, year: year };
      return formattedYearData;
    });

    const activeYears = data.filter(year => !year.disabled);
    const latestYear = activeYears[0] ? activeYears[0].year : '';

    setActiveYear(latestYear);
    setYearList(data);
  }, [prospect.givingHistory]);

  const openCenterModal = () => {
    setModalOpen(true);
    trackViewGiving();
  };

  const renderTextListItems = () => {
    let renderItems;
    const foundYear = find(yearList, { year: activeYear });
    const yearGifts = foundYear.gifts;

    if (yearGifts && yearGifts.length > 0) {
      const sorted = orderBy(yearGifts, ['amount'], ['desc']);
      renderItems = sorted.map((historyItem, index) => {
        const animations = {
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          transition: { delay: index * 0.03 }
        };

        return (
          <GivingHistoryListItem {...animations} key={`${activeYear}${historyItem.org_name}`}>
            <div>
              <GivingHistoryTitle>{historyItem.org_name}</GivingHistoryTitle>
              <GivingHistoryTitle fontStyle={'italic'}>
                {historyItem?.org_category || 'Uncategorized'}
              </GivingHistoryTitle>
            </div>
            <GivingHistoryTitle>{`$${prospect.getFormattedUSDollars(historyItem.amount)}`}</GivingHistoryTitle>
          </GivingHistoryListItem>
        );
      });
    }

    return <>{renderItems}</>;
  };

  const renderGivingTabs = () => {
    return (
      <>
        <GivingTabListItem active={activeGivingTab === 'OVERVIEW'}>
          <button onClick={() => setActiveGivingTab('OVERVIEW')}>Overview</button>
        </GivingTabListItem>
        <GivingTabListItem active={activeGivingTab === 'ANNUAL'}>
          <button onClick={() => setActiveGivingTab('ANNUAL')}>Annual Giving</button>
        </GivingTabListItem>
      </>
    );
  };

  const renderEmptyState = () => {
    let renderEmptyState;

    if (prospect.givingHistory && prospect.givingHistory.length === 0) {
      renderEmptyState = (
        <BasicTile.Description textAlign={'center'} fontWeight={600}>
          {`There is no highlighted giving for this donor between ${givingYears[0]} and ${
            givingYears[givingYears.length - 1]
          }`}
        </BasicTile.Description>
      );
    }

    return renderEmptyState;
  };

  const renderSeeMore = () => {
    let seeMoreRender;

    if (prospect.givingHistory && prospect.givingHistory.length > 0) {
      seeMoreRender = (
        <SeeMoreButton buttonStyle="secondary" onClick={openCenterModal}>
          <strong>HIGHLIGHTED GIFT DETAILS</strong>
          <ArrowRight size={18} />
        </SeeMoreButton>
      );
    }

    return seeMoreRender;
  };

  const renderAggregateContentBlocks = () => {
    return yearList.map(yearData => {
      const isActive = activeYear === yearData.year;

      if (!yearData.disabled && isActive) {
        const animations = {
          initial: { opacity: 0, y: 30 },
          animate: { opacity: isActive ? 1 : 0, y: isActive ? 0 : 30 },
          transition: { duration: 0.6 }
        };

        return (
          <motion.div key={yearData.year} {...animations}>
            <GivingAggregateGroup>
              <div>
                <BasicTile.Description fontWeight={600}>Est. Value of Gifts</BasicTile.Description>
                <BasicTile.Title fontWeight={400}>{`$${prospect.getFormattedUSDollars(
                  yearData.total_gift_amount
                )}`}</BasicTile.Title>
              </div>
              <div>
                <BasicTile.Description fontWeight={600}>Highlighted Gifts</BasicTile.Description>
                <BasicTile.Title fontWeight={400}>{yearData.total_gift_count}</BasicTile.Title>
              </div>
            </GivingAggregateGroup>
            <GivingAggregateGroup>
              <div>
                <BasicTile.Description fontWeight={600}>Largest Gift Recipient</BasicTile.Description>
                <BasicTile.Title fontWeight={400}>{`$${prospect.getFormattedUSDollars(
                  yearData.largest_gift_recipient.amount
                )}`}</BasicTile.Title>
                <BasicTile.Description fontWeight={400}>
                  {yearData?.largest_gift_recipient?.org_name}
                </BasicTile.Description>
              </div>
            </GivingAggregateGroup>
          </motion.div>
        );
      }

      return null;
    });
  };

  const activateYear = (year, isDisabled) => {
    if (!isDisabled) {
      setActiveYear(year);
    }
  };

  const renderYears = borderPosition => {
    return givingYears.map(year => {
      const isActive = year === activeYear;
      const isDisabled = findIndex(prospect.givingHistory, { year: year }) === -1;
      return (
        <GivingYearsListItem key={year} active={isActive} disabled={isDisabled} borderPosition={borderPosition}>
          <button onClick={() => activateYear(year, isDisabled)}>{year}</button>
        </GivingYearsListItem>
      );
    });
  };

  return (
    <>
      <BasicTile>
        <TitleTooltipWrapper>
          <BasicTile.SubTitle>{`${prospect.PreferredFirstName}'s Highlighted Giving`}</BasicTile.SubTitle>
          <Tooltip>
            <Tooltip.Title>Highlighted Giving</Tooltip.Title>
            <Tooltip.Paragraph>{`Atticus sources disclosed giving data for each major donor and highlights a subset of those gifts to provide a feel for the major donor's recent giving. Other significant gifts may be mentioned in the Donor Insights section.`}</Tooltip.Paragraph>
          </Tooltip>
        </TitleTooltipWrapper>
        <GivingTileWrapper>
          <GivingYearsList>{renderYears()}</GivingYearsList>
          <GivingTileContent>
            <>{renderAggregateContentBlocks()}</>
            {renderSeeMore()}
            {renderEmptyState()}
          </GivingTileContent>
        </GivingTileWrapper>
      </BasicTile>
      <BasicTile.Description>
        <strong>Relevant</strong> highlights of recent disclosed giving; see Donor Insights for other highlights.
      </BasicTile.Description>
      <CenterModal opened={modalOpen}>
        <CenterModal.Header toggleOpened={dismissCenterModal}>
          <h2>Highlighted Giving</h2>
        </CenterModal.Header>
        <ModalContent>
          <div>
            <GivingYearsList flexDirection={'column'}>{renderYears('left')}</GivingYearsList>
          </div>
          <div>
            <GivingTabs>{renderGivingTabs()}</GivingTabs>
            <MobileGivingYearsList>{renderYears('bottom')}</MobileGivingYearsList>
            <GivingCardContent>
              {activeGivingTab === 'OVERVIEW' && <>{renderAggregateContentBlocks()}</>}
              {activeGivingTab === 'ANNUAL' && <>{renderTextListItems(true)}</>}
            </GivingCardContent>
          </div>
        </ModalContent>
      </CenterModal>
    </>
  );
};

export default ProspectGivingTile;
