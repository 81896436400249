import React from 'react';
import styled from 'styled-components/macro';
import { palette } from 'components/styles';

import { microBody, body, bodyShort, lgBody } from 'components/mixins';

export function ProspectSection({ id, className, children, ...restProps }) {
  return (
    <StyledSection className={className} id={id}>
      <SectionAnchor />
      {children}
    </StyledSection>
  );
}

ProspectSection.Title = function ({ className, children, ...restProps }) {
  return <Title className={className}>{children}</Title>;
};

ProspectSection.SubTitle = function ({ className, children, ...restProps }) {
  return <SubTitle>{children}</SubTitle>;
};

ProspectSection.Note = function ({ className, children, ...restProps }) {
  return <Note>{children}</Note>;
};

ProspectSection.Label = function ({ className, children, ...restProps }) {
  return <Label className={className}>{children}</Label>;
};

const StyledSection = styled.section`
  padding: 50px 0;
  border-bottom: 1px solid ${palette.gray2};
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }
`;

const SectionAnchor = styled.div`
  position: absolute;
  top: -55px;
  left: 0;
`;

const Title = styled.h2`
  ${lgBody(palette.pureBlack)}
  font-weight: 600;
  margin-bottom: 8px;
`;

const SubTitle = styled.p`
  ${body(palette.pureBlack)}
`;

const Label = styled.p`
  ${bodyShort(palette.pureBlack)}
  font-weight: 600;
  margin-bottom: 24px;
`;

const Note = styled.p`
  ${microBody(palette.pureBlack)}
  font-weight: 400;
  font-style: italic;
  margin: 8px 0;
`;

export default ProspectSection;
