import React from 'react';
import ReactDOM from 'react-dom';
import FeatureFlagsProvider from './context/flag-context';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';

import App from './App';

const pca = new PublicClientApplication(msalConfig);

// Component
const AppProvider = () => (
  <FeatureFlagsProvider>
    <App instance={pca} />
  </FeatureFlagsProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'));
