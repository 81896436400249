import styled from 'styled-components/macro';

import { microBody, xsBodyTall, bodyShort } from 'components/mixins';
import { palette } from 'components/styles';

export const BioContentWrapper = styled.div`
  padding: 30px 0 0 0;
  page-break-after: always;
`;

export const SocialItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const BioTitle = styled.h3`
  ${bodyShort(palette.pureBlack)}
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const BioParagraph = styled.div`
  p {
    ${xsBodyTall(palette.pureBlack)};
    margin-top: 16px;
  }

  ul,
  li {
    padding: 0;
    margin: 0 10px;
    ${xsBodyTall(palette.pureBlack)};
  }

  a,
  a:visited {
    font-size: inherit;
    font-weight: 600;
    color: ${palette.brandBlue};
  }
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }

    p:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const SocialHandlesGroup = styled.div`
  display: flex;
  flex-direction: column;

  a {
    ${microBody(palette.pureBlack)}
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }
`;

export const SocialIconWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${palette.brandBlue};
  margin-right: 16px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;
