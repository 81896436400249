import React from 'react';

const SvgInvisible = ({ fill = '#007CFF', stroke = '#FFFFFF' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={fill} />
      <path
        d="M11.2368 11.2368C11.0766 11.4088 10.8834 11.5467 10.6688 11.6423C10.4541 11.738 10.2224 11.7894 9.98738 11.7936C9.75241 11.7977 9.51901 11.7545 9.3011 11.6665C9.0832 11.5784 8.88525 11.4474 8.71907 11.2813C8.5529 11.1151 8.42189 10.9171 8.33388 10.6992C8.24586 10.4813 8.20264 10.2479 8.20678 10.013C8.21093 9.77798 8.26236 9.54625 8.35801 9.33159C8.45366 9.11692 8.59156 8.92372 8.7635 8.76351M13.4652 13.4652C12.468 14.2253 11.2538 14.6463 10.0002 14.6668C5.91683 14.6668 3.5835 10.0002 3.5835 10.0002C4.3091 8.64795 5.31549 7.46653 6.53516 6.53518L13.4652 13.4652ZM8.77516 5.47351C9.17669 5.37952 9.58778 5.33254 10.0002 5.33351C14.0835 5.33351 16.4168 10.0002 16.4168 10.0002C16.0627 10.6626 15.6404 11.2863 15.1568 11.861L8.77516 5.47351Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5835 3.5835L16.4168 16.4168"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgInvisible };
