import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { BulletList, BulletItem } from './elements';

export const ProspectBulletList = props => {
  const { items, textStyle, className, prospect } = props;
  const appInsights = useAppInsightsContext();
  const trackInsightLink = useTrackEvent(appInsights, 'PROFILE | Link Click', prospect);

  return (
    <BulletList className={className}>
      {items &&
        items.map((item, index) => {
          return (
            <BulletItem textStyle={textStyle ? textStyle : 'BODY'} key={index}>
              <ReactMarkdown
                linkTarget="_blank"
                components={{
                  a({ children, ...props }) {
                    return (
                      <a {...props} onClick={() => trackInsightLink({ linkType: 'INSIGHT' })}>
                        {children}
                      </a>
                    );
                  }
                }}
              >
                {item}
              </ReactMarkdown>
            </BulletItem>
          );
        })}
    </BulletList>
  );
};

export default ProspectBulletList;
