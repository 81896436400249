import styled from 'styled-components/macro';

import { CenterModal, Loader } from 'components/ui-components';
import { Button } from 'components/ui-components';
import { palette, breakpoints } from 'components/styles';
import { body, xsBody } from 'components/mixins';

export const FilterButton = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${palette.gray3};
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  min-width: 48px;

  > p {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    > p {
      display: block;
      margin-left: 8px;
    }
  }
`;

export const FilterCount = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${palette.brandBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -8px;

  p {
    ${xsBody(palette.pureWhite)};
    font-weight: 600;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    position: relative;
    margin-left: 8px;
    top: 0;
    right: 0;
  }
`;

export const FiltersCenterModal = styled(CenterModal)`
  > div > div {
    padding: 32px 24px 16px 24px;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      padding: 32px 32px 16px 32px;
    }
  }
`;

export const GroupLabel = styled.label`
  ${xsBody(palette.pureBlack)}
  display: block;
  font-weight: 600;
  margin-bottom: 20px;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    ${body(palette.pureBlack)}
    font-weight: 600;
  }
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin-left: 12px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 24px 0;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 32px 0;
  }
`;

export const FilterGroupWrapper = styled.div`
  flex: 50%;
  margin-bottom: 24px;
`;

export const CheckBlock = styled.label`
  align-items: center;
  margin: 8px 0;
`;

export const FilterLoader = styled(Loader)`
  margin: 0 6px;
`;
