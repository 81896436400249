import { css } from 'styled-components/macro';
import { colors } from 'components/styles';

export const basicCard = css`
  border: 1px solid ${colors.border};
  box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 40px;
`;

export const hideScrollbar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`;
