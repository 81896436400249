import forEach from 'lodash/forEach';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import ProspectRaise from 'models/ProspectRaise/ProspectRaise';
import ProspectOrgConnection from 'models/ProspectOrgConnection/ProspectOrgConnection';
import ProspectMutualConnection, {
  MutualConnectionLink
} from 'models/ProspectMutualConnection/ProspectMutualConnection';
import AlternateContact from './AlternateContact';
import Address from './Address';

class ProspectProfile {
  alt_contact_data: AlternateContact[];
  angel_url: string;
  articles: any[];
  assessment: any;
  addresses: Address[];
  bio: string;
  contact_label: string;
  crunchbase_url: string;
  donorClassification: string;
  donorId: string;
  email: string;
  employer: string;
  facebook_url: string;
  first_name: string;
  firstPublishedEpoch: number;
  github_url: string;
  givingHistory: any[];
  highlights: any[];
  instagram_username: string;
  last_name: string;
  lastPublishedEpoch: number;
  linkedin_url: string;
  occupation: string;
  onHideList: boolean;
  onWatchlist: boolean;
  orgConnections: ProspectOrgConnection[];
  middle_name?: string;
  mutualConnections: ProspectMutualConnection[];
  nickname?: string;
  photo: string;
  phone: string;
  raises: ProspectRaise[];
  twitter_username: string;
  wikipedia_url: string;
  youtube_url: string;
  workPhone?: string;
  workEmail?: string;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseMobilePhone?: string;
  spouseWorkPhone?: string;
  primaryHomePhone?: string;
  secondaryHomePhone?: string;
  tertiaryHomePhone?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  secondHomeAddress?: string;
  secondHomeState?: string;
  secondHomeCity?: string;
  secondHomeZip?: string;
  thirdHomeAddress?: string;
  thirdHomeState?: string;
  thirdHomeCity?: string;
  thirdHomeZip?: string;
  raiseNames: string;

  constructor(data: any) {
    const { assessment, raises } = data;

    if (assessment) {
      const {
        matchId,
        alignment_rank,
        engagement,
        mvv_status,
        single_ask_amount,
        prorated_ask_amount,
        donor_alignment,
        donor_alignment_reason
      } = assessment;

      this.assessment = {
        alignmentRank: alignment_rank ?? 0,
        matchId: matchId,
        engagement: engagement ? engagement : [],
        mvv_status: mvv_status ? mvv_status : {},
        singleAskAmount: single_ask_amount ? single_ask_amount : 0,
        proratedAskAmount: prorated_ask_amount ? prorated_ask_amount : 0,
        alignment: donor_alignment ? donor_alignment : 'Unknown',
        alignmentReason: donor_alignment_reason ? donor_alignment_reason : 'Reason Unknown'
      };
    }
    this.raises = [];
    if (raises && raises.length > 0) {
      this.raises = raises.map((data: any) => {
        return new ProspectRaise(
          data.org_raise_id,
          data.donor_alignment,
          data.donor_alignment_reason,
          data.highlights,
          data.priority,
          data.prorated_ask_amount,
          data.single_ask_amount,
          data.raise_name
        );
      });
    }

    this.addresses = [];
    this.donorId = data.donorId;
    this.donorClassification = data.donor_classification ? data.donor_classification.toUpperCase() : 'DEFAULT';
    this.first_name = data.first_name ? data.first_name : null;
    this.last_name = data.last_name ? data.last_name : null;
    this.state = data.state;
    this.city = data.city;
    this.email = data.email;
    this.phone = data.phone;
    this.occupation = data?.occupation ?? '';
    this.employer = data?.employer ?? '';
    this.highlights = data?.highlights ?? [];
    this.givingHistory = data.giving_history ? data.giving_history : [];
    this.twitter_username = data.twitter_username;
    this.instagram_username = data.instagram_username;
    this.youtube_url = data.youtube_url;
    this.linkedin_url = data.linkedin_url;
    this.facebook_url = data.facebook_url;
    this.crunchbase_url = data.crunchbase_url;
    this.wikipedia_url = data.wikipedia_url;
    this.github_url = data.github_url;
    this.angel_url = data.angel_url;
    this.bio = data.bio;
    this.photo = data?.photo;
    this.articles = data?.articles ?? [];
    this.onHideList = data.on_users_hidden_list ? data.on_users_hidden_list : false;
    this.onWatchlist = data.on_users_watchlist;
    this.alt_contact_data = data.alt_contact_data ? data.alt_contact_data : [];
    this.contact_label = data?.contact_label ?? '';
    this.firstPublishedEpoch = data?.first_published_epoch ?? 0;
    this.lastPublishedEpoch = data?.last_published_epoch ?? 0;
    this.orgConnections = [];
    this.middle_name = data.middle_name;
    this.mutualConnections = [];
    this.nickname = data.nickname;
    this.workPhone = data.work_phone;
    this.workEmail = data.work_email;
    this.spouseFirstName = data.spouse_first_name;
    this.spouseLastName = data.spouse_last_name;
    this.spouseMobilePhone = data.spouse_mobile_phone;
    this.spouseWorkPhone = data.spouse_work_phone;
    this.spouseWorkPhone = data.spouse_work_phone;
    this.primaryHomePhone = data.primary_home_phone;
    this.secondaryHomePhone = data.secondary_home_phone;
    this.tertiaryHomePhone = data.third_home_phone;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zipCode = data.zip_code;
    this.secondHomeAddress = data.second_home_address;
    this.secondHomeState = data.second_home_state;
    this.secondHomeCity = data.second_home_city;
    this.secondHomeZip = data.second_home_zip;
    this.thirdHomeAddress = data.third_home_address;
    this.thirdHomeState = data.third_home_state;
    this.thirdHomeCity = data.third_home_city;
    this.thirdHomeZip = data.third_home_zip;
    this.raiseNames = data?.raiseNames;
  }

  /**
   * Simple formatter which takes in a raw integer value and returns a string
   * for USD (including symbol, commas, etc)
   *
   * @param {number} raw
   * @return {string} Formatted dollar value in USD => $X,XXX
   */
  getFormattedUSDollars = (raw: number) => {
    return new Intl.NumberFormat().format(raw);
  };

  get FullName(): string {
    return `${this.first_name}${this.nickname ? ` "${this.nickname}"` : ''}${
      this.middle_name ? ` ${this.middle_name}` : ''
    } ${this.last_name}`;
  }

  get PreferredName(): string {
    return `${this.nickname ? this.nickname : this.first_name} ${this.last_name}`;
  }

  get PreferredFirstName(): string {
    return this.nickname ? this.nickname : this.first_name;
  }

  getDonorInitials() {
    const firstInitial = this.first_name ? this.first_name.substring(0, 1) : '';
    const lastInitial = this.last_name ? this.last_name.substring(0, 1) : '';

    return firstInitial.concat(lastInitial);
  }

  getActiveProspectHandles() {
    const propertyMap = {
      FACEBOOK: { key: 'facebook_url', generateHandle: (val: any) => val },
      TWITTER: { key: 'twitter_username', generateHandle: (val: any) => val },
      INSTAGRAM: { key: 'instagram_username', generateHandle: (val: any) => val },
      YOUTUBE: { key: 'youtube_url', generateHandle: (val: any) => val },
      LINKEDIN: { key: 'linkedin_url', generateHandle: (val: any) => val }
    };

    const handles: any = [];

    forEach(propertyMap, (propertyData, key) => {
      if (this[propertyData.key as keyof ProspectProfile]) {
        handles.push({
          type: key,
          value: propertyData.generateHandle(this[propertyData.key as keyof ProspectProfile])
        });
      }
    });

    return handles;
  }

  getFormattedDate(unformattedDate: number) {
    return moment(unformattedDate, 'YYYY-MM-DD').format('MMM D, YYYY');
  }

  getFormattedPercentage(value: number, factor: number) {
    const rawPercent = (value / factor) * 100;
    return rawPercent.toFixed(1);
  }

  getFormattedDollars(unformattedVal: string | number) {
    // Nine Zeroes for Billions
    return unformattedVal === 0
      ? '--'
      : Math.abs(Number(unformattedVal)) >= 1.0e9
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e9).toFixed(2)) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(unformattedVal)) >= 1.0e6
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e6).toFixed(2)) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(unformattedVal)) >= 1.0e3
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e3).toFixed(2)) + 'K'
      : Math.abs(Number(unformattedVal));
  }

  getProspectAlignmentLevel(checkValue: string) {
    const alignmentLevels = [
      { name: 'Unknown', weight: null },
      { name: 'Very low', weight: 0 },
      { name: 'Low', weight: 1 },
      { name: 'Moderate', weight: 2 },
      { name: 'High', weight: 3 },
      { name: 'Very high', weight: 4 }
    ];
    const foundAlignment = find(alignmentLevels, level => level.name.toLowerCase() === checkValue?.toLowerCase());

    return foundAlignment ? foundAlignment : alignmentLevels[0];
  }

  getDonorRaiseDataByRaiseId(raiseId: string): ProspectRaise {
    const foundRaise = this.raises.find((raise: ProspectRaise) => raise.raiseId === raiseId);

    return foundRaise ?? new ProspectRaise();
  }

  getPhoneForResidence(residenceLabel: string): string | undefined {
    if (residenceLabel === 'Primary') {
      return this.primaryHomePhone;
    } else if (residenceLabel === 'Secondary') {
      return this.secondaryHomePhone;
    } else if (residenceLabel === 'Tertiary') {
      return this.tertiaryHomePhone;
    }
    return undefined;
  }

  initConnections(rawConnections: any) {
    if (rawConnections.length) {
      const modeledConnections: ProspectOrgConnection[] = [];
      rawConnections.forEach((connection: any) => {
        const orgConnection = new ProspectOrgConnection(connection);
        orgConnection.initLinks();
        modeledConnections.push(orgConnection);
      });
      this.orgConnections = orderBy(modeledConnections, ['numDegrees', 'firstLink'], ['asc', 'asc']);
    } else {
      this.orgConnections = [];
    }
  }

  initMutualConnections(rawConnections: any) {
    if (rawConnections.length) {
      rawConnections.forEach((raw: any) => {
        const { links } = raw;
        const connection = new ProspectMutualConnection(raw);
        if (links?.length) {
          connection.numDegrees = links.length;
          connection.firstConnectionName = links[0].name;
          connection.links = links.map((link: any) => new MutualConnectionLink(link));
        }

        this.mutualConnections.push(connection);
      });
    }
    this.mutualConnections = orderBy(this.mutualConnections, ['numDegrees', 'firstConnectionName'], ['asc', 'asc']);
  }

  initAlternateContacts() {
    const initializedAltContacts = [...this.alt_contact_data];

    if (this.spouseWorkPhone) {
      initializedAltContacts.unshift({
        name: `${this.spouseFirstName} ${this.spouseLastName}`,
        subName: 'Spouse',
        label: 'Work',
        phone: this.spouseWorkPhone
      });
    }

    if (this.workPhone || this.workEmail) {
      initializedAltContacts.unshift({
        name: `${this.PreferredName}`,
        label: 'Work',
        phone: this.workPhone,
        email: this.workEmail
      });
    }

    this.alt_contact_data = initializedAltContacts;
  }

  initAddresses() {
    const initializedAddresses: Address[] = [];

    if (this.address) {
      const address = new Address('Primary', this.address, this.city, this.state, this.zipCode);
      initializedAddresses.push(address);
    }

    if (this.secondHomeAddress) {
      const address = new Address(
        'Secondary',
        this.secondHomeAddress,
        this.secondHomeCity,
        this.secondHomeState,
        this.secondHomeZip
      );
      initializedAddresses.push(address);
    }

    if (this.thirdHomeAddress) {
      const address = new Address(
        'Tertiary',
        this.thirdHomeAddress,
        this.thirdHomeCity,
        this.thirdHomeState,
        this.thirdHomeZip
      );
      initializedAddresses.push(address);
    }

    this.addresses = initializedAddresses;
  }
}

export default ProspectProfile;
