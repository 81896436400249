class Raise {
  raiseId: string;
  raise: string;

  constructor(id: string, raise: string) {
    this.raiseId = id;
    this.raise = raise;
  }
}

export default Raise;
