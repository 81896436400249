import React from 'react';
import styled from 'styled-components/macro';
import { lgBody, microBody } from 'components/mixins';
import { palette, breakpoints } from 'components/styles';

export const SidebarNav = ({ className, children, ...restProps }) => {
  return (
    <StyledNav className={className}>
      <NavUL>{children}</NavUL>
    </StyledNav>
  );
};

SidebarNav.Item = function ({ className, children, ...restProps }) {
  return (
    <StyledItem className={className} active={restProps.active}>
      {children}
    </StyledItem>
  );
};

SidebarNav.ItemButton = function ({ className, children, ...restProps }) {
  return (
    <StyledButton onClick={restProps.onClick} active={restProps.active}>
      {children}
    </StyledButton>
  );
};

SidebarNav.Badge = function({ className, children, ...restProps }) {
  return <Badge>{children}</Badge>;
};

const StyledNav = styled.nav`
  background-color: ${palette.background};
  position: sticky;
  top: 56px;
  width: calc(100vw - 44px);
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    width: auto;
  }
`;

const NavUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: ${breakpoints['laptop']}) {
    flex-direction: column;
  }
`;

const StyledItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-left: none;
  border-bottom: ${props => (props.active ? `3px solid ${palette.brandBlue}` : `2px solid ${palette.gray3}`)};
  cursor: pointer;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    top: 56px;
    border-left: ${props => (props.active ? `3px solid ${palette.brandBlue}` : `2px solid ${palette.gray3}`)};
    border-bottom: none;
  }
`;

const StyledButton = styled.button`
  ${microBody(palette.pureBlack)}
  font-weight: ${props => (props.active ? 600 : 400)};
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 112px;
  padding: 8px;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  justify-content: center;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    ${lgBody(palette.pureBlack)}
    font-weight: ${props => (props.active ? 600 : 400)};
    justify-content: flex-start;
    min-width: auto;
  }
`;

const Badge = styled.div`
  background-color: ${palette.brandBlue};
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  line-height: 7px;
  padding: 4px 6px;
  vertical-align: top;
  font-weight: bold;
`;

export default SidebarNav;
