import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { BackgroundLayer, ModalWrapper, ModalCard } from './elements';
import Header from './header';
import Footer from './footer';

interface CenterModalProps {
  opened: boolean;
  children: React.ReactChild;
  className?: string;
}

interface CenterModalInterface extends React.FunctionComponent<CenterModalProps> {
  Header?: any;
  Footer?: any;
}

const CenterModal: CenterModalInterface = (props: CenterModalProps) => {
  const { opened, children, className } = props;
  const appBody = document?.getElementsByTagName('html')[0];
  const appRoot = document?.getElementById('root') ?? ({} as Element);

  const cssTransitionProps = {
    in: opened,
    timeout: 500,
    unmountOnExit: true,
    mountOnEnter: true
  };

  useEffect(() => {
    if (opened && appRoot) {
      appBody.style.overflowY = 'hidden';
    } else {
      if (appRoot) {
        appBody.style.overflowY = '';
      }
    }
  });

  return ReactDOM.createPortal(
    <div className={className}>
      <CSSTransition className={`background`} {...cssTransitionProps}>
        <BackgroundLayer></BackgroundLayer>
      </CSSTransition>
      <CSSTransition className={`modal`} {...cssTransitionProps}>
        <ModalWrapper>
          <ModalCard>{children}</ModalCard>
        </ModalWrapper>
      </CSSTransition>
    </div>,
    appRoot
  );
};

CenterModal.Header = Header;
CenterModal.Footer = Footer;

export { CenterModal };
