import styled, { css } from 'styled-components/macro';
import { palette } from 'components/styles';

const ButtonEssentials = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 14px 20px;
  transition: all 1s ease-in;
  cursor: pointer;
`;

export const PrimaryButton = styled.button`
  ${ButtonEssentials}
  background-color: ${palette.brandBlue};
  border: none;
`;

export const SecondaryButton = styled.button`
  ${ButtonEssentials}
  background-color: transparent;
  border: none;
  padding: 0;

  label {
    cursor: pointer;
  }
`;

export const TertiaryButton = styled.button`
  ${ButtonEssentials}
  background-color: ${palette.pureWhite};
  border: 1px solid ${palette.gray5};
`;
