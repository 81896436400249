import React from 'react';

import { RootLI, StandardNavButton, StandardNavLink, StandardExternalLink, StandardNavLabel } from './elements';

interface NavItemProps {
  isActive?: boolean;
  className: string;
  content: string;
  type?: string;
  href: string;
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onExternalNavClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onButtonNavClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const NavItem: React.FC<NavItemProps> = props => {
  const { isActive = false, className, content, type = 'LINK', href, onExternalNavClick, onButtonNavClick } = props;

  const renderLinkItem = () => {
    const linkProps = {
      role: 'menuitem',
      to: href ?? '#',
      onClick: onExternalNavClick,
      children: content
    };

    return (
      <StandardNavLink activeRoute={isActive} linkProps={{ ...linkProps }}>
        {content}
      </StandardNavLink>
    );
  };

  const renderExternalLink = () => {
    return (
      <StandardExternalLink role="menuitem" href={href} onClick={onExternalNavClick} target="_blank">
        {content}
      </StandardExternalLink>
    );
  };

  const renderButtonItem = () => {
    return (
      <StandardNavButton activeRoute={isActive} role="menuitem" onClick={onButtonNavClick}>
        {content}
      </StandardNavButton>
    );
  };

  const renderLabelItem = () => {
    return <StandardNavLabel role="menuitem">{content}</StandardNavLabel>;
  };

  return (
    <RootLI className={className} role="none">
      {type.toUpperCase() === 'LABEL' && renderLabelItem()}
      {type.toUpperCase() === 'BUTTON' && renderButtonItem()}
      {type.toUpperCase() === 'LINK' && renderLinkItem()}
      {type.toUpperCase() === 'EXTERNAL' && renderExternalLink()}
    </RootLI>
  );
};

export { NavItem };
