import styled from 'styled-components/macro';

export const TabsBar = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
