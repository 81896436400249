import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  BioContentWrapper,
  BioTitle,
  BioParagraph,
  ContactInfoWrapper,
  SocialHandlesGroup,
  SocialItem
} from './elements';
import { SOCIAL_MAP } from 'constants/social-icons';
import { palette } from 'components/styles';
import ContactTile from 'components/prospect-profile/contact-tile';

const PrintProspectBio = props => {
  const { prospect } = props;

  const renderSocialHandles = () => {
    const prospectSocialHandles = prospect.getActiveProspectHandles();
    let socialHandles = [];

    if (prospectSocialHandles.length > 0) {
      socialHandles = prospectSocialHandles.map((handleObj, index) => {
        return (
          <SocialItem key={index} href={handleObj.value} target="_blank">
            {React.cloneElement(SOCIAL_MAP[handleObj.type], {
              fill: palette.pureWhite,
              stroke: palette.brandBlue,
              strokeWidth: 1,
              size: 20
            })}
            {handleObj.value}
          </SocialItem>
        );
      });
    }

    return (
      <>
        {prospectSocialHandles.length > 0 && (
          <div>
            <SocialHandlesGroup>{socialHandles}</SocialHandlesGroup>
          </div>
        )}
      </>
    );
  };

  const renderBioSection = () => {
    let renderBio;
    if (prospect.bio && prospect.bio !== '') {
      renderBio = (
        <>
          <BioTitle>{`Biography`}</BioTitle>
          <BioParagraph>
            <ReactMarkdown>{prospect.bio}</ReactMarkdown>
          </BioParagraph>
        </>
      );
    }

    return renderBio;
  };

  const renderContactInformationSection = () => {
    const prospectSocialHandles = prospect.getActiveProspectHandles();
    const containsContactInfo = prospect.phone !== '' || prospect.email !== '';
    const containsSocial = prospectSocialHandles.length > 0;

    return (
      <>
        <BioTitle>{`Contact Information`}</BioTitle>
        <ContactInfoWrapper>
          {containsContactInfo && (
            <div>
              <ContactTile>
                <ContactTile.Text fontWeight={600}>{prospect.PreferredName}</ContactTile.Text>
                <ContactTile.Text fontStyle="italic">{prospect.contact_label}</ContactTile.Text>
                {prospect.phone && <ContactTile.Text>{prospect.phone}</ContactTile.Text>}
                {prospect.email && <ContactTile.Text>{prospect.email}</ContactTile.Text>}
              </ContactTile>
            </div>
          )}
          {containsSocial && <>{renderSocialHandles()}</>}
          {!containsSocial && !containsContactInfo && (
            <BioParagraph>{`${prospect.PreferredFirstName}'s contact information is not available.`}</BioParagraph>
          )}
        </ContactInfoWrapper>
      </>
    );
  };

  return (
    <>
      <BioContentWrapper>
        {renderBioSection()}
        {renderContactInformationSection()}
      </BioContentWrapper>
    </>
  );
};

export default PrintProspectBio;
