import React from 'react';
import { CheckboxContainer, Icon, StyledCheckbox, HiddenCheckbox } from './elements';
import Label from './label';

interface CheckboxProps {
  className?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CheckboxInterface extends React.FunctionComponent<CheckboxProps> {
  Label?: any;
}

const Checkbox: CheckboxInterface = ({ className, checked, onChange, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} type="checkbox" onChange={onChange} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.Label = Label;

export { Checkbox };
