import React from 'react';
import { TextUnorderedListText } from './elements';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Text: React.FunctionComponent<Props> = ({ className, children, ...restProps }) => {
  return <TextUnorderedListText className={className}>{children}</TextUnorderedListText>;
};

export default Text;
