import React from 'react';

import { InsightGrid, TileWrapper, PrintMarginWrapper } from './elements';
import ProspectSection from 'components/prospect-profile/prospect-section';
import ProspectEngagementTile from '../prospect-engagement-tile';

const PrintProspectInsights = props => {
  const { id, prospect } = props;

  const conditionallyRenderEngagement = () => {
    const { assessment } = prospect;
    let renderEngagementTile = null;

    if (assessment.engagement && assessment.engagement.length > 0) {
      renderEngagementTile = (
        <PrintMarginWrapper>
          <ProspectSection id={id}>
            <ProspectSection.Title>{`Organizational Engagement`}</ProspectSection.Title>
            <InsightGrid>
              <TileWrapper>
                <ProspectEngagementTile prospect={prospect} />
              </TileWrapper>
            </InsightGrid>
          </ProspectSection>
        </PrintMarginWrapper>
      );
    }

    return renderEngagementTile;
  };

  return <>{conditionallyRenderEngagement()}</>;
};

export default PrintProspectInsights;
