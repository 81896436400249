import React from 'react';
import ProspectProfile from 'models/ProspectProfile/ProspectProfile';
import TabBar from 'components/ui-components/tabs/tab-bar';
import { RaiseTab } from './elements';

interface ProspectRaiseTabsProps {
  prospect: ProspectProfile;
  onRaiseChanged?: (raiseId: string) => void;
  currentRaise: string;
}

const ProspectRaiseTabs: React.FC<ProspectRaiseTabsProps> = props => {
  const { prospect, onRaiseChanged, currentRaise } = props;

  return (
    <TabBar onTabChanged={onRaiseChanged} activeTabId={currentRaise}>
      {prospect.raises.map(raise => {
        return (
          <RaiseTab
            id={raise.raiseId}
            key={raise.raiseId}
            activeTabId={raise.raiseId === currentRaise}
            onTabClicked={onRaiseChanged}
            isActive={raise.raiseId === currentRaise}
          >
            <>{raise.raiseName}</>
          </RaiseTab>
        );
      })}
    </TabBar>
  );
};

export default ProspectRaiseTabs;
