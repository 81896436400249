class Address {
  label: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;

  constructor(label: string, street?: string, city?: string, state?: string, zip?: string) {
    this.label = label;
    this.street = street;
    this.city = city;
    this.state = state;
    this.zip = zip;
  }
}

export default Address;
