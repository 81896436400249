import { Route, Switch } from 'react-router-dom';
import React from 'react';
import map from 'lodash/map';

const Routes = props => {
  const { routeConfig } = props;

  const renderRoutes = () => {
    const renderedRoutes = map(routeConfig, route => {
      return <Route key={route.id} path={route.path} exact={route.exact} render={() => route.component}></Route>;
    });

    return renderedRoutes;
  };

  return <Switch>{renderRoutes()}</Switch>;
};

export default Routes;
