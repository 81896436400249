export const CLASSIFICATIONS = {
  STRANGER: {
    label: 'Stranger',
    description: 'New major donor prospect',
    weight: 100
  },
  ACQUAINTANCE: {
    label: 'Acquaintance',
    description: 'Engaged major donor prospect',
    weight: 65
  },
  FRIEND: {
    label: 'Friend',
    description: 'Existing minor donor to your organization',
    weight: 35
  },
  PARTNER: {
    label: 'Partner',
    description: 'Existing major donor to your organization',
    weight: 0
  },
  DEFAULT: {
    label: 'Unavailable',
    description: 'Organization relationship currently unavailable',
    weight: 0
  }
};
