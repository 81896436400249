import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';

export const DropdownContainer = styled('div')`
  position: relative;
  height: 44px;
  max-width: 48px;
  width: 48px;
  min-width: 0;
  border: 1px solid ${palette.gray3};
  border-radius: 8px;
`;

export const DropdownHeader = styled('button')`
  ${xsBody(palette.pureBlack)}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${palette.pureWhite};
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 6px;
  }
`;

export const DropdownListContainer = styled('div')`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  right: 0;
  min-width: 256px;
  width: 100%;
`;

export const DropdownList = styled('ul')`
  border: 1px solid ${palette.gray3};
  background-color: ${palette.pureWhite};
  border-radius: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface ListItemProps {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 8px 10px;
  margin: 0;
  background-color: ${props => (props.selected ? palette.gray3 : palette.pureWhite)};

  &:hover {
    background-color: ${palette.gray3};
    cursor: pointer;
  }

  button,
  button:visited {
    color: ${xsBody(palette.pureBlack)};
    border: none;
    outline: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
  }
`;

export const StyledLabel = styled.label`
  ${xsBody(palette.pureBlack)}
  font-weight: 600;
`;
