import React from 'react';

import { TextUnorderedList } from './elements';
import Text from './text';
import Item from './item';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

interface TextListInterface extends React.FunctionComponent<Props> {
  Text?: any;
  Item?: any;
}

const TextList: TextListInterface = ({ className, children }) => {
  return <TextUnorderedList className={className}>{children}</TextUnorderedList>;
};

TextList.Text = Text;
TextList.Item = Item;

export { TextList };
