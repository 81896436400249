import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { palette } from 'components/styles';
import { FadeIn } from 'components/ui-components';
import { FeatureFlags, Flag } from 'context/flag-context';

import {
  HeaderToggle,
  ProspectHeaderWrapper,
  ProspectHeaderContainer,
  LayoutLeftWrapper,
  AvatarBlock,
  AvatarFadeIn,
  AvatarSkeleton,
  LayoutRightWrapper,
  ProspectNameWrapper,
  PrintProspectWrapper,
  ProspectToggleDesktopWrapper,
  ProspectToggleWrapper,
  Separator,
  ProspectDetails,
  ProspectDetailsGroup,
  ProspectDetailsContent,
  ProspectDetailsLabel,
  SkeletonLoaders,
  BackButton,
  RaiseDropdown
} from './elements';
import HideProspectToggle from 'components/hide-prospect-toggle';
import PrintProspect from 'components/prospect-profile/print-prospect';
import Classification from 'components/classification';
import WatchlistToggle from 'components/watchlist-toggle';
import { NewDonorTag } from 'components/ui-components/new-donor-tag';
import { OrgContext } from 'context/org-context';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import ProspectRaiseTabs from 'components/prospect-profile/prospect-raise-tabs';

const ProspectProfile = props => {
  const { activeRaise, toggleActiveRaiseById, organizationRaises } = useContext(OrgContext);
  const { prospect } = props;
  const { assessment } = prospect;
  const { matchId } = assessment;
  const { isFeatureEnabled } = useContext(FeatureFlags);
  const history = useHistory();

  const handleRaiseChange = raiseId => {
    toggleActiveRaiseById(raiseId);
  };

  const getFormattedJobTitleEmployer = () => {
    let formattedJobDesc = ``;

    if (prospect.employer && prospect.occupation) {
      formattedJobDesc = `${prospect.occupation}, ${prospect.employer}`;
    } else {
      formattedJobDesc = `${prospect.occupation}${prospect.employer}`;
    }

    return formattedJobDesc;
  };

  return (
    <ProspectHeaderWrapper showRaiseSelector={organizationRaises && organizationRaises.length > 1}>
      <ProspectHeaderContainer>
        <LayoutLeftWrapper>
          <AvatarBlock>
            <BackButton onClick={() => history.goBack()}>
              <ChevronLeft size={20} /> <span>Back to List</span>
            </BackButton>
            <AvatarFadeIn background={palette.brandDarkBlue}>
              {!prospect?.photo && <p>{prospect.getDonorInitials()}</p>}
              {prospect?.photo && <img src={prospect.photo} alt="prospect-avatar" />}
            </AvatarFadeIn>
          </AvatarBlock>
        </LayoutLeftWrapper>
        <FadeIn>
          <LayoutRightWrapper>
            <ProspectDetailsContent>
              <Classification>
                <Classification.Icon classificationId={prospect.donorClassification} />
                <Classification.Title classificationId={prospect.donorClassification} />
                <Classification.Infotip classificationId={prospect.donorClassification} iconColor={palette.pureWhite} />
              </Classification>
            </ProspectDetailsContent>
            <ProspectNameWrapper>
              <h1>{prospect.FullName}</h1>
              <NewDonorTag size="large" firstPublishedEpoch={prospect.firstPublishedEpoch} />
            </ProspectNameWrapper>
            <ProspectDetails>
              <Separator />
              <ProspectDetailsGroup>
                <ProspectDetailsContent>
                  <ProspectDetailsLabel>{getFormattedJobTitleEmployer()}</ProspectDetailsLabel>
                </ProspectDetailsContent>
                <ProspectDetailsContent>
                  <p>{`${prospect.city ? prospect.city : 'Unavailable'}, ${prospect.state}`}</p>
                </ProspectDetailsContent>
              </ProspectDetailsGroup>
            </ProspectDetails>
            <ProspectToggleDesktopWrapper>
              <HeaderToggle
                loadStroke={palette.pureWhite}
                displayLabel={true}
                offFill={palette.brandBlue}
                offStroke={palette.pureWhite}
                prospect={prospect}
                matchId={matchId}
              />
              <HideProspectToggle prospect={prospect} displayLabel={true} matchId={matchId} />
              <PrintProspectWrapper>{<PrintProspect prospect={prospect} displayLabel={true} />}</PrintProspectWrapper>
            </ProspectToggleDesktopWrapper>
            {organizationRaises.length > 1 && (
              <>
                {isFeatureEnabled(Flag.ShowRaiseTabs) && (
                  <ProspectRaiseTabs
                    prospect={prospect}
                    currentRaise={activeRaise?.raiseId ?? prospect?.raises[0]?.raiseId}
                    onRaiseChanged={handleRaiseChange}
                  />
                )}
                {isFeatureEnabled(Flag.ShowRaiseDropdown) && (
                  <RaiseDropdown
                    options={prospect.raises.map(raise => ({
                      value: raise.raiseId,
                      label: raise.raiseName,
                      key: raise.raiseId
                    }))}
                    value={activeRaise?.raiseId ?? prospect?.raises[0]?.raiseId}
                    onSelectionChange={handleRaiseChange}
                    iconPosition="LEFT"
                    disabled={prospect?.raises?.length <= 1}
                    useIcon={true}
                  />
                )}
              </>
            )}
            <ProspectToggleWrapper>
              <WatchlistToggle
                loadStroke={palette.pureWhite}
                displayLabel={false}
                offFill={palette.brandBlue}
                offStroke={palette.pureWhite}
                prospect={prospect}
                matchId={matchId}
              />
              <HideProspectToggle prospect={prospect} matchId={matchId} />
              <PrintProspect prospect={prospect} />
            </ProspectToggleWrapper>
          </LayoutRightWrapper>
        </FadeIn>
      </ProspectHeaderContainer>
    </ProspectHeaderWrapper>
  );
};

ProspectProfile.Loading = function () {
  return (
    <ProspectHeaderWrapper showRaiseSelector={false}>
      <ProspectHeaderContainer>
        <LayoutLeftWrapper>
          <AvatarBlock background={palette.gray3}>
            <AvatarSkeleton>
              <img alt="skeleton-avatar" />
            </AvatarSkeleton>
          </AvatarBlock>
        </LayoutLeftWrapper>
        <LayoutRightWrapper>
          <SkeletonLoaders>
            <Skeleton width={300} height={44} />
            <Skeleton width={275} height={22} />
            <Skeleton height={44} width={'75%'} />
          </SkeletonLoaders>
        </LayoutRightWrapper>
      </ProspectHeaderContainer>
    </ProspectHeaderWrapper>
  );
};

export default ProspectProfile;
