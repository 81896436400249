import React from 'react';

const SvgSort = ({ fill, stroke }) => {
  return (
    <svg width="27" height="13" viewBox="0 0 27 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 7.36412L5.5 11.8641L10 7.36412" stroke="black" strokeLinejoin="round" />
      <line x1="5.40967" y1="11.4545" x2="5.40967" y2="-1.9292e-06" stroke="black" strokeLinejoin="round" />
      <line x1="13" y1="1" x2="19" y2="1" stroke="black" strokeWidth="2" />
      <line x1="13" y1="6" x2="23" y2="6" stroke="black" strokeWidth="2" />
      <line x1="13" y1="11" x2="27" y2="11" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export { SvgSort };
