import styled from 'styled-components/macro';
import ProspectCard from 'components/prospect-card';
import { breakpoints } from 'components/styles';

export const CardListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 56px;
  justify-content: center;

  > div {
    flex: 25%;
    max-width: 300px;
    padding: 8px;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    justify-content: flex-start;
  }
`;

export const ProspectListCard = styled(ProspectCard)`
  max-width: 280px;
`;
