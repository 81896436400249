import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody, nanoBody } from 'components/mixins';
import { breakpoints } from 'components/styles';

export const LeftColumn = styled.div`
  height: 70px;
  width: 770px;
  height: 629px;
  overflow-y: scroll;
  border-right: ${palette.gray3} solid 1px;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    width: 35%;
  }
`;

export const ConnectionsNameList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
`;

interface ConnectionChainListItemProps {
  active: boolean;
}

export const ConnectionChainListItem = styled.li<ConnectionChainListItemProps>`
  position: relative;
  display: flex;
  width: 100%;

  button {
    background-color: ${props => (props.active ? 'rgba(20, 101, 187, 0.08)' : 'transparent')};
    border: none;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    padding: 0;
    cursor: pointer;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;

    p:first-of-type {
      ${xsBody(palette.pureBlack)};
      font-weight: ${props => (props.active ? 600 : 400)};
      text-align: left;
      margin-bottom: 2px;
      padding-left: 24px;
    }

    p:last-of-type {
      ${nanoBody(palette.pureBlack)}
      font-weight: 400;
      line-height: 16px;
      font-weight: 400;
      padding-left: 24px;
      text-align: left;
    }
  }
`;
