import React from 'react';
import styled from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';
import { Container, NavItemDropdown, NavItem } from 'components/ui-components';

export const FixedNav = styled.nav`
  position: relative;
  top: 0;
  width: 100%;
  background-color: ${palette.navyBackground};
  border-bottom: 1px solid ${palette.fadedBlue};
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
  height: 64px;
`;

export const LogoWrapper = styled.div`
  svg,
  img {
    height: 14px;
    width: auto;

    @media only screen and (min-width: ${breakpoints['laptop']}) {
      height: 26px;
    }
  }
`;

export const OrgBrandWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid ${palette.pureWhite};

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    height: 64px;
    margin-left: 32px;
    padding-left: 32px;
  }

  > div {
    height: 20px;
    max-width: 150px;
    margin-right: 20px;
    border: 1px solid ${palette.navyBackground};

    @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
      height: 40px;
    }

    @media only screen and (min-width: ${breakpoints['laptop']}) {
      height: 48px;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    color: ${palette.pureWhite};

    @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
      font-size: 24px;
      line-height: 40px;
    }
  }
`;

export const NavContainer = styled(Container)`
  align-items: center;

  > div {
    padding: 34px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const NavWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const MenuUL = styled.ul`
  display: none;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    display: flex;
  }
`;

export const NavMenuRootItem = styled(props => <NavItemDropdown {...props} />)`
  margin-right: 32px;
`;

export const LogoutItem = styled(props => <NavItem {...props} />)`
  color: ${palette.pureWhite};
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${palette.pureWhite};
  }

  > a {
    font-weight: 600;
    font-size: 16px;
  }
`;
