import React from 'react';
import { Linkedin, Twitter, Facebook, Instagram, Youtube } from 'react-feather';

export const SOCIAL_MAP = {
  FACEBOOK: <Facebook />,
  LINKEDIN: <Linkedin />,
  TWITTER: <Twitter />,
  INSTAGRAM: <Instagram />,
  YOUTUBE: <Youtube />
};
