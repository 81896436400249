import React from 'react';
import { ProspectAvatarWrapper, ProspectAvatarImage } from './elements';

export const ProspectAvatar = props => {
  const { className, prospect, backgroundColor, textColor } = props;

  return (
    <ProspectAvatarWrapper className={className}>
      <ProspectAvatarImage backgroundColor={backgroundColor} textColor={textColor}>
        <div>
          {prospect.photo && <img src={prospect.photo} alt="prospect-avatar" />}
          {!prospect.photo && <p>{prospect.getDonorInitials()}</p>}
        </div>
      </ProspectAvatarImage>
    </ProspectAvatarWrapper>
  );
};

export default ProspectAvatar;
