import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom';

import { Toast } from '../toast';
import { MotionErrorWrapper, ErrorContainer } from './elements';
import { Notification } from 'types/shared';

const transition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };
const rowVariant = {
  hidden: { opacity: 0, x: '-5px' },
  show: {
    transition: transition,
    opacity: 1,
    x: 0
  },
  close: { opacity: 0, x: '-5px' }
};

interface NotificationToastProps {
  notificationQueue: Array<Notification>;
  dismissNotifications: () => void;
}

const NotificationToast: React.FC<NotificationToastProps> = props => {
  const { notificationQueue, dismissNotifications } = props;
  const [activeNotifications, setActiveNotifications] = useState(false);
  const appRoot = document.getElementsByTagName('body')[0];

  useEffect(() => {
    if (notificationQueue.findIndex(note => note.active === true) >= 0) {
      setActiveNotifications(true);
    } else {
      setActiveNotifications(false);
    }
  }, [notificationQueue]);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {activeNotifications && (
        <MotionErrorWrapper variants={rowVariant} initial="hidden" animate="show" exit="close" key="wrapper">
          <ErrorContainer>
            <AnimatePresence exitBeforeEnter>
              {notificationQueue.map(note => {
                if (note.active === true) {
                  return (
                    <Toast
                      key={note.id}
                      id={note.id}
                      title={note.title}
                      description={note.description}
                      type={note.type}
                      closeToast={dismissNotifications}
                    />
                  );
                }
                return null;
              })}
            </AnimatePresence>
          </ErrorContainer>
        </MotionErrorWrapper>
      )}
    </AnimatePresence>,
    appRoot
  );
};

export { NotificationToast };
