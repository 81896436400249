export const SORT_DIR = {
  asc: 'Ascending',
  dsc: 'Descending'
};

export const SORT_OPTIONS = [
  {
    key: 'first_name--asc',
    value: 'first_name--asc',
    label: 'First Name (ascending)',
    disabled: false,
    sortEngine: 'server'
  },
  {
    key: 'first_name--dsc',
    value: 'first_name--dsc',
    label: 'First Name (descending)',
    disabled: false,
    sortEngine: 'server'
  },
  {
    key: 'last_name--asc',
    value: 'last_name--asc',
    label: 'Last Name (ascending)',
    disabled: false,
    sortEngine: 'server'
  },
  {
    key: 'last_name--dsc',
    value: 'last_name--dsc',
    label: 'Last Name (descending)',
    disabled: false,
    sortEngine: 'server'
  },
  {
    key: 'raises--asc',
    value: 'raises--asc',
    label: 'Raises (ascending)',
    disabled: false,
    sortEngine: 'server'
  },
  {
    key: 'raises--dsc',
    value: 'raises--dsc',
    label: 'Raises (descending)',
    disabled: false,
    sortEngine: 'server'
  }
];

export const FILTER_LIST = [
  {
    type: 'MULTI',
    label: 'Donor Location',
    url_property: 'DonorLocationFilter',
    checkedValues: [],
    checks: [
      { key: 'AK', label: 'AK' },
      { key: 'AL', label: 'AL' },
      { key: 'AR', label: 'AR' },
      { key: 'AS', label: 'AS' },
      { key: 'AZ', label: 'AZ' },
      { key: 'CA', label: 'CA' },
      { key: 'CO', label: 'CO' },
      { key: 'CT', label: 'CT' },
      { key: 'DC', label: 'DC' },
      { key: 'DE', label: 'DE' },
      { key: 'FL', label: 'FL' },
      { key: 'GA', label: 'GA' },
      { key: 'GU', label: 'GU' },
      { key: 'HI', label: 'HI' },
      { key: 'IA', label: 'IA' },
      { key: 'ID', label: 'ID' },
      { key: 'IL', label: 'IL' },
      { key: 'IN', label: 'IN' },
      { key: 'KS', label: 'KS' },
      { key: 'KY', label: 'KY' },
      { key: 'LA', label: 'LA' },
      { key: 'MA', label: 'MA' },
      { key: 'MD', label: 'MD' },
      { key: 'ME', label: 'ME' },
      { key: 'MI', label: 'MI' },
      { key: 'MN', label: 'MN' },
      { key: 'MO', label: 'MO' },
      { key: 'MP', label: 'MP' },
      { key: 'MS', label: 'MS' },
      { key: 'MT', label: 'MT' },
      { key: 'NC', label: 'NC' },
      { key: 'ND', label: 'ND' },
      { key: 'NE', label: 'NE' },
      { key: 'NH', label: 'NH' },
      { key: 'NJ', label: 'NJ' },
      { key: 'NM', label: 'NM' },
      { key: 'NV', label: 'NV' },
      { key: 'NY', label: 'NY' },
      { key: 'OH', label: 'OH' },
      { key: 'OK', label: 'OK' },
      { key: 'OR', label: 'OR' },
      { key: 'PA', label: 'PA' },
      { key: 'PR', label: 'PR' },
      { key: 'RI', label: 'RI' },
      { key: 'SC', label: 'SC' },
      { key: 'SD', label: 'SD' },
      { key: 'TN', label: 'TN' },
      { key: 'TX', label: 'TX' },
      { key: 'UM', label: 'UM' },
      { key: 'UT', label: 'UT' },
      { key: 'VA', label: 'VA' },
      { key: 'VI', label: 'VI' },
      { key: 'VT', label: 'VT' },
      { key: 'WA', label: 'WA' },
      { key: 'WI', label: 'WI' },
      { key: 'WV', label: 'WV' },
      { key: 'WY', label: 'WY' }
    ]
  },
  {
    type: 'CHECKBOX',
    label: 'Donor Type',
    url_property: 'DonorCategorizationFilter',
    checkedValues: [],
    checks: [
      {
        key: '3',
        label: 'Partner'
      },
      {
        key: '2',
        label: 'Friend'
      },
      {
        key: '1',
        label: 'Acquaintance'
      },
      {
        key: '0',
        label: 'Stranger'
      }
    ]
  },
  {
    type: 'DROPDOWN',
    label: 'Watchlist/Hidden',
    url_property: 'viewFilter',
    checkedValues: [],
    checks: [
      {
        key: 'hidden',
        label: 'Hidden'
      },
      {
        key: 'watched',
        label: 'Watchlist'
      }
    ]
  }
];
