import React from 'react';
import { TileContent } from './elements';

interface BasicTileContentProps {
  className: string;
  children: React.ReactChild;
}

const BasicTileContent: React.FC<BasicTileContentProps> = ({ className, children }) => {
  return <TileContent className={className}>{children}</TileContent>;
};

export default BasicTileContent;
