import styled from 'styled-components/macro';
import { breakpoints } from 'components/styles';

interface WrapperProps {
  wide?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  flex: 1;
  max-width: ${props => (props.wide ? '1328px' : '1170px')};
  padding: 0px 22px;
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    padding: 0px 32px;
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    padding: 0px 48px;
  }

  @media only screen and (min-width: ${breakpoints.container}) {
    padding: 0px 16px;
  }
`;
