import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AnimatePresence } from 'framer-motion';

import { palette } from 'components/styles';
import WatchlistToggle from 'components/watchlist-toggle';
import { BasicTile } from 'components/ui-components';
import {
  ProspectCardWrapper,
  ProspectCardBasicTile,
  ProspectSkeletonWrapper,
  ProspectContentWrapper,
  ProspectContent,
  WatchlistToggleIconWrapper,
  DropDownButton,
  CardClassification,
  DonorInfoTileContent,
  DropdownList,
  DropdownListContainer,
  ListItem
} from './elements';
import ProspectAvatar from 'components/prospect-avatar';
import Classification from 'components/classification';
import { useHistory } from 'react-router-dom';
import { SvgArrowDown } from 'components/svg-components';
import { useOuterClick } from 'utils';

export const ProspectCard = props => {
  const { loading, prospect, backgroundColor, organizationRaises, textColor } = props;
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const fade = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
    transition: { duration: 0.3 }
  };

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  const renderWatchlistToggle = () => {
    return <WatchlistToggle matchId={prospect.id} prospect={prospect} />;
  };

  const handleDonorRaiseClicked = clickedRaiseId => {
    history.push(`/organization/prospect/${prospect.id}?raiseId=${clickedRaiseId}`);
  };

  const renderRaiseOptions = () => {
    const raiseOptions = prospect.raises.map(raise => {
      const foundRaise = organizationRaises.find(orgRaise => orgRaise.raiseId === raise.raiseId);
      return (
        <ListItem
          onClick={() => handleDonorRaiseClicked(foundRaise?.raiseId)}
          key={foundRaise?.raiseId}
          selected={prospect.raises[0].raiseId === foundRaise?.raiseId}
        >
          <p role="menuitem">{foundRaise?.raise}</p>
        </ListItem>
      );
    });

    return raiseOptions;
  };

  const renderRaiseButton = () => {
    let raiseButton;

    if (prospect.raises && prospect.raises.length === 1) {
      const prospectRaiseId = prospect.raises[0].raiseId;
      const foundRaise = organizationRaises.find(orgRaise => orgRaise.raiseId === prospectRaiseId);

      raiseButton = (
        <DropDownButton onClick={() => handleDonorRaiseClicked(prospectRaiseId)}>{foundRaise?.raise}</DropDownButton>
      );
    } else {
      raiseButton = (
        <DropDownButton onClick={() => setIsOpen(!isOpen)} ref={innerRef}>
          {`${prospect.raises.length} Raises`}
          <SvgArrowDown fill={palette.pureBlack} />
          <AnimatePresence>
            {isOpen && (
              <DropdownListContainer key="dropContainer" {...fade}>
                <DropdownList role="menu">{renderRaiseOptions()}</DropdownList>
              </DropdownListContainer>
            )}
          </AnimatePresence>
        </DropDownButton>
      );
    }

    return raiseButton;
  };

  const renderProspectCard = () => {
    return (
      <ProspectCardWrapper data-testid="prospect-card">
        <WatchlistToggleIconWrapper>{renderWatchlistToggle()}</WatchlistToggleIconWrapper>
        <ProspectContentWrapper>
          <ProspectAvatar prospect={prospect} backgroundColor={backgroundColor} textColor={textColor} />
          <ProspectContent data-testid="prospect-content">
            <BasicTile.Title>{prospect.FullName}</BasicTile.Title>
            <CardClassification>
              <Classification.Icon barHeight={18} barWidth={5} classificationId={prospect.donorClassification} />
              <Classification.Title
                fontWeight={400}
                color={palette.pureBlack}
                classificationId={prospect.donorClassification}
              />
              <Classification.Infotip classificationId={prospect.donorClassification} />
            </CardClassification>
            <DonorInfoTileContent>
              <div>
                <p>{prospect.getFormattedJobTitleEmployer()}</p>
                <p>{prospect.getFormattedLocation()}</p>
              </div>
            </DonorInfoTileContent>
            {renderRaiseButton()}
          </ProspectContent>
        </ProspectContentWrapper>
      </ProspectCardWrapper>
    );
  };

  const renderSkeleton = () => {
    return (
      <>
        <ProspectSkeletonWrapper>
          <Skeleton circle={true} height={160} width={160} />
        </ProspectSkeletonWrapper>
        <BasicTile.Title>
          <Skeleton height={30} width={160} />
        </BasicTile.Title>
        <BasicTile.Description>
          <Skeleton width={200} />
        </BasicTile.Description>
      </>
    );
  };

  return (
    <ProspectCardBasicTile>
      {loading && renderSkeleton()}
      {!loading && renderProspectCard()}
    </ProspectCardBasicTile>
  );
};

export default ProspectCard;
