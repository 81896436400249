import React from 'react';

const SvgFilter = ({ fill, stroke }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="4.25" y1="20" x2="4.25" y2="4.16099e-08" stroke="black" strokeWidth="1.5" />
      <line x1="14.25" y1="20" x2="14.25" y2="-3.90883e-08" stroke="black" strokeWidth="1.5" />
      <circle cx="14" cy="8" r="3.5" fill="white" stroke="black" />
      <circle cx="4" cy="14" r="3.5" fill="white" stroke="black" />
    </svg>
  );
};

export { SvgFilter };
