import React from 'react';
import { Title } from './elements';

interface BasicTileTitleProps {
  className: string;
  children: React.ReactChild;
  color: string;
  fontWeight: string;
}

const BasicTileTitle: React.FC<BasicTileTitleProps> = ({ className, children, ...restProps }) => {
  return (
    <Title color={restProps.color} fontWeight={restProps.fontWeight}>
      {children}
    </Title>
  );
};

export default BasicTileTitle;
