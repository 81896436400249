import styled from 'styled-components/macro';

import { xsBody, body } from 'components/mixins';
import { palette } from 'components/styles';
import ProspectSection from 'components/prospect-profile/prospect-section';

export const BioContent = styled.div`
  h2:first-of-type {
    margin-top: 0;
  }
`;

export const BioTitle = styled(ProspectSection.Title)`
  display: block;
  margin-bottom: 16px;
  margin-top: 48px;
`;

export const BioParagraph = styled.div`
  ${body(palette.pureBlack)};

  p {
    margin-top: 16px;
  }

  ul,
  li {
    padding: 0;
    margin: 0 8px;
    ${body(palette.pureBlack)};

    &::marker {
      ${xsBody(palette.pureBlack)};
    }
  }

  a,
  a:visited {
    font-size: inherit;
    font-weight: 600;
    color: ${palette.brandBlue};
  }
`;
