import React, { useState } from 'react';
import {
  AppLinkContainer,
  ContentWrapper,
  DiagnosticContainer,
  ErrorMessageContainer,
  HeaderLabelWrapper,
  Headline,
  IconWrapper,
  LoginErrorContainer,
  RetryLink
} from './elements';
import { useLocation } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { SvgPlus, SvgMinus } from 'components/svg-components';
import { palette } from 'components/styles';

const LoginErrorPage = (): JSX.Element => {
  const location = useLocation();
  const message = location.state?.message;
  const [open, setOpen] = useState(false);

  const handleTriggerClick = () => {
    setOpen(!open);
  };

  const collapsibleHeader = () => {
    return (
      <>
        <IconWrapper>
          {!open && <SvgPlus stroke={palette.pureBlack} fill={palette.pureWhite} />}
          {open && <SvgMinus stroke={palette.pureBlack} fill={palette.pureWhite} />}
        </IconWrapper>
        <HeaderLabelWrapper>
          <h3>Diagnostic Information</h3>
        </HeaderLabelWrapper>
      </>
    );
  };

  return (
    <>
      <LoginErrorContainer>
        <Headline>Something went wrong while trying to log you in</Headline>
        <ErrorMessageContainer>
          <p>
            If you are using a bookmark to open the application, please make sure the bookmark links to the application
            URL shown below.
          </p>
          <AppLinkContainer>
            <RetryLink href="https://app.atticustechnology.com"> https://app.atticustechnology.com</RetryLink>
          </AppLinkContainer>
        </ErrorMessageContainer>
        <DiagnosticContainer>
          <Collapsible trigger={collapsibleHeader()} {...{ open, handleTriggerClick }}>
            <ContentWrapper>{message ?? 'No diagnostic information is available.'}</ContentWrapper>
          </Collapsible>
        </DiagnosticContainer>
      </LoginErrorContainer>
    </>
  );
};

export default LoginErrorPage;
