class ProspectOrgConnection {
  donorRoleTarget: string;
  firstLink: string | undefined | null;
  secondLink: string | undefined | null;
  people: any;
  secondDegreeConnection: string | undefined | null;
  firstDegreeConnection: string | undefined | null;
  numDegrees: number | undefined | null;

  constructor(data: any) {
    this.donorRoleTarget = data.donor_role_target;
    this.secondDegreeConnection = data.second_degree_org_connection;
    this.firstDegreeConnection = data.first_degree_org_connection;
    this.people = data.people;
  }

  initLinks() {
    const cleansedFirstDegree = this.firstDegreeConnection ? this.firstDegreeConnection.trim() : null;
    const cleansedSecondDegree = this.secondDegreeConnection ? this.secondDegreeConnection.trim() : null;

    this.firstLink = cleansedSecondDegree ? cleansedSecondDegree : cleansedFirstDegree;
    this.secondLink = cleansedSecondDegree ? cleansedFirstDegree : null;
    this.numDegrees = this.secondLink ? 2 : 1;
  }
}

export default ProspectOrgConnection;
