import * as React from 'react';
import { palette } from 'components/styles';

interface SvgLogoAltProps {
  fill?: string;
}

const SvgLogoAlt = (props: SvgLogoAltProps) => {
  const { fill = palette.brandBlue } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="640" height="85" viewBox="0 0 638.2 84.71">
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            fill={fill}
            points="42.35 32.73 68.35 84.71 84.71 84.71 42.35 0.01 0 84.71 16.36 84.71 42.35 32.73"
          />
          <path fill={fill} d="M302.22,0h14V84.71h-14Z" />
          <path
            fill={fill}
            d="M387.31,0a40.88,40.88,0,0,1,16.41,3.3,42.85,42.85,0,0,1,13.49,9.15l-10,10a28.14,28.14,0,0,0-39.91,0A27.1,27.1,0,0,0,359,42.35a28.31,28.31,0,0,0,48.21,20l10,9.89a43,43,0,0,1-13.49,9.15,42.44,42.44,0,0,1-46.44-9.15,40.53,40.53,0,0,1-12.45-29.91,40.78,40.78,0,0,1,12.45-29.9A40.67,40.67,0,0,1,387.31,0Z"
          />
          <path
            fill={fill}
            d="M516.33,49.43a34.14,34.14,0,0,1-10.37,25,35.22,35.22,0,0,1-60.18-25V0h14V49.43a20.23,20.23,0,0,0,6.1,14.89,20.48,20.48,0,0,0,15,6.23,20.08,20.08,0,0,0,14.89-6.23,20.12,20.12,0,0,0,6.34-14.89V0h14.16Z"
          />
          <path
            fill={fill}
            d="M568.08,49.43a23.77,23.77,0,0,1-17.45-7.2,23.91,23.91,0,0,1-7.2-17.57,23.65,23.65,0,0,1,7.2-17.34A23.31,23.31,0,0,1,568.08,0H614V14.16H568.08a10.91,10.91,0,0,0-4.15.79,10.25,10.25,0,0,0-3.42,2.26,10.41,10.41,0,0,0-2.26,3.42,10.75,10.75,0,0,0-.79,4,10.53,10.53,0,0,0,3,7.56,10.08,10.08,0,0,0,7.57,3.18H589.2A24.79,24.79,0,0,1,614,60.05a23.85,23.85,0,0,1-7.32,17.46,23.77,23.77,0,0,1-17.45,7.2H543.43V70.55H589.2a10.94,10.94,0,0,0,4.15-.79,10.58,10.58,0,0,0,3.41-2.2A9.62,9.62,0,0,0,599,64.2a10.87,10.87,0,0,0,.79-4.15,10.17,10.17,0,0,0-3.17-7.44,9.91,9.91,0,0,0-7.44-3.18Z"
          />
          <polygon fill={fill} points="124.51 30.36 124.51 84.71 138.66 84.71 138.66 24.95 124.51 30.36" />
          <rect fill={fill} x="96.19" width="70.67" height="13.62" />
          <polygon fill={fill} points="166.85 14.16 166.86 14.16 166.86 14.16 166.85 14.16" />
          <rect fill={fill} x="199.2" width="70.67" height="13.62" />
          <polygon fill={fill} points="227.52 30.27 227.52 84.71 241.68 84.71 241.68 24.86 227.52 30.27" />
          <polygon fill={fill} points="269.63 14.16 269.88 14.16 269.88 14.07 269.63 14.16" />
          <text fill={fill} fontFamily="Barlow" fontSize="36px" x="621" y="26">
            &#x00ae;
          </text>
        </g>
      </g>
    </svg>
  );
};

export { SvgLogoAlt };
