import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FadeIn } from 'components/ui-components';
import { palette } from 'components/styles';
import {
  Avatar,
  HeaderColumnsWrapper,
  ColumnItem,
  ProspectColumnItem,
  ColumnTitle,
  ColumnSubTitle,
  ProspectHorizontalItem,
  ColumnItemText
} from './elements';
import Classification from 'components/classification';
import WatchlistToggle from 'components/watchlist-toggle';
import { NewDonorTag } from 'components/ui-components/new-donor-tag';

const cardAvatarBackgrounds = [
  { bg: palette.brandDarkBlue, text: palette.pureWhite },
  { bg: palette.brandLightBlue, text: palette.pureBlack },
  { bg: palette.navyBackground, text: palette.pureWhite }
];

export const ProspectHorizontalList = props => {
  const { prospects } = props;

  const renderProspectItem = (prospect, index, indexCounter) => {
    const rowBackground = index % 2 === 0 ? palette.background : palette.gray7;
    const avatarBackground = cardAvatarBackgrounds[indexCounter].bg;
    const text = cardAvatarBackgrounds[indexCounter].text;

    return (
      <Link
        data-testid="prospect-row-link"
        to={`organization/prospect/${prospect.id}?raiseId=${prospect?.raises[0]?.raiseId}`}
      >
        <ProspectHorizontalItem background={rowBackground}>
          <ProspectColumnItem columnType="WATCH">
            <WatchlistToggle matchId={prospect.id} prospect={prospect} />
            <Avatar backgroundColor={avatarBackground} textColor={text}>
              <div>
                {prospect.photo && <img src={prospect.photo} alt="prospect-avatar" />}
                {!prospect.photo && <p>{prospect.getDonorInitials()}</p>}
              </div>
            </Avatar>
          </ProspectColumnItem>
          <ProspectColumnItem columnType="NAME">
            <ColumnItemText>
              <p data-testid="prospect-row-name">{prospect.FullName}</p>
              <NewDonorTag size="small" firstPublishedEpoch={prospect.firstPublishedEpoch} />
            </ColumnItemText>
          </ProspectColumnItem>
          <ProspectColumnItem columnType="LOCATION">
            <ColumnItemText>
              <p data-testid="prospect-row-location">{prospect.getFormattedLocation()}</p>
            </ColumnItemText>
          </ProspectColumnItem>
          <ProspectColumnItem columnType="TYPE">
            <ColumnItemText>
              <Classification.Title color={palette.pureBlack} classificationId={prospect.donorClassification} />
              <Classification.Infotip classificationId={prospect.donorClassification} />
            </ColumnItemText>
          </ProspectColumnItem>
          <ProspectColumnItem columnType="RAISES">
            <ColumnItemText>
              <p data-testid="prospect-row-raises">{prospect.raiseNames}</p>
            </ColumnItemText>
          </ProspectColumnItem>
        </ProspectHorizontalItem>
      </Link>
    );
  };

  const renderSkeletonItem = index => {
    const rowBackground = index % 2 === 0 ? palette.background : palette.gray7;

    return (
      <ProspectHorizontalItem background={rowBackground}>
        <ProspectColumnItem columnType="WATCH">
          <Skeleton circle={true} height={20} width={20} />
          <Skeleton circle={true} height={56} width={56} />
        </ProspectColumnItem>
        <ProspectColumnItem columnType="NAME">
          <Skeleton height={20} width={115} />
        </ProspectColumnItem>
        <ProspectColumnItem columnType="LOCATION">
          <Skeleton height={20} width={115} />
        </ProspectColumnItem>
        <ProspectColumnItem columnType="TYPE">
          <Skeleton height={20} width={95} />
        </ProspectColumnItem>
        <ProspectColumnItem columnType="RAISES">
          <Skeleton height={20} width={115} />
        </ProspectColumnItem>
      </ProspectHorizontalItem>
    );
  };

  const renderProspectItems = () => {
    let renderCards;
    let indexCounter = 0;

    if (prospects && prospects.length > 0) {
      renderCards = prospects.map((prospect, index) => {
        if (!prospect.photo) {
          indexCounter = indexCounter < 2 ? (indexCounter += 1) : 0;
        }
        return (
          <FadeIn delay={0.1 * (index % 4)} key={prospect.id}>
            {prospect.loading && renderSkeletonItem(index)}
            {!prospect.loading && renderProspectItem(prospect, index, indexCounter)}
          </FadeIn>
        );
      });
    }

    return renderCards;
  };

  const renderColumnHeaders = () => {
    return (
      <HeaderColumnsWrapper>
        <ColumnItem columnType="WATCH_TITLE">
          <ColumnTitle>WATCHLIST</ColumnTitle>
          <ColumnSubTitle>ADD OR REMOVE</ColumnSubTitle>
        </ColumnItem>
        <ColumnItem columnType="NAME">
          <ColumnTitle>NAME</ColumnTitle>
          <ColumnSubTitle></ColumnSubTitle>
        </ColumnItem>
        <ColumnItem columnType="LOCATION">
          <ColumnTitle>LOCATION</ColumnTitle>
          <ColumnSubTitle></ColumnSubTitle>
        </ColumnItem>
        <ColumnItem columnType="TYPE">
          <ColumnTitle>DONOR TYPE</ColumnTitle>
          <ColumnSubTitle></ColumnSubTitle>
        </ColumnItem>
        <ColumnItem columnType="RAISES">
          <ColumnTitle>RAISES</ColumnTitle>
          <ColumnSubTitle></ColumnSubTitle>
        </ColumnItem>
      </HeaderColumnsWrapper>
    );
  };

  return (
    <div data-testid="prospect-list">
      {renderColumnHeaders()}
      {renderProspectItems()}
    </div>
  );
};

export default ProspectHorizontalList;
