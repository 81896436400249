export class MutualConnectionLink {
  name: string;
  personDetails: string;
  strength: string;
  strengthReason: string;

  constructor(data: any) {
    this.name = data?.name ?? '';
    this.personDetails = data?.person_details ?? '';
    this.strength = data?.strength ?? '';
    this.strengthReason = data?.strength_reason ?? '';
  }
}

class ProspectMutualConnection {
  strengthToDonor: string;
  strengthToDonorReason: string;
  numDegrees: number;
  firstConnectionName: string;
  links: MutualConnectionLink[];

  constructor(data: any) {
    this.strengthToDonor = data?.strength_to_donor ?? '';
    this.strengthToDonorReason = data?.strength_to_donor_reason ?? '';
    this.links = [];
    this.numDegrees = 0;
    this.firstConnectionName = '';
  }
}

export default ProspectMutualConnection;
