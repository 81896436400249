import React from 'react';
import { TextUnorderedListItem } from './elements';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Item: React.FunctionComponent<Props> = ({ className, children, ...restProps }) => {
  return (
    <TextUnorderedListItem className={className} {...restProps}>
      {children}
    </TextUnorderedListItem>
  );
};

export default Item;
