import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { motion } from 'framer-motion';
import { nanoBody, xsBody } from 'components/mixins';
import { breakpoints } from 'components/styles';

export const ConnectionsHeader = styled.div`
  font-family: Barlow;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin: 40px 75px;
`;

export const Chains = styled.div`
  width: 260px;
  margin: 0 auto;
`;

export const MobileConnectionCard = styled(motion.div)`
  width: 100%;
  height: 629px;
  overflow-y: scroll;
  position: absolute;
  background-color: white;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const RightColumn = styled(motion.div)`
  width: 100%;
  height: 629px;
  overflow-y: scroll;
  position: absolute;
  background: white;
  box-shadow: -7px 6px 60px 9px rgba(82, 82, 82, 0.5);

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @media only screen and (max-width: ${breakpoints['tablet-lg']}) {
    opacity: 1 !important;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    box-shadow: none;
    position: relative;
    width: 65%;
    background: none;
    transform: none !important;
  }
`;

export const ChainConnectorContent = styled.p`
  font-style: italic;
  padding: 28px 4px;
  border-left: 1px solid ${palette.gray2};
  margin-left: 14px;
  ${nanoBody(palette.pureBlack)}
  font-weight: 400;
`;

export const ChainLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-bottom: 2px;
    ${nanoBody(palette.pureBlack)};
    line-height: 16px;
    font-weight: 400;
  }

  p:first-child {
    ${xsBody(palette.pureBlack)};
    font-weight: 600;
  }
`;

export const ChainLinkIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 6px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 58px;

  > p {
    font-weight: 600;
    margin-left: 8px;
  }
`;

interface AvatarProps {
  backgroundColor: string;
  textColor: string;
}

export const Avatar = styled.div<AvatarProps>`
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};

  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    color: ${props => props.textColor};
    font-weight: 600;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    background-color: ${palette.navyBackground};
  }
`;
