import React from 'react';
import styled, { css } from 'styled-components/macro';
import { palette } from 'components/styles';
import { microBody } from 'components/mixins';
import { Link } from 'react-router-dom';
import { xsBody } from 'components/mixins';
import { motion } from 'framer-motion';

interface DropdownState {
  dropOpen: boolean;
}

export const RootLI = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavStyles = css`
  position: relative;
  font-size: 16px;
  color: ${palette.pureWhite};
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const StandardNavLink = styled(({ activeRoute, weight, isDropdown, dropOpen, ...rest }) => <Link {...rest} />)`
  ${NavStyles}
  font-weight: ${props => props.weight};
  padding: ${props => (props.isDropdown ? '12px 24px 12px 16px' : '12px 16px 12px 16px')};
  border-radius: ${props => (props.isDropdown ? '8px 0px 0px 8px' : '8px')};
  border: ${props => `1px solid ${props.dropOpen ? palette.navyHover : palette.navyBackground}`};
  transition: all 450ms ease-in-out;

  &:hover {
    background-color: ${palette.navyHover};
  }

  > p:first-of-type {
    text-transform: uppercase;
  }

  > p {
    position: relative;
    ${microBody(palette.pureWhite)};
    font-weight: 600;
    margin-bottom: 4px;
  }

  > p:last-of-type:before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: ${props => (props.activeRoute ? '100%' : 0)};
    height: 1px;
    background-color: ${palette.pureWhite};
    transition: all 450ms ease-in-out;
  }
`;

interface DropdownInterface {
  opened?: boolean;
}

export const DropdownListContainer = styled(motion.div)`
  position: absolute;
  z-index: 30;
  width: 100%;
  top: calc(100% + 4px);
  left: 0;
  min-width: 100%;
  width: 100%;
  background-color: ${palette.navyBackground};
  border: 1px solid ${palette.navyHover};
  border-radius: 8px;
`;

export const DropdownList = styled.ul<DropdownInterface>`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

interface ListItemProps extends DropdownInterface {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 8px 11px;
  margin: 4px 0;
  background-color: ${props => (props.selected ? palette.brandBlue : palette.navyBackground)};
  cursor: pointer;
  transition: all 450ms ease-in-out;

  &:hover {
    background-color: ${palette.navyHover};
  }

  > p {
    color: ${xsBody(palette.pureWhite)};
  }
`;

export const ToggleWrapper = styled.button<DropdownState>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 14px;
  text-decoration: none;
  background-color: ${palette.navyBackground};
  border: ${props => `1px solid ${props.dropOpen ? palette.navyHover : palette.navyBackground}`};
  border-left: 1px solid ${palette.navyHover};
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  transition: all 450ms ease-in-out;

  &:hover {
    background-color: ${palette.navyHover};
  }
`;
