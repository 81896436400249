import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { scopes } from '../config/authConfig';
import { extendSession } from 'components/session-timeout';

const userPrefsKey = 'user_prefs';

const myError = error => {
  return {
    title: error.message ?? 'Error',
    detail: `A ${
      error.response?.status ? '[' + error.response.status + ']' : 'server'
    } error has occured. Please try again.`
  };
};

const axiosInstance = tokenResponse => {
  return axios.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenResponse.accessToken}`
    }
  });
};

const getRefreshToken = async (user, instance) => {
  return new Promise(async (resolve, reject) => {
    const tokenRequest = { scopes: [scopes.allAccess], account: user };
    try {
      const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
      resolve(tokenResponse);
    } catch (error) {
      clearStorage();
      if (error instanceof InteractionRequiredAuthError) {
        if (user) {
          try {
            const tokenPopupResponse = await instance.acquireTokenRedirect(tokenRequest);
            resolve(tokenPopupResponse);
          } catch (error) {
            reject(myError(error));
          }
        } else {
          reject(myError(error));
        }
      } else {
        reject(myError(error));
      }
    }
  });
};

const handleErrors = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
};

export const getEntitiesByUrl = async (entityUrl, user, instance, abortSignal) => {
  extendSession();
  const refreshToken = await getRefreshToken(user, instance);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance(refreshToken).get(`${entityUrl}`, { signal: abortSignal });
      resolve(response);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        handleErrors(error);
        reject(myError(error));
      }
    }
  });
};

export const postByUrl = async (entityUrl, user, instance) => {
  extendSession();
  const refreshToken = await getRefreshToken(user, instance);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance(refreshToken).post(`${entityUrl}`);
      resolve(response);
    } catch (error) {
      handleErrors(error);
      reject(myError(error));
    }
  });
};

export const getWithoutAuthorization = async entityUrl => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'get',
        url: entityUrl,
        headers: { 'Content-Type': 'application/json' }
      });
      resolve(response);
    } catch (error) {
      handleErrors(error);
      reject(myError(error));
    }
  });
};

export const clearStorage = () => {
  document.cookie.split(';').forEach(c => {
    console.log(c);
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
  if (process.env.REACT_APP_CACHE_LOCATION === 'localStorage') {
    const prefs = getUserPrefs();
    localStorage.clear();
    setUserPrefs(prefs);
  } else {
    sessionStorage.clear();
  }
};

export const getUserPrefs = () => {
  return JSON.parse(localStorage.getItem(userPrefsKey));
};

export const setUserPrefs = prefs => {
  localStorage.setItem(userPrefsKey, JSON.stringify(prefs));
};

export const addCriteriaToUserPrefs = (criteria, orgId) => {
  const prefs = getUserPrefs() ?? {};
  prefs[orgId] = criteria;
  setUserPrefs(prefs);
};

export const getCriteriaFromUserPrefs = orgId => {
  const prefs = getUserPrefs();
  if (!prefs || !prefs[orgId]) {
    return undefined;
  }
  return prefs[orgId];
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
