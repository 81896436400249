import React from 'react';
import { SubTitle } from './elements';

interface BasicTileSubTitleProps {
  className: string;
  children: React.ReactChild;
  textStyle: string;
}

const BasicTileSubTitle: React.FC<BasicTileSubTitleProps> = ({
  className,
  children,
  ...restProps
}: BasicTileSubTitleProps) => {
  return (
    <SubTitle className={className} textStyle={restProps.textStyle}>
      {children}
    </SubTitle>
  );
};

export default BasicTileSubTitle;
