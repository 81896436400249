import React from 'react';

const SvgVisible = ({ fill = '#007CFF', stroke = '#FFFFFF' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={fill} />
      <path
        d="M3.5835 10.0002C3.5835 10.0002 5.91683 5.3335 10.0002 5.3335C14.0835 5.3335 16.4168 10.0002 16.4168 10.0002C16.4168 10.0002 14.0835 14.6668 10.0002 14.6668C5.91683 14.6668 3.5835 10.0002 3.5835 10.0002Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.75C10.9665 11.75 11.75 10.9665 11.75 10C11.75 9.0335 10.9665 8.25 10 8.25C9.0335 8.25 8.25 9.0335 8.25 10C8.25 10.9665 9.0335 11.75 10 11.75Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgVisible };
