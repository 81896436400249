import React from 'react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { ArticleSection, ArticleGrid, BasicTileDateWrapper, TileLink, ArticleSnippet } from './elements';
import ProspectSection from 'components/prospect-profile/prospect-section';
import { FadeIn, BasicTile } from 'components/ui-components';

const ProspectNews = props => {
  const { id, prospect } = props;
  const appInsights = useAppInsightsContext();
  const trackNewsLink = useTrackEvent(appInsights, 'PROFILE | Link Click', prospect);

  const getPublisherFromSource = url => {
    let hostname = '';

    if (url) {
      try {
        const sourceURL = new URL(url);
        hostname = sourceURL.hostname;
        hostname = hostname ? hostname.replace('www.', '') : '';
      } catch {
        hostname = 'Invalid Url';
      }
    }

    return hostname;
  };

  const renderArticleTiles = () => {
    let renderedTiles = [];

    renderedTiles = prospect.articles.map((article, index) => {
      const publisher = article.publisher ? article.publisher : getPublisherFromSource(article.url);
      const formattedPublishDate = article.date_published ? prospect.getFormattedDate(article.date_published) : '';

      return (
        <FadeIn key={index}>
          <div>
            <TileLink
              href={article.url}
              border={true}
              target="_blank"
              onClick={() => trackNewsLink({ linkType: 'NEWS' })}
            >
              <BasicTile.Description>
                <strong>{article.title}</strong>
              </BasicTile.Description>
              <BasicTileDateWrapper>
                <BasicTile.Description textStyle="italic">{publisher}</BasicTile.Description>
                <BasicTile.Description>{formattedPublishDate}</BasicTile.Description>
              </BasicTileDateWrapper>
              <ArticleSnippet>{article.snippet}</ArticleSnippet>
            </TileLink>
          </div>
        </FadeIn>
      );
    });

    return renderedTiles;
  };

  return (
    <>
      <ProspectSection id={id}>
        <ProspectSection.Title>News</ProspectSection.Title>
        <ProspectSection.SubTitle>
          {`Compilation of recent and relevant news about ${prospect.PreferredFirstName}`}.
        </ProspectSection.SubTitle>
        {prospect.articles && prospect.articles.length > 0 && (
          <ArticleSection>
            <ProspectSection.Label>{`Articles`}</ProspectSection.Label>
            <ArticleGrid>{renderArticleTiles()}</ArticleGrid>
          </ArticleSection>
        )}
      </ProspectSection>
    </>
  );
};

export default ProspectNews;
