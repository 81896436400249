import React from 'react';
import { Redirect } from 'react-router-dom';

import ProspectListPage from 'pages/prospect-list-page';
import ProspectDetailPage from 'pages/prospect-detail-page';

export const config = {
  BROWSE: {
    id: 'PROSPECT_LIST',
    path: '/prospect-list',
    component: <ProspectListPage />,
    exact: true
  },
  PROSPECT_DETAIL: {
    id: 'PROSPECT_DETAIL',
    path: '/organization/prospect/:prospectId',
    component: <ProspectDetailPage />
  },
  DEFAULT: {
    id: 'DEFAULT',
    path: '/*',
    component: <Redirect to="/prospect-list" />
  }
};

export default config;
