class ProspectRaise {
  alignment: string;
  alignmentReason: string;
  highlights: any[];
  raiseId: string;
  priority: string;
  proratedAskAmount: number;
  singleAskAmount: number;
  raiseName: string;

  constructor(
    org_raise_id?: string,
    donor_alignment?: string,
    donor_alignment_reason?: string,
    highlights?: any[],
    priority?: string,
    prorated_ask_amount?: number,
    single_ask_amount?: number,
    raise_name?: string
  ) {
    this.raiseId = org_raise_id ?? 'id';
    this.alignment = donor_alignment ?? 'alignment';
    this.alignmentReason = donor_alignment_reason ?? 'LOW';
    this.highlights = highlights ?? [];
    this.priority = priority ?? '1';
    this.proratedAskAmount = prorated_ask_amount ?? 0;
    this.singleAskAmount = single_ask_amount ?? 0;
    this.raiseName = raise_name ?? 'Default';
  }
}

export default ProspectRaise;
