import { body } from 'components/mixins';
import { palette } from 'components/styles';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 629px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    ${body(palette.pureBlack)}
    margin: 0px 32px 0px 32px;
  }
`;
