import React, { useState, useEffect } from 'react';
import { Linkedin } from 'react-feather';

import { palette } from 'components/styles';
import { Container } from 'components/ui-components';
import {
  FooterSection,
  FooterContentWrapper,
  OrgBrandWrapper,
  FooterLogoLink,
  FooterCopyrightWrapper,
  FooterItemsWrapper,
  SocialIconsWrapper,
  StandardNavLink,
  SocialIconLink,
  LogoWrapper,
  FooterNavItem
} from './elements';
import RouteConfig from 'routes/routes-config';
import { useMsalUserManager } from 'hooks/useManageMsalUser';
import { getWithoutAuthorization } from 'services/utilities';

const Footer = () => {
  const { user, instance, brand } = useMsalUserManager();
  const { idTokenClaims } = user || {};
  const { city: orgId } = idTokenClaims || {};
  const [orgBrand, setOrgBrand] = useState({});
  const [displayBrandLogo, setDisplayBrandLogo] = useState(true);
  const orgLogoSrc = `https://atticus.azureedge.net/org-${orgId}/logo.svg`;
  const orgBrandJson = `https://atticus.azureedge.net/org-${orgId}/branding.json`;

  const menuProps = [
    {
      id: 'contact',
      content: 'Contact Us',
      type: 'EXTERNAL',
      href: 'mailto:clientsuccess@atticustechnology.com'
    },
    {
      id: 'policy',
      content: 'Privacy Policy',
      type: 'EXTERNAL',
      href: 'https://atticustechnology.com/privacy-policy'
    },
    {
      id: 'terms',
      content: 'Terms & Conditions',
      type: 'EXTERNAL',
      href: 'https://atticustechnology.com/terms'
    }
  ];

  useEffect(() => {
    const getOrgBranding = async () => {
      try {
        const results = await getWithoutAuthorization(orgBrandJson, user, instance);

        if (results.data) {
          setOrgBrand({ ...results.data });
        }
      } catch (error) {
        console.log(error);
      }
    };

    getOrgBranding();
  }, [orgBrandJson, user, instance]);

  const renderNavItems = () => {
    return menuProps.map(item => {
      return <FooterNavItem key={item.id} {...item} />;
    });
  };

  return (
    <>
      <FooterSection>
        <Container wide={true}>
          {brand && (
            <FooterContentWrapper>
              <FooterLogoLink to="/">
                <LogoWrapper>{brand && brand.logo}</LogoWrapper>
                <OrgBrandWrapper>
                  {displayBrandLogo && (
                    <div>
                      <img onError={() => setDisplayBrandLogo(false)} src={orgLogoSrc} alt="atticus-logo" />
                    </div>
                  )}
                  {!displayBrandLogo && <h2>{orgBrand.name}</h2>}
                </OrgBrandWrapper>
              </FooterLogoLink>
              <FooterItemsWrapper>{renderNavItems()}</FooterItemsWrapper>
            </FooterContentWrapper>
          )}
        </Container>
      </FooterSection>
      <FooterSection>
        <Container wide={true}>
          <FooterCopyrightWrapper>
            <StandardNavLink
              to={RouteConfig.BROWSE.path}
            >{`Copyright © 2022 ${brand.name} Technology`}</StandardNavLink>
            <SocialIconsWrapper>
              <SocialIconLink
                href={'https://www.linkedin.com/company/atticus-technology-services/'}
                aria-label="Atticus LinkedIn"
                target="_blank"
              >
                <Linkedin size={18} stroke={palette.navyBackground} fill={palette.navyBackground} />
              </SocialIconLink>
            </SocialIconsWrapper>
          </FooterCopyrightWrapper>
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
