import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { palette, breakpoints } from 'components/styles';
import { microBody } from 'components/mixins';
import { NavItem } from 'components/ui-components';

export const FooterSection = styled.section`
  border-top: 1px solid ${palette.fadedBlue};
  background-color: ${palette.navyBackground};
`;

export const OrgBrandWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid ${palette.pureWhite};

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    height: 64px;
    margin-left: 32px;
    padding-left: 32px;
  }

  > div {
    height: 20px;
    max-width: 150px;
    margin-right: 20px;
    border: 1px solid ${palette.navyBackground};

    @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
      height: 40px;
    }

    @media only screen and (min-width: ${breakpoints['laptop']}) {
      height: 48px;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    color: ${palette.pureWhite};

    @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
      font-size: 24px;
      line-height: 40px;
    }
  }
`;

export const FooterContentWrapper = styled.div`
  padding: 20px 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    flex-direction: row;
    padding: 30px 0;
  }
`;

export const FooterCopyrightWrapper = styled.div`
  padding: 24px 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const LogoWrapper = styled.div`
  img,
  svg {
    height: 14px;
    width: auto;

    @media only screen and (min-width: ${breakpoints['laptop']}) {
      height: 26px;
    }
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
`;

export const StandardNavLink = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: ${palette.pureWhite};
  text-decoration: none;
  margin-top: 24px;

  &:visited {
    color: ${palette.pureWhite};
  }

  &.active {
    color: ${palette.pureWhite};

    &:visited {
      color: ${palette.pureWhite};
    }
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    margin-top: 0;
  }
`;

export const FooterLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 56px;
`;

export const FooterItemsWrapper = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    flex-direction: row;
    flex: 0 0 340px;
  }
`;

export const FooterNavItem = styled(props => <NavItem {...props} />)`
  ${microBody(palette.pureWhite)};
  flex: 1;
  margin-bottom: 12px;

  a {
    font-weight: 600;
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    flex: 0 0 300px;
  }
`;

export const SocialIconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${palette.pureWhite};
  margin: 0 12px;
`;
