import styled from 'styled-components/macro';
import { breakpoints, palette } from 'components/styles';
import ProspectSection from 'components/prospect-profile/prospect-section';

export const WrapperProspectSection = styled(ProspectSection)`
  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: block;
  }
`;

export const ConnectionsTileContainer = styled.div`
  max-width: 770px;
  border-radius: 8px;
  background-color: white;
  border-radius: 4px;
  margin-top: 48px;
  width: 100%;
`;

export const ComponentContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
`;

export const TileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const Badge = styled.div`
  background-color: ${palette.brandBlue};
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  line-height: 7px;
  padding: 4px 6px;
  vertical-align: text-top;
`;
