import React from 'react';

import ProspectSection from 'components/prospect-profile/prospect-section';
import { BasicTile } from 'components/ui-components';
import { ArticleSnippet, BasicTileDateWrapper, TileLink, TileSource } from './elements';

const PrintProspectNews = props => {
  const { id, prospect } = props;

  const getPublisherFromSource = source => {
    let hostname = '';

    if (source) {
      try {
        const sourceURL = new URL(source);
        hostname = sourceURL.hostname;
        hostname = hostname ? hostname.replace('www.', '') : '';
      } catch {
        hostname = 'INVALID URL';
      }
    }

    return hostname;
  };

  const renderContentRows = contentList => {
    let renderedTiles = [];

    if (contentList.length > 0) {
      renderedTiles = contentList.map((item, index) => {
        const publisher = item.publisher ? item.publisher : getPublisherFromSource(item.url);
        const formattedPublishDate = item.date_published ? prospect.getFormattedDate(item.date_published) : '';

        return (
          <div key={index}>
            <TileLink href={item.url} border={true}>
              <BasicTile.Description>
                <strong>{item.title}</strong>
              </BasicTile.Description>
              <BasicTileDateWrapper>
                <BasicTile.Description textStyle="italic">{publisher}</BasicTile.Description>
                <BasicTile.Description>{formattedPublishDate}</BasicTile.Description>
              </BasicTileDateWrapper>
              <ArticleSnippet>{item.snippet}</ArticleSnippet>
              <TileSource>{item.url}</TileSource>
            </TileLink>
          </div>
        );
      });
    }

    return renderedTiles;
  };

  return (
    <>
      <ProspectSection id={id}>
        <ProspectSection.Title>News</ProspectSection.Title>
        {prospect.articles && prospect.articles.length > 0 && (
          <>
            <div>{renderContentRows(prospect.articles)}</div>
          </>
        )}
      </ProspectSection>
    </>
  );
};

export default PrintProspectNews;
