import { palette } from 'components/styles';
import React from 'react';

interface SvgDropdownArrowProps {
  fill?: string;
  stroke?: string;
}

const SvgDropdownArrow = (props: SvgDropdownArrowProps) => {
  const { fill = palette.brandBlue, stroke = palette.pureWhite } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" fill={fill} r="10" />
      <polygon points="4,7 16,7 10,15" fill={stroke} />
    </svg>
  );
};

export { SvgDropdownArrow };
