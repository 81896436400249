import { useEffect } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export function useTrackPageView(pageTelemetry) {
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights.trackPageView(pageTelemetry);
  }, [appInsights, pageTelemetry]);
}
