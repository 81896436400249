import React from 'react';

import { Title, ClassificationWrapper, ClassificationIcon, ClassificationInfotip } from './elements';
import { CLASSIFICATIONS } from 'constants/classifications';
import { palette } from 'components/styles';
import { SvgAtticusIcon } from 'components/svg-components';
import { Infotip } from 'components/ui-components';

function Classification({ className, children, ...restProps }) {
  return <ClassificationWrapper className={className}>{children}</ClassificationWrapper>;
}

Classification.Title = function ({ className, ...restProps }) {
  const { classificationId, color = palette.pureWhite, fontWeight = 600 } = restProps;
  const foundClassification = CLASSIFICATIONS[classificationId]
    ? CLASSIFICATIONS[classificationId]
    : CLASSIFICATIONS['DEFAULT'];
  const classificationLabel = foundClassification.label;

  return (
    <Title className={className} color={color} fontWeight={fontWeight}>
      {classificationLabel}
    </Title>
  );
};

Classification.Icon = function ({ className, ...restProps }) {
  const { classificationId } = restProps;
  const foundClassification = CLASSIFICATIONS[classificationId]
    ? CLASSIFICATIONS[classificationId]
    : CLASSIFICATIONS['DEFAULT'];
  const weight = foundClassification.weight;

  const renderIcon = () => {
    return <SvgAtticusIcon fill={palette.brandBlue} fillAmount={weight} />;
  };

  return <ClassificationIcon color={restProps.color}>{renderIcon()}</ClassificationIcon>;
};

Classification.Infotip = function ({ className, ...restProps }) {
  const { classificationId, iconSize = 14, iconColor = palette.pureBlack } = restProps;
  const foundClassification = CLASSIFICATIONS[classificationId]
    ? CLASSIFICATIONS[classificationId]
    : CLASSIFICATIONS['DEFAULT'];
  const classificationTip = foundClassification.description;

  return (
    <ClassificationInfotip iconSize={iconSize} iconStrokeWidth={1} minWidth={305} iconColor={iconColor}>
      <Infotip.Paragraph>{classificationTip}</Infotip.Paragraph>
    </ClassificationInfotip>
  );
};

export default Classification;
