import React, { useState, useEffect, useRef } from 'react';
import { useMsalUserManager } from 'hooks';
import {
  SessionTimeoutButton,
  SessionTimeoutModal,
  SessionTimeoutModalHeader,
  ModalContent,
  SessionTimeoutModalFooter,
  SessionIconContainer,
  SessionTimerContainer
} from './elements';
import { motion } from 'framer-motion';
import { palette } from 'components/styles';
import { SvgAtticusIcon } from 'components/svg-components';
import { InteractionStatus } from '@azure/msal-browser';

const session_length = parseInt(process.env.REACT_APP_SESSION_LENGTH ?? '14400');
const initial_time = Math.floor(Date.now() / 1000);
const session_time = 'session_time';

export const extendSession = () => {
  const new_time = Math.floor(Date.now() / 1000);
  sessionStorage.setItem(session_time, JSON.stringify(new_time));
};

const RenderTimer = () => {
  return (
    <SessionTimerContainer>
      <svg
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        style={{
          transform: 'rotate(-90deg)',
          overflow: 'visible'
        }}
      >
        <circle cx="50" cy="50" r="50" strokeWidth="10" stroke={palette.gray7} fill="transparent" />
        <motion.circle
          cx="50"
          cy="50"
          r="50"
          key="loader"
          strokeWidth="10"
          stroke={palette.brandBlue}
          fill="transparent"
          strokeDasharray={50 * 2 * Math.PI}
          animate={{ strokeDashoffset: [50 * 2 * Math.PI, 0] }}
          transition={{ duration: 90.0, ease: 'linear' }}
        />
      </svg>
      <SessionIconContainer>
        <SvgAtticusIcon fill={palette.brandBlue} stroke={palette.brandBlue} fillAmount={0} />
      </SessionIconContainer>
    </SessionTimerContainer>
  );
};

export const SessionTimeout = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [time, setTime] = useState<number>(initial_time);
  const [timerFrequency, setTimerFrequency] = useState<number>(60);
  const clearTimer = useRef<NodeJS.Timeout | null>(null);
  const { user, instance, inProgress } = useMsalUserManager();

  useEffect(() => {
    sessionStorage.setItem(session_time, JSON.stringify(initial_time));
  }, []);

  useEffect(() => {
    const start_time = JSON.parse(sessionStorage.getItem(session_time) ?? '0');
    const current_time = Math.floor(Date.now() / 1000);
    let time_diff = current_time - start_time;
    if (time_diff < 0) {
      time_diff = 0;
      sessionStorage.setItem(session_time, JSON.stringify(current_time));
    }
    if (time_diff >= session_length - 90 && !open) {
      setOpen(true);
      setTimerFrequency(1);
      sessionStorage.setItem(session_time, JSON.stringify(current_time - session_length + 90));
    }
    if (time_diff < session_length - 90 && open) {
      setOpen(false);
      setTimerFrequency(60);
    }
    if (time_diff >= session_length) {
      setOpen(false);
      if (inProgress === InteractionStatus.None) {
        instance.logoutRedirect({ account: user });
      }
    }
    clearTimeout(clearTimer.current ?? undefined);
    clearTimer.current = setTimeout(() => {
      setTime(time + timerFrequency);
    }, timerFrequency * 1000);
  }, [time, instance, user, open, inProgress, timerFrequency]);

  return (
    <>
      <SessionTimeoutModal opened={open}>
        <>
          <SessionTimeoutModalHeader>
            <RenderTimer />
            <h2>Session Timeout</h2>
          </SessionTimeoutModalHeader>
          <ModalContent>
            <p>You are going to be timed out if no activity is detected</p>
          </ModalContent>
          <SessionTimeoutModalFooter>
            <SessionTimeoutButton
              buttonStyle="primary"
              onClick={() => {
                extendSession();
                setOpen(false);
                setTimerFrequency(60);
              }}
            >
              Stay Logged In
            </SessionTimeoutButton>
            <SessionTimeoutButton
              buttonStyle="tertiary"
              onClick={() => {
                instance.logoutRedirect({ account: user });
              }}
            >
              Log Out
            </SessionTimeoutButton>
          </SessionTimeoutModalFooter>
        </>
      </SessionTimeoutModal>
    </>
  );
};
