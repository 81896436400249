import React, { useState } from 'react';
import { Info } from 'react-feather';
import { InfotipWrapper, InfotipContent, InfoWrapper, Paragraph } from './elements';
import { palette } from 'components/styles';

interface InfotipProps {
  className?: string;
  children?: React.ReactChild;
  iconSize?: number;
  iconStrokeWidth?: number;
  iconColor?: string;
  minWidth: number;
}

interface InfotipInterface extends React.FunctionComponent<InfotipProps> {
  Paragraph?: any;
}

const Infotip: InfotipInterface = props => {
  const { className, children, iconSize = 24, iconStrokeWidth = 2, iconColor = palette.pureBlack, minWidth } = props;
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <InfotipWrapper className={className} onMouseEnter={showTip} onMouseLeave={hideTip}>
      <InfoWrapper>
        <Info size={iconSize} strokeWidth={iconStrokeWidth} color={iconColor} />
      </InfoWrapper>
      <InfotipContent active={active} minWidth={minWidth}>
        {children}
      </InfotipContent>
    </InfotipWrapper>
  );
};

Infotip.Paragraph = Paragraph;

export { Infotip };
