import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import ProspectSection from 'components/prospect-profile/prospect-section';

export const PrintMarginWrapper = styled.div`
  margin-top: -42px;
`;

export const YearGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;

  > div {
    margin-left: 16px;
    flex: 1;
  }
`;
export const BasicTileDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p:first-child {
    flex: 1;
    margin-top: 3px;
  }
  p:last-child {
    whitespace: no-wrap;
  }
`;

export const TileLink = styled.a`
  display: block;
  text-decoration: none;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray3};
  break-inside: avoid;

  &:first-of-type {
    padding-top: 0;
  }
`;

export const SectionTitle = styled(ProspectSection.Label)`
  margin-bottom: 0;
`;

export const TileSource = styled.p`
  color: ${palette.brandBlue};
  margin-top: 6px;
`;
