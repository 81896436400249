import styled from 'styled-components/macro';
import { Button } from 'components/ui-components';
import ProspectBulletList from 'components/prospect-bullet-list';
import { palette } from 'components/styles';

export const EngagementBulletList = styled(ProspectBulletList)`
  margin-top: 16px;
  flex: 1;

  > li {
    margin-bottom: 8px;
  }
`;

export const EngagementBlockList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 8px 0;
    padding: 8px;
    border-left: 2px solid ${palette.brandBlue};
  }

  > li:first-child {
    margin-top: 0;
  }
`;

export const TitleTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 8px;

  > p {
    font-weight: 600;
    margin-right: 8px;
    margin-bottom: 0;
  }
`;

export const EngagementTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SeeMoreButton = styled(Button)`
  margin-top: 32px;
`;

export const ModalContent = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
`;
