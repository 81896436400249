import { css, keyframes } from 'styled-components/macro';

const bounceRight = keyframes`
	0%, 15%, 50%, 80%, 100% {transform: translateX(0);}
	40% {transform: translateX(8px);}
	60% {transform: translateX(4px);}
`;

const bounceLeft = keyframes`
	0%, 15%, 50%, 80%, 100% {transform: translateX(0);}
	40% {transform: translateX(-8px);}
	60% {transform: translateX(-4px);}
`;

const bounceTop = keyframes`
	0%, 15%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-8px);}
	60% {transform: translateY(-4px);}
`;

const bounceBottom = keyframes`
	0%, 15%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(8px);}
	60% {transform: translateY(4px);}
`;

export const bounce = direction => {
  switch (direction.toUpperCase()) {
    case 'LEFT':
      return css`
        animation: ${bounceLeft} 1.5s linear infinite;
      `;
    case 'RIGHT':
      return css`
        animation: ${bounceRight} 1.5s linear infinite;
      `;
    case 'TOP':
      return css`
        animation: ${bounceTop} 1.5s linear infinite;
      `;
    case 'BOTTOM':
      return css`
        animation: ${bounceBottom} 1.5s linear infinite;
      `;
    default:
      return css`
        animation: ${bounceBottom} 1.5s linear infinite;
      `;
  }
};

export const motionFadeUp = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 }
};
