import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { BasicTile } from 'components/ui-components';

export const BasicTileDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p:first-child {
    flex: 1;
  }
  p:last-child {
    whitespace: no-wrap;
  }
`;

export const ArticleSnippet = styled(BasicTile.Description)`
  margin-top: 12px;
`;

export const TileLink = styled.a`
  display: block;
  text-decoration: none;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray3};
  break-inside: avoid;
`;

export const TileSource = styled.p`
  color: ${palette.brandBlue};
  margin-top: 6px;
`;
