import React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'components/styles';

interface TextAreaProps {
  id: string;
  className: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
}

const TextArea: React.FC<TextAreaProps> = props => <textarea className={props.className}>{props.children}</textarea>;

const StyledTextArea = styled(TextArea)`
  font-size: 18px;
  line-height: 28px;
  padding: 24px;
  background: ${colors.pureWhite};
  border: 1px solid ${colors.border};
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
`;

export { StyledTextArea };
