import Raise from './Raise';

class Org {
  id: string = '';
  raises: Raise[] = [] as Raise[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.raises = data.raises.map((r: any) => new Raise(r.id, r.name));
    }
  }
}

export default Org;
