import styled from 'styled-components/macro';

import { microBody } from 'components/mixins';
import { palette } from 'components/styles';

export const InfotipWrapper = styled.div`
  svg {
    display: block;
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

interface InfotipContentProps {
  active: boolean;
  minWidth: number;
}

export const InfotipContent = styled.div<InfotipContentProps>`
  position: absolute;
  border-radius: 10px;
  left: 50%;
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  opacity: ${props => (props.active ? 1 : 0)};
  transform: ${props => (props.active ? 'translate(-50%, calc(-100% - 18px))' : 'translate(-50%, calc(-100%))')};
  padding: 8px;
  color: ${palette.pureBlack};
  background: ${palette.gray4};
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  text-align: left;
  z-index: 50;
  top: 0;
  width: calc(100% + 8px);
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'calc(100% + 8px)')};
  transition: all 200ms ease-in;

  &:before {
    position: absolute;
    content: '';
    left: calc(50% - 8px);
    top: 100%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid ${palette.gray4};
    pointer-events: none;
  }
`;

export const Paragraph = styled.p`
  ${microBody(palette.pureBlack)}
  margin-bottom: 16px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
`;
