import styled from 'styled-components/macro';

import { microBody } from 'components/mixins';
import { palette } from 'components/styles';

export const TooltipWrapper = styled.div`
  svg {
    display: block;
    cursor: pointer;
  }
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

interface TooltipContentProps {
  active: boolean;
  minWidth: number;
  arrowLeftOffset: number;
}

export const TooltipContent = styled.div<TooltipContentProps>`
  position: absolute;
  border-radius: 10px;
  left: 50%;
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  opacity: ${props => (props.active ? 1 : 0)};
  transform: ${props => (props.active ? 'translate(-50%, calc(-100% - 27px))' : 'translate(-50%, calc(-100% - 20px))')};
  padding: 24px;
  color: ${palette.pureWhite};
  background: ${palette.brandDarkBlue};
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  text-align: left;
  z-index: 50;
  top: 0;
  width: calc(100% + 16px);
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'calc(100% + 16px)')};
  transition: all 200ms ease-in;

  &:before {
    position: absolute;
    content: '';
    left: ${props => `calc(${props.arrowLeftOffset}px + 9px)`};
    top: 100%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 24px solid ${palette.brandDarkBlue};
    pointer-events: none;
  }

  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

export const TooltipTitle = styled.p`
  ${microBody(palette.pureWhite)}
  font-weight: 600;
  margin-bottom: 4px;
  text-align: left;
`;

interface ParagraphProps {
  fontStyle: string;
}

export const TooltipParagraph = styled.p<ParagraphProps>`
  ${microBody(palette.pureWhite)}
  margin-bottom: 16px;
  text-align: left;
  font-style: ${props => props?.fontStyle ?? 'normal'};

  &:last-child {
    margin-bottom: 0;
  }
`;
