import React from 'react';
import { Headline } from './elements';

interface BasicTileHeadlineProps {
  className: string;
  children: React.ReactChild;
  textStyle: string;
  fontWeight: string;
}

const BasicTileHeadline: React.FC<BasicTileHeadlineProps> = ({ className, children, ...restProps }) => {
  return (
    <Headline className={className} textStyle={restProps.textStyle} fontWeight={restProps.fontWeight}>
      {children}
    </Headline>
  );
};

export default BasicTileHeadline;
