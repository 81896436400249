import React from 'react';
import ContactTile from 'components/prospect-profile/contact-tile';
import { SocialIconGroup, SocialIconWrapper, KnownContactGroup, ContactTitle, ContactSubTitle } from './elements';
import { palette } from 'components/styles';
import { SOCIAL_MAP } from 'constants/social-icons';
import ProspectSection from '../prospect-section';
import { BioContent } from '../prospect-bio/elements';
import { FadeIn } from 'components/ui-components';


const ProspectContactInfo = props => {
  const { id, prospect } = props;
  const alternativeContactData = prospect?.alt_contact_data;

  const renderAlternateContactList = () => {
    let alternateItems = [];

    if (alternativeContactData && alternativeContactData.length > 0) {
      alternateItems = alternativeContactData.map((contact, index) => {
        const { name, subName, label, phone, email } = contact;
        return (
          <ContactTile key={index}>
            <ContactTile.Text subText={subName} fontWeight={600}>
              {name}
            </ContactTile.Text>
            <ContactTile.Text fontStyle="italic">{label}</ContactTile.Text>
            {phone && <ContactTile.Text>{phone}</ContactTile.Text>}
            {email && <ContactTile.Text>{email}</ContactTile.Text>}
          </ContactTile>
        );
      });
    }

    return (
      <>
        <ContactSubTitle>{`Additional`}</ContactSubTitle>
        <KnownContactGroup>{alternateItems}</KnownContactGroup>
      </>
    );
  };

  const renderSocialIcons = () => {
    const prospectSocialHandles = prospect.getActiveProspectHandles();
    let socialIcons = [];

    if (prospectSocialHandles.length > 0) {
      socialIcons = prospectSocialHandles.map((handleObj, index) => {
        return (
          <SocialIconWrapper key={index} href={handleObj.value} target="_blank">
            {React.cloneElement(SOCIAL_MAP[handleObj.type], {
              fill: palette.pureWhite,
              stroke: palette.brandBlue,
              strokeWidth: 1,
              size: 20
            })}
          </SocialIconWrapper>
        );
      });
    }

    return (
      <>
        {prospectSocialHandles.length > 0 && (
          <div>
            <ContactSubTitle>{`Social Media`}</ContactSubTitle>
            <SocialIconGroup>{socialIcons}</SocialIconGroup>
          </div>
        )}
      </>
    );
  };

  const renderAddresses = () => {
    let renderAddress = [];

    if (prospect.addresses.length > 0) {
      renderAddress = prospect.addresses.map((add, index) => {
        const { label, street, city, state, zip } = add;
        const phone = prospect.getPhoneForResidence(label);
        return (
          <ContactTile key={index}>
            <ContactTile.Text fontWeight={600}>{`${label} Address`}</ContactTile.Text>
            <ContactTile.Text>{street}</ContactTile.Text>
            <ContactTile.Text>{`${city}, ${state} ${zip}`}</ContactTile.Text>
            {phone && <ContactTile.Text>{phone}</ContactTile.Text>}
          </ContactTile>
        );
      });
    }

    return (
      <>
        {renderAddress.length > 0 && <ContactSubTitle>{`Addresses`}</ContactSubTitle>}
        <KnownContactGroup>{renderAddress}</KnownContactGroup>
      </>
    );
  };

  const renderKnownContactInformation = () => {
    const renderContact = [];
    if (prospect.phone || prospect.email) {
      renderContact.push(
        <ContactTile key="primary-contact">
          <ContactTile.Text fontWeight={600}>{prospect.PreferredName}</ContactTile.Text>
          <ContactTile.Text fontStyle="italic">{prospect.contact_label}</ContactTile.Text>
          {prospect.phone && <ContactTile.Text>{prospect.phone}</ContactTile.Text>}
          {prospect.email && <ContactTile.Text>{prospect.email}</ContactTile.Text>}
        </ContactTile>
      );
    }

    if (prospect.spouseMobilePhone) {
      renderContact.push(
        <ContactTile key="spouse-contact">
          <ContactTile.Text subText="Spouse" fontWeight={600}>
            {`${prospect?.spouseFirstName} ${prospect?.spouseLastName}`}
          </ContactTile.Text>
          <ContactTile.Text fontStyle="italic">{'Personal'}</ContactTile.Text>
          <ContactTile.Text>{prospect.spouseMobilePhone}</ContactTile.Text>
        </ContactTile>
      );
    }

    return (
      <>
        <ContactTitle>{`Contact Information`}</ContactTitle>
        <ContactSubTitle>{`Primary`}</ContactSubTitle>
        <KnownContactGroup>{renderContact}</KnownContactGroup>
      </>
    );
  };

  return (
    <>
      <ProspectSection id={id}>
        <FadeIn>
          <BioContent>
            {renderKnownContactInformation()}
            {renderAlternateContactList()}
            {renderAddresses()}
            {renderSocialIcons()}
          </BioContent>
        </FadeIn>
      </ProspectSection>
    </>
  );
};

export default ProspectContactInfo;
