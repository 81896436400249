import styled from 'styled-components/macro';
import { headline } from 'components/mixins';
import { palette } from 'components/styles';
import { Container } from 'components/ui-components';

export const LoginErrorContainer = styled(Container)`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  height: 100vh;
`;

export const DiagnosticContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const DiagnosticHeaderWrapper = styled.div`
  padding: 0 10px;
`;

export const Headline = styled.h1`
  ${headline(palette.pureBlack)}
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const RetryLink = styled.a`
  && {
    font-weight: 600;
    color: ${palette.brandBlue};
    cursor: pointer;
  }
`;

export const ErrorMessageContainer = styled.div`
  margin-bottom: 10px;
`;

export const AppLinkContainer = styled.div`
  margin-top: 20px;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
`;

export const HeaderLabelWrapper = styled.div`
  margin-left: 8px;
  height: 36px;
  display: inline-flex;
  vertical-align: middle;
`;

export const ContentWrapper = styled.div`
  margin: 10px 0 0 30px;
`;
