import { palette } from 'components/styles';
import React from 'react';
import { UserX } from 'react-feather';
import { Wrapper, Message } from './elements';

type EmptyStateProps = {
  connectionType: string;
  prospect: any;
};

const fade = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.4 }
};

const EmptyState = (props: EmptyStateProps): JSX.Element => {
  const { connectionType, prospect } = props;

  return (
    <Wrapper>
      <Message key={connectionType} {...fade}>
        <UserX size={32} color={palette.brandBlue} />
        <p>{`No known ${connectionType} connections to ${prospect.PreferredFirstName}`}</p>
      </Message>
    </Wrapper>
  );
};

export default EmptyState;
