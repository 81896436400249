import React from 'react';
import Classification from 'components/classification';
import { palette } from 'components/styles';
import {
  Avatar,
  AvatarBlock,
  ConnectionWrapper,
  LayoutLeftWrapper,
  LayoutRightWrapper,
  ProspectDetails,
  ProspectDetailsGroup,
  ProspectHeaderContainer,
  ProspectHeaderWrapper
} from './elements';

const ProspectProfile = props => {
  const { prospect } = props;

  const getFormattedJobTitleEmployer = () => {
    let formattedJobDesc = ``;

    if (prospect.employer && prospect.occupation) {
      formattedJobDesc = `${prospect.occupation}, ${prospect.employer}`;
    } else {
      formattedJobDesc = `${prospect.occupation}${prospect.employer}`;
    }

    return formattedJobDesc;
  };

  return (
    <ProspectHeaderWrapper>
      <ProspectHeaderContainer>
        <LayoutLeftWrapper>
          <AvatarBlock>
            <Avatar background={palette.brandDarkBlue}>
              {!prospect?.photo && <p>{prospect.getDonorInitials()}</p>}
              {prospect?.photo && <img src={prospect.photo} alt="prospect-avatar" />}
            </Avatar>
          </AvatarBlock>
        </LayoutLeftWrapper>
        <LayoutRightWrapper>
          <ConnectionWrapper>
            <Classification>
              <Classification.Icon classificationId={prospect.donorClassification} />
              <Classification.Title classificationId={prospect.donorClassification} />
            </Classification>
          </ConnectionWrapper>
          <h1>{`${prospect.FullName}`}</h1>
          <ProspectDetails>
            <ProspectDetailsGroup>
              <div>
                <p>{getFormattedJobTitleEmployer()}</p>
              </div>
              <div>
                <p>{`${prospect.city ? prospect.city : 'Unavailable'}, ${prospect.state}`}</p>
              </div>
            </ProspectDetailsGroup>
          </ProspectDetails>
        </LayoutRightWrapper>
      </ProspectHeaderContainer>
    </ProspectHeaderWrapper>
  );
};

export default ProspectProfile;
