import { css } from 'styled-components/macro';
import { colors } from 'components/styles';

export const primaryLink = css`
  color: ${colors.brandBlueDark};
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-decoration: none;

  &:visited {
    color: ${colors.brandBlueDark};
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-decoration: none;
  }
`;
