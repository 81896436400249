import { useCallback, useEffect, useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { BRAND_ASSETS } from 'constants/brand-assets';

type Brand = {
  logo: React.ReactElement<HTMLImageElement>;
  logoAlt: React.ReactElement<HTMLImageElement>;
  name: string;
  capitalized: string;
};

const useMsalUserManager = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [brand, setBrand] = useState({} as Brand);
  const user = useAccount(accounts[0] || {});
  const tenantId = 33;

  useEffect(() => {
    if (user && !brand.name) {
      const { username } = user;
      const id = username.includes('@liftus.org') ? 'LIFT_US' : 'ATTICUS';
      setBrand({ ...BRAND_ASSETS[id] });
    } else if (!user) {
      setBrand({ ...BRAND_ASSETS['ATTICUS'] });
    }
  }, [user, brand.name]);

  const logoutUser = useCallback(() => {
    instance.logoutRedirect({ account: user });
  }, [user, instance]);

  return { user, tenantId, logoutUser, instance, inProgress, brand };
};

export { useMsalUserManager };
