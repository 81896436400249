import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';

export const MultiSelectWrapper = styled.div`
  position: relative;
  max-width: 256px;
  flex: 1;
  min-width: 0;
`;

interface MultiSelectHeaderButtonProps {
  opened: boolean;
}

export const MultiSelectHeaderButton = styled('button')<MultiSelectHeaderButtonProps>`
  ${xsBody(palette.pureBlack)}

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${palette.pureWhite};
  border: 1px solid ${palette.gray3};
  border-bottom: ${props => (props.opened ? 'none' : `1px solid ${palette.gray3}`)};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${props => (props.opened ? 0 : '8px')};
  border-bottom-right-radius: ${props => (props.opened ? 0 : '8px')};
  width: 100%;
  transition: border-radius 0.3s ease-in;

  p {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 6px;
  }
`;

interface MultiSelectListContainerProps {
  position: string;
}

export const MultiSelectListContainer = styled('div')<MultiSelectListContainerProps>`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  right: ${props => (props.position === 'RIGHT' ? 0 : 'none')};
  left: ${props => (props.position === 'LEFT' ? 0 : 'none')};
  min-width: 256px;
  width: 100%;

  > div {
    position: relative;
    overflow: hidden;
    padding: 12px 16px;
    margin: 0;
    border: 1px solid ${palette.gray3};
    border-top: none;
    background-color: ${palette.pureWhite};
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const MultiSelectList = styled('ul')`
  border-top: 1px solid ${palette.gray3};
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 32px;
  max-height: 220px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

interface ListItemProps {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 6px 0;
  margin: 0;
  background-color: ${props => (props.selected ? palette.gray3 : palette.pureWhite)};
  cursor: pointer;

  &:hover {
    background-color: ${palette.gray3};
  }

  p,
  p:visited {
    color: ${xsBody(palette.pureBlack)};
  }
`;

export const ClearSelectionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${palette.pureWhite};
  width: 100%;
  padding: 0 16px;
`;

export const ClearButton = styled.button`
  ${xsBody(palette.pureBlack)}

  width: 100%;
  text-align: left;
  padding: 10px 0;
  border: none; /* hide default border */
  border-top: 1px solid ${palette.gray3};
  background-color: transparent;
  display: block;
  text-decoration: none;
  cursor: pointer;
`;

export const CheckboxListItem = styled('li')`
  padding: 6px 3px;
  margin: 0;
  cursor: pointer;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  p {
    margin-left: 12px;
  }
`;
