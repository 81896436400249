import styled, { keyframes } from 'styled-components/macro';

const spin = keyframes`
to {transform: rotate(360deg);}
`;

interface Spinner {
  size: number;
  color: string;
}

export const Spinner = styled.div<Spinner>`
  display: inline-block;
  position: relative;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};

  &:before {
    content: '';
    top: 0;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    border-radius: 50%;
    border-top: 3px solid ${props => props.color};
    border-right: 3px solid transparent;
    animation: ${spin} 0.6s linear infinite;
  }
`;
