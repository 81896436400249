import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { lgBody } from 'components/mixins';

type TabProps = {
  active: boolean;
};

export const TabItem = styled.li<TabProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-left: none;
  cursor: pointer;
  position: relative;
`;

export const TabActiveIndicator = styled.div<TabProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  background-color: ${palette.gray3};
  bottom: -2px;
  left: 0;
  width: 100%;

  &:before {
    content: '';
    width:${props => (props.active ? '100%' : 0)};
    opacity: ${props => (props.active ? 1 : 0)};
    height: 3px;
    background-color: ${palette.brandBlue};
    transition: all 350ms ease-in-out;
`;

export const TabButton = styled.button<TabProps>`
  ${lgBody(palette.pureWhite)}
  font-weight: ${props => (props.active ? 600 : 400)};
  display: flex;
  height: 100%;
  width: 100%;
  padding: 8px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
