import styled from 'styled-components/macro';
import { bodyShort } from 'components/mixins';
import { palette } from 'components/styles';

export const HighlightsTitle = styled.h3`
  ${bodyShort(palette.pureBlack)}
  font-weight: 600;
  display: block;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const HighlightsWrapper = styled.div`
  padding: 30px 0 0 0;
`;

export const AlignmentContent = styled.div`
  display: block;
  margin-bottom: 32px;
  padding: 25px;
  font-size: 18px;
`;
