import React, { useState, useEffect } from 'react';
import { AccountInfo } from '@azure/msal-browser';

import {
  FixedNav,
  LogoLink,
  NavContainer,
  LogoWrapper,
  OrgBrandWrapper,
  NavWrapper,
  MenuUL,
  LogoutItem
} from './elements';
import { useMsalUserManager } from 'hooks/useManageMsalUser';
import { getWithoutAuthorization } from 'services/utilities';
import { OrgBrand } from 'types/shared';

type Token = {
  city?: string;
};

const MainNav: React.FC = () => {
  const { user, brand, instance } = useMsalUserManager();
  const { idTokenClaims } = user ?? ({} as AccountInfo);
  const { city: orgId } = ({ ...idTokenClaims } as Token) ?? ({} as Token);
  const [orgBrand, setOrgBrand] = useState({} as OrgBrand);
  const [displayBrandLogo, setDisplayBrandLogo] = useState(true);
  const orgLogoSrc = `https://atticus.azureedge.net/org-${orgId}/logo.svg`;
  const orgBrandJson = `https://atticus.azureedge.net/org-${orgId}/branding.json`;

  useEffect(() => {
    const getOrgBranding = async () => {
      try {
        const results = await getWithoutAuthorization(orgBrandJson);

        if (results.data) {
          setOrgBrand({ ...results.data });
        }
      } catch (error) {
        console.log(error);
      }
    };

    getOrgBranding();
  }, [orgBrandJson]);

  const renderNav = () => {
    return (
      <>
        <FixedNav role="navigation" aria-label="main navigation">
          <NavContainer wide={true}>
            <div>
              <LogoLink href="/">
                <LogoWrapper>{brand && brand.logo}</LogoWrapper>
                <OrgBrandWrapper>
                  {displayBrandLogo && (
                    <div>
                      <img onError={() => setDisplayBrandLogo(false)} src={orgLogoSrc} alt="atticus-logo" />
                    </div>
                  )}
                  {!displayBrandLogo && <h2>{orgBrand.name}</h2>}
                </OrgBrandWrapper>
              </LogoLink>
              <NavWrapper>
                <MenuUL id="menubar1" role="menubar" aria-label="Main Navigation">
                  <LogoutItem
                    onExternalNavClick={() => instance.logoutRedirect({ account: user })}
                    content="Logout"
                    type="LINK"
                    id="logout"
                  />
                </MenuUL>
              </NavWrapper>
            </div>
          </NavContainer>
        </FixedNav>
      </>
    );
  };

  return renderNav();
};

export { MainNav };
