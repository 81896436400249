import React from 'react';
import { palette } from 'components/styles';
import { Spinner } from './elements';

interface Props {
  className: string;
  size?: number;
  color?: string;
}

const Loader: React.FC<Props> = props => {
  const { className, size = 150, color = palette.navyBackground } = props;

  return <Spinner className={className} size={size} color={color} />;
};

export { Loader };
