import React, { useCallback, useState } from 'react';
import Raise from 'models/Org/Raise';
import Org from 'models/Org/Org';

interface IOrgContext {
  organizationRaises: Array<Raise>;
  activeRaise: Raise;
  toggleActiveRaiseById: (id: string) => void;
  initializeOrg: (org: any) => void;
  org: Org;
}

interface OrgContextProviderProps {
  children: React.ReactNode;
}

export const OrgContext = React.createContext<IOrgContext>({
  activeRaise: new Raise('id', 'value'),
  organizationRaises: [],
  toggleActiveRaiseById: () => undefined,
  initializeOrg: () => undefined,
  org: new Org()
});

const RaiseContextProvider = (props: OrgContextProviderProps): JSX.Element => {
  const [organizationRaises, setOrganizationRaises] = useState([] as Array<Raise>);
  const [activeRaise, setActiveRaise] = useState({} as Raise);
  const [org, setOrg] = useState(new Org());
  const { children } = props;
  const orgKey: string = org.id + '-org';

  const initializeOrg = useCallback(
    (orgData: any) => {
      const org = new Org(orgData);
      setOrg(org);
      setOrganizationRaises(org.raises);
      localStorage.setItem(orgKey, JSON.stringify(org));
    },
    [orgKey]
  );

  const toggleActiveRaiseById = (id: string) => {
    if (!organizationRaises || organizationRaises.length === 0) {
      const savedOrgJson: string | null = localStorage.getItem(orgKey);
      if (savedOrgJson) {
        const savedOrg: Org = JSON.parse(savedOrgJson) as Org;
        setOrg(savedOrg);
        setOrganizationRaises(savedOrg.raises);
      }
    }
    if (organizationRaises?.length > 0) {
      const foundRaise = organizationRaises.find((raise: Raise) => raise.raiseId === id);
      if (foundRaise) {
        setActiveRaise(foundRaise);
      }
    }
  };

  const defaultContext: IOrgContext = {
    organizationRaises,
    activeRaise,
    toggleActiveRaiseById,
    initializeOrg,
    org
  };

  return <OrgContext.Provider value={defaultContext}>{children}</OrgContext.Provider>;
};

export default RaiseContextProvider;
