import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { headline, xsBody, body, lgBody, bounce } from 'components/mixins';

interface StyledTileProps {
  bgColor?: string;
}

export const StyledTile = styled.div<StyledTileProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  min-height: 372px;
  background-color: ${props => (props.bgColor ? props.bgColor : palette.pureWhite)};
  border-radius: 4px;
  border: 1px solid ${palette.background};
`;

export const TileContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface TitleProps {
  fontWeight: string;
}

export const Title = styled.h2<TitleProps>`
  ${lgBody(palette.pureBlack)}
  font-weight: 600;
  margin-bottom: 8px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  color: ${props => (props.color ? props.color : palette.pureBlack)};
`;

interface SubTitleProps {
  color?: string;
  textStyle?: string;
}

export const SubTitle = styled.p<SubTitleProps>`
  ${body(palette.pureBlack)}
  line-height: 24px;
  color: ${props => (props.color ? props.color : palette.pureBlack)};
  font-style: ${props => (props.textStyle ? props.textStyle : 'normal')};
`;

interface DescriptionProps {
  textStyle: string;
  fontWeight: string;
  textAlign: string;
}

export const Description = styled.p<DescriptionProps>`
  ${xsBody(palette.pureBlack)}
  font-style: ${props => (props.textStyle ? props.textStyle : 'normal')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

interface HeadlineProps {
  textStyle: string;
  fontWeight: string;
}

export const Headline = styled.p<HeadlineProps>`
  ${headline(palette.pureBlack)}
  font-family: 'Barlow';
  font-style: ${props => (props.textStyle ? props.textStyle : 'normal')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
`;

export const Img = styled.img`
  margin-bottom: 20px;
  width: 100%;
  max-height: 208px;
`;

export const Video = styled.video`
  margin-bottom: 20px;
  width: 100%;
  max-height: 208px;
`;

export const TileLink = styled(Link)`
  color: ${props => (props.color ? props.color : palette.pureBlack)};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  strong {
    color: ${props => (props.color ? props.color : palette.pureBlack)};
  }

  svg {
    margin-left: 8px;
  }

  &:hover {
    svg {
      ${bounce('left')}
    }
  }
`;
