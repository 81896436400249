import styled from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';

export const Button = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid ${palette.background};
  background-color: ${palette.navyBackground};
  color: ${palette.pureWhite};
  padding: 10px;
  border-radius: 4px;
  transition: opacity 400ms ease-in-out;
  display: block;

  @media only screen and (min-width: ${breakpoints['tablet-sm']}) {
    display: none;
  }

  &.hidden {
    opacity: 0;
  }
`;
