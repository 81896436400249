import styled, { css } from 'styled-components/macro';
import { xsBody, jumboHeading, bodyShort } from 'components/mixins';
import { palette, breakpoints } from 'components/styles';
import WatchlistToggle from 'components/watchlist-toggle';
import { FadeIn, Container, Tooltip, Dropdown } from 'components/ui-components';

const getHideContentOverrides = hideMediaList => {
  let hideContentMediaQueries = '';

  if (hideMediaList) {
    for (let i = 0; i < hideMediaList.length; i += 1) {
      const breakpointStringMin = hideMediaList[i].min;
      const breakpointStringMax = hideMediaList[i].max;

      const MinBreakpoint = breakpointStringMin ? breakpoints[breakpointStringMin] : '';
      const MaxBreakpoint = breakpointStringMax ? breakpoints[breakpointStringMax] : '';

      if (MinBreakpoint) {
        hideContentMediaQueries += `
        @media only screen and (min-width: ${MinBreakpoint}) { 
           display: none; 
          }
        `;
      }

      if (MaxBreakpoint) {
        hideContentMediaQueries += `
        @media only screen and (max-width: ${MaxBreakpoint}) { 
           display: none;
          }
        `;
      }
    }
  }

  return css`
    ${hideContentMediaQueries}
  `;
};

export const BackButton = styled.button`
  color: ${palette.pureWhite};
  background-color: transparent;
  border: 0;
  left: 0;
  position: absolute;
  top: -35px;
  cursor: pointer;

  span {
    vertical-align: top;
    font-size: 16px;
  }
`;

export const HeaderToggle = styled(WatchlistToggle)`
  width: 196px;
  margin-right: 16px;
  justify-content: flex-start;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    margin-right: 32px;
  }
`;

export const ProspectHeaderWrapper = styled.section`
  padding: 80px 0 24px 0;
  background-color: ${palette.navyBackground};

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    padding: ${props => (props.showRaiseSelector ? '50px' : '100px')} 0 32px 0;
  }
`;

export const ProspectHeaderContainer = styled(Container)`
  display: grid;
  flex-direction: row;
  column-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'profilePicture'
    'profileData';

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    grid-template-areas: 'profilePicture profileData';
    grid-template-columns: 255px 1fr;
    column-gap: 32px;
  }

  @media only screen and (min-width: ${breakpoints['container']}) {
    grid-template-areas: 'profilePicture profileData';
    grid-template-columns: 334px 1fr;
    column-gap: 56px;
  }
`;

export const LayoutLeftWrapper = styled.div`
  grid-area: 'profilePicture';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarBlock = styled.div`
  position: relative;
  height: 240px;
  width: 240px;
  background-color: ${palette.pureWhite};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    position: absolute;
    bottom: -24px;
    height: 255px;
    width: 255px;
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${breakpoints['container']}) {
    position: absolute;
    bottom: -76px;
    height: 325px;
    width: 334px;
    margin-bottom: 0;
  }
`;

export const AvatarBase = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${props => props.background};

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    width: 186px;
    height: 186px;
  }

  @media only screen and (min-width: ${breakpoints['container']}) {
    width: 240px;
    height: 240px;
  }

  img {
    width: 100%;
    background-color: ${palette.gray3};
  }
`;

export const AvatarFadeIn = styled(FadeIn)`
  ${AvatarBase}
  p {
    ${jumboHeading(palette.pureWhite)}
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: ${palette.gray3};
  }
`;

export const AvatarSkeleton = styled.div`
  ${AvatarBase}
  background-color: ${palette.gray3};

  img {
    color: transparent;
    width: 100%;
    height: 100%;
    background-color: ${palette.gray3};
  }
`;

export const LayoutRightWrapper = styled.div`
  grid-area: 'profileData';
  color: ${palette.pureWhite};
  text-align: center;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    text-align: left;
  }
`;

export const ProspectNameWrapper = styled.div`
  h1 {
    font-family: 'Effra';
    font-size: 32px;
    font-weight: 300;
    line-height: 50px;

    @media only screen and (min-width: ${breakpoints['laptop']}) {
      font-size: 40px;
      line-height: 44px;
      margin: 4px 0 4px 0;
      margin-right: 12px;
    }
  }

  h2 {
    ${bodyShort(palette.pureWhite)};
  }
`;

export const ProspectDetails = styled.div`
  ${xsBody(palette.pureWhite)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  ${
    '' /* @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    flex-direction: row;
  } */
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    justify-content: flex-start;
    margin-top: 10px;
    align-items: stretch;
    flex-direction: row;
  }
`;

export const PrintProspectWrapper = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 114px;
    margin-left: 16px;
  }
`;

export const ProspectToggleDesktopWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${breakpoints['laptop']}) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
`;

export const ProspectToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  > button {
    margin: 0 8px;
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    display: none;
  }
`;

export const Separator = styled.span`
  width: 100px;
  border-bottom: 1px solid ${palette.gray2};
  margin: 20px 0;
  display: block;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    display: none;
  }
`;

export const ProspectDetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-left: none;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  min-width: ${props => `${props.minWidth}px` ?? '195px'};

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    padding: 8px 32px 8px 32px;
    border-left: 1px solid ${palette.gray2};
    align-items: flex-start;

    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }
  }

  ${props => getHideContentOverrides(props.hideContentBreakpoints)}
`;

export const ProspectDetailsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    justify-content: flex-start;
  }

  span {
    display: none;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      display: inline-block;
      margin-left: 4px;
    }
  }

  strong {
    margin-left: 4px;
  }

  svg {
    margin-right: 8px;
  }

  ${props => getHideContentOverrides(props.hideContentBreakpoints)}
`;

export const ProspectDetailsLabel = styled.p`
  margin-right: 5px;
  margin-bottom: 8px;
`;

export const SkeletonLoaders = styled.div`
  span {
    display: block;
    margin-bottom: 16px;
  }
`;

export const TitleTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const DesktopTip = styled(Tooltip)`
  display: none;

  svg {
    margin-bottom: 8px;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: inline-block;
  }
`;

export const MobileTip = styled(Tooltip)`
  display: inline-block;

  svg {
    margin-left: 4px;
  }

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: none;
  }
`;

export const RaiseDropdown = styled(Dropdown)`
  margin-top: 10px;
  display: flex;
  justify-content: center;

  button {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    color: ${palette.pureWhite};
    max-width: fit-content;
    padding: 10px 0;

    p {
      margin-left: 8px;
    }
  }

  ul {
    color: ${palette.pureWhite};
    background-color: ${palette.navyBackground};
    border: 0;

    li {
      color: ${palette.pureWhite};
      background-color: ${palette.navyBackground};
      border: 0;

      p {
        color: ${palette.pureWhite};
      }
    }

    &:hover {
      color: ${palette.pureWhite};
      background-color: ${palette.navyBackground};
    }
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    justify-content: flex-start;
    margin-top: 20px;
    align-items: stretch;
    flex-direction: row;
  }
`;
