import React, { useContext } from 'react';
import ProspectSection from '../prospect-section';
import { FadeIn } from 'components/ui-components';
import ProspectBulletList from 'components/prospect-bullet-list';
import { AlignmentContent, HighlightsContent, HighlightsTitle } from './elements';
import { OrgContext } from 'context/org-context';

const ProspectHighlights = props => {
  const { id, prospect } = props;
  const { activeRaise } = useContext(OrgContext);
  const activeRaiseData = prospect.getDonorRaiseDataByRaiseId(activeRaise.raiseId);

  const renderHighlightsSection = () => {
    let renderHighlights;
    if (activeRaiseData.highlights && activeRaiseData.highlights.length > 0) {
      renderHighlights = (
        <>
          <ProspectBulletList items={activeRaiseData.highlights} prospect={prospect} />
        </>
      );
    }

    return renderHighlights;
  };

  return (
    <>
      <ProspectSection id={id}>
      <HighlightsTitle>{`Atticus Insights`}</HighlightsTitle>
        <FadeIn>
          <AlignmentContent>{activeRaiseData.alignmentReason}</AlignmentContent>
          <HighlightsContent>{renderHighlightsSection()}</HighlightsContent>
        </FadeIn>
      </ProspectSection>
    </>
  );
};

export default ProspectHighlights;
