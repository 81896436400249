import React from 'react';

const SvgMinus = ({ fill = '#007CFF', stroke = '#FFFFFF' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={fill}
      />
      <path d="M5 10L15 10.0001" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export { SvgMinus };
