import React, { useLayoutEffect, useEffect, useContext, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';

import { useMsalUserManager, useTrackPageView } from 'hooks';
import { NotificationContext } from 'context/notification-context';
import { OrgContext } from 'context/org-context';
import { getProspectProfile } from 'services';
import ProspectProfile from 'models/ProspectProfile/ProspectProfile';
import { useScrollPosition } from 'hooks';
import { ScrollToTop } from 'components/ui-components';
import { PageWrapper, LayoutContainer, SidebarWrapper, MainContentWrapper, LoaderWrapper } from './elements';
import ProspectHeader from 'components/prospect-profile/prospect-header';
import ProspectBio from 'components/prospect-profile/prospect-bio';
import ProspectNews from 'components/prospect-profile/prospect-news';
import ProspectInsights from 'components/prospect-profile/prospect-insights';
import Connections from 'components/prospect-connections';

import SidebarNav from 'components/prospect-profile/sidebar-nav';
import { Loader } from 'components/ui-components/loader';
import { Tooltip } from 'react-tooltip';
import { palette } from 'components/styles';
import ProspectContactInfo from 'components/prospect-profile/prospect-contact-info';
import ProspectHighlights from 'components/prospect-profile/prospect-highlights';
import Org from 'models/Org/Org';

const links = [
  { id: 'INSIGHT' },
  { id: 'BIO' },
  { id: 'CONTACT' },
  { id: 'CONNECTIONS' },
  { id: 'HIGHLIGHTS' },
  { id: 'NEWS' }
];

export const ProspectDetailPage = () => {
  const { prospectId } = useParams();
  const { user, instance, inProgress } = useMsalUserManager();
  const [prospectBasicInfo, setProspectBasicInfo] = useState({ loading: true });
  const [linkState, setLinkState] = useState({});
  const [checkScrollPosition, setCheckScrollPosition] = useState(true);
  const [raiseInitialized, setRaiseInitialized] = useState(false);
  const [activeLink, setActiveLink] = useState('INSIGHT');
  const { addNewNotification } = useContext(NotificationContext);
  const { initializeOrg, toggleActiveRaiseById, activeRaise } = useContext(OrgContext);
  const isPreview = window.location.pathname.toLowerCase() === '/preview';
  useTrackPageView({ name: `DONOR_PROFILE_PAGE` });

  const renderPreviewDonor = useCallback(
    e => {
      if (isPreview) {
        const previewData = JSON.parse(e.data);
        const { assessment, org_raises } = previewData;
        const orgRaises = [];
        for (let i = 0; i < org_raises.length; i++) {
          orgRaises.push({ id: org_raises[i].org_raise_id, name: org_raises[i].raise_name });
        }
        const prospect = new ProspectProfile(previewData);
        assessment?.org_connections && prospect.initConnections(assessment?.org_connections);
        assessment?.mutual_connections && prospect.initMutualConnections(assessment?.mutual_connections);
        prospect.initAlternateContacts();
        prospect.initAddresses();
        const org = new Org();
        org.id = 'Preview';
        org.raises = orgRaises;
        initializeOrg(org);
        setProspectBasicInfo({ loading: false, prospect: prospect });
      }
    },
    [initializeOrg, isPreview]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    window.addEventListener('message', event => renderPreviewDonor(event), false);

    return () => {
      window.removeEventListener('message', event => renderPreviewDonor(event));
    };
  }, [renderPreviewDonor]);

  useEffect(() => {
    if (!raiseInitialized) {
      const urlParams = new URLSearchParams(location.search);
      const raiseId = urlParams.get('raiseId');
      if (raiseId) {
        if (raiseId !== activeRaise.raiseId) {
          toggleActiveRaiseById(raiseId);
        }
        setRaiseInitialized(true);
      }
    }
  }, [activeRaise, toggleActiveRaiseById, raiseInitialized]);

  useEffect(() => {
    if (!prospectBasicInfo.loading) {
      const prospect = prospectBasicInfo.prospect;
      if (!activeRaise?.raiseId && prospect.raises && prospect.raises.length > 0) {
        toggleActiveRaiseById(prospect.raises[0].raiseId);
      }
    }
  }, [activeRaise, toggleActiveRaiseById, prospectBasicInfo]);

  useEffect(() => {
    const abortController = new AbortController();
    const initializeProspectData = async signal => {
      try {
        const prospectInfo = await getProspectProfile(user, instance, prospectId);
        const { data } = prospectInfo;
        const { assessment } = data;
        const prospect = new ProspectProfile(data);
        assessment?.org_connections && prospect.initConnections(assessment?.org_connections);
        assessment?.mutual_connections && prospect.initMutualConnections(assessment?.mutual_connections);
        prospect.initAlternateContacts();
        prospect.initAddresses();

        if (!signal.aborted) {
          setProspectBasicInfo({ loading: false, prospect: prospect });
        }
      } catch (error) {
        const my_error = error;
        addNewNotification({
          type: 'error',
          title: my_error.title,
          description: my_error.detail
        });
        if (!signal.aborted) {
          setProspectBasicInfo({ loading: false, prospect: null });
        }
      }
    };

    if (prospectId && inProgress === 'none') {
      setTimeout(() => {
        initializeProspectData(abortController.signal);
      }, 300);
    } else {
      if (window.opener) {
        window.opener.postMessage('Preview Ready', process.env.REACT_APP_DATA_SOURCE);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [prospectId, addNewNotification, inProgress, instance, user]);

  /**
   * Lifecycle method which fires after the DOM lays out. Implementation seeks to set the
   * positional dimensions for each section of the page, and assigns it to state so that it
   * can be accessed later for scrolling to locations, or styling active links
   *
   * @function useLayoutEffect
   */
  useLayoutEffect(() => {
    let linkState = {};
    if (!prospectBasicInfo.loading) {
      if (links.length > 1) {
        linkState = Object.assign(
          linkState,
          ...links.map((link, index) => {
            let linkObject = null;

            if (link.id) {
              const elementDimensions = document.getElementById(link.id).getBoundingClientRect();
              const elementTop = elementDimensions.top + window.scrollY;
              linkObject = {
                [link.id]: {
                  id: link.id,
                  topOfSection: elementTop,
                  bottomOfSection: elementTop + elementDimensions.height
                }
              };
            }

            return linkObject;
          })
        );
      }

      setLinkState(linkState);
    }
  }, [prospectBasicInfo.loading]);

  useScrollPosition(
    // eslint-disable-next-line no-unused-vars
    ({ prevPos, currPos }) => {
      const scrolledSection = find(linkState, link => {
        const currPositionTranslated = currPos.y * -1;

        return currPositionTranslated >= link.topOfSection && currPositionTranslated <= link.bottomOfSection;
      });
      if (
        checkScrollPosition &&
        window.scrollY > 0 &&
        window.scrollY + window.innerHeight >= document.body.scrollHeight
      ) {
        setActiveLink('NEWS');
      } else if (checkScrollPosition && scrolledSection) {
        setActiveLink(scrolledSection.id);
      }
    }
  );

  /**
   * Click handler method which scrolls to the position of the clicked section item
   *
   * @function scrollToSection
   * @param {object} e - click event
   * @param {string} id - id of the clicked subnav item
   */
  const scrollToSection = (e, id) => {
    if (id) {
      e.preventDefault();
      window.scroll({
        top: linkState[id].topOfSection + 25,
        behavior: 'smooth'
      });
    }
  };

  const handleNavItemClick = (event, clickedSectionId) => {
    setCheckScrollPosition(false);
    setActiveLink(clickedSectionId);
    scrollToSection(event, clickedSectionId);
    setTimeout(() => setCheckScrollPosition(true), 500);
  };

  return (
    <PageWrapper>
      {prospectBasicInfo.loading ? (
        <ProspectHeader.Loading />
      ) : (
        <ProspectHeader prospect={prospectBasicInfo.prospect} />
      )}
      {prospectBasicInfo.loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!prospectBasicInfo.loading && (
        <LayoutContainer>
          <SidebarWrapper>
            <SidebarNav>
              <SidebarNav.Item active={activeLink === 'INSIGHT'}>
                <SidebarNav.ItemButton
                  active={activeLink === 'INSIGHT'}
                  onClick={e => handleNavItemClick(e, 'INSIGHT')}
                >
                  Atticus Insights
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
              <SidebarNav.Item active={activeLink === 'BIO'}>
                <SidebarNav.ItemButton active={activeLink === 'BIO'} onClick={e => handleNavItemClick(e, 'BIO')}>
                  Biography
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
              <SidebarNav.Item active={activeLink === 'CONTACT'}>
                <SidebarNav.ItemButton
                  active={activeLink === 'CONTACT'}
                  onClick={e => handleNavItemClick(e, 'CONTACT')}
                >
                  Contact Information
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
              <SidebarNav.Item active={activeLink === 'CONNECTIONS'}>
                <SidebarNav.ItemButton
                  active={activeLink === 'CONNECTIONS'}
                  onClick={e => handleNavItemClick(e, 'CONNECTIONS')}
                >
                  Connections
                  <sup>
                    <a
                      data-tooltip-id="beta-tooltip"
                      data-tooltip-content="This feature is experimental and still under development. Client usage and feedback is needed to understand how much value this feature adds to the platform. Please give us feedback on how well it works and how it might be improved!"
                      data-tooltip-place="bottom"
                    >
                      <SidebarNav.Badge>BETA</SidebarNav.Badge>
                    </a>
                  </sup>
                  <Tooltip
                    id="beta-tooltip"
                    style={{
                      backgroundColor: palette.brandDarkBlue,
                      width: '90%',
                      fontSize: '14px',
                      lineHeight: '1',
                      textAlign: 'left',
                      color: palette.pureWhite,
                      padding: '24px',
                      fontFamily: 'sans-serif',
                      transition: 'all 200ms ease-in',
                      borderRadius: '10px',
                      opacity: '1'
                    }}
                  />
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
              <SidebarNav.Item active={activeLink === 'HIGHLIGHTS'}>
                <SidebarNav.ItemButton
                  active={activeLink === 'HIGHLIGHTS'}
                  onClick={e => handleNavItemClick(e, 'HIGHLIGHTS')}
                >
                  Highlighted Giving
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
              <SidebarNav.Item active={activeLink === 'NEWS'}>
                <SidebarNav.ItemButton active={activeLink === 'NEWS'} onClick={e => handleNavItemClick(e, 'NEWS')}>
                  News
                </SidebarNav.ItemButton>
              </SidebarNav.Item>
            </SidebarNav>
          </SidebarWrapper>
          <MainContentWrapper>
            {!prospectBasicInfo.loading && <ProspectHighlights id="INSIGHT" prospect={prospectBasicInfo.prospect} />}
            {!prospectBasicInfo.loading && <ProspectBio id="BIO" prospect={prospectBasicInfo.prospect} />}
            {!prospectBasicInfo.loading && <ProspectContactInfo id="CONTACT" prospect={prospectBasicInfo.prospect} />}
            {!prospectBasicInfo.loading && <Connections id="CONNECTIONS" prospect={prospectBasicInfo.prospect} />}
            {!prospectBasicInfo.loading && <ProspectInsights id="HIGHLIGHTS" prospect={prospectBasicInfo.prospect} />}
            {!prospectBasicInfo.loading && <ProspectNews id="NEWS" prospect={prospectBasicInfo.prospect} />}
          </MainContentWrapper>
        </LayoutContainer>
      )}
      <ScrollToTop />
    </PageWrapper>
  );
};

export default ProspectDetailPage;
