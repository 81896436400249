import { useRef, useLayoutEffect, useCallback } from 'react';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition(element, useWindow) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, wait) {
  const position = useRef(getScrollPosition());

  const callBack = useCallback(() => {
    const currPos = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
  }, [effect]);

  useLayoutEffect(() => {
    let throttleTimeout = null;

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [callBack, wait]);
}
