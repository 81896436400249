import React from 'react';
import { TabsBar } from './elements';

type TabBarProps = {
  onTabChanged?: (id: string) => void;
  activeTabId?: string;
  children: React.ReactNode;
};

const TabBar = (props: TabBarProps): JSX.Element => {
  const { children, activeTabId, onTabChanged } = props;

  return (
    <div>
      <TabsBar role="tablist">
        {React.Children.map(children, (child: any) => {
          return (
            <>
              {React.cloneElement(child, {
                activeTabId: activeTabId,
                onTabClicked: (tabId: string) => onTabChanged && onTabChanged(tabId)
              })}
            </>
          );
        })}
      </TabsBar>
    </div>
  );
};

export default TabBar;
