import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { xsBodyTall, microBody } from 'components/mixins';
import { palette } from 'components/styles';

export const CloseWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  padding-right: 8px;
  background-color: transparent;
  border: none;
`;

export const MotionToastContent = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: 340px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid ${palette.brandDarkBlue};
  background-color: ${palette.pureWhite};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
`;

interface MotionProgressBarProps {
  barColor: string;
}

export const MotionProgressBar = styled(motion.div)<MotionProgressBarProps>`
  position: absolute;
  height: 3px;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: ${props => props.barColor};
`;

export const MotionIconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
`;

export const ContentWrapper = styled.div`
  margin: 14px 0;
  padding: 0 14px;
  border-left: 1px solid ${palette.gray3};

  > p {
    ${microBody(palette.pureBlack)};
  }
  > p:first-child {
    ${xsBodyTall(palette.pureBlack)};
    font-weight: 600;
  }
`;
