import React from 'react';
import { CheckboxLabel } from './elements';

interface LabelProps {
  className?: string;
  children?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = ({ className, children, ...restProps }) => {
  return <CheckboxLabel className={className}>{children}</CheckboxLabel>;
};

export default Label;
