import React, { useContext } from 'react';
import ProspectBulletList from 'components/prospect-bullet-list';
import { HighlightsTitle, HighlightsWrapper, AlignmentContent } from './elements';
import { OrgContext } from 'context/org-context';

const PrintProspectHighlights = props => {
  const { activeRaise } = useContext(OrgContext);
  const { prospect } = props;
  const activeRaiseData = prospect.getDonorRaiseDataByRaiseId(activeRaise.raiseId);

  const renderHighlightsSection = () => {
    let renderHighlights;
    if (activeRaiseData.highlights && activeRaiseData.highlights.length > 0) {
      renderHighlights = (
        <>
          <ProspectBulletList items={activeRaiseData.highlights} />
        </>
      );
    }

    return renderHighlights;
  };

  return (
    <>
      <HighlightsWrapper>
        <HighlightsTitle>{`Atticus Insights`}</HighlightsTitle>
        <AlignmentContent>{activeRaiseData.alignmentReason}</AlignmentContent>
        {renderHighlightsSection()}
      </HighlightsWrapper>
    </>
  );
};

export default PrintProspectHighlights;
