import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.gray3};
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  padding: 10px;
  background-color: ${palette.pureWhite};
`;

export const StyledInput = styled.input`
  ${xsBody(palette.pureBlack)}
  padding-left: 10px;
  background: ${palette.pureWhite};
  border: none;
  width: 100%;
  highlight: none;
  outline: none;

  &::placeholder {
    color: inherit;
  }
`;
