import styled, { css } from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';
import { microBody, xsBody } from 'components/mixins';

export const HeaderColumnsWrapper = styled.div`
  display: none;
  align-items: center;
  background-color: ${palette.gray7};
  border-bottom: 1px solid ${palette.gray6};

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
  }
`;

const WatchTitleStyles = css`
  display: flex;
  flex: 0 0 126px;
  text-align: left;
  justify-content: space-between;
  padding-left: 16px;

  @media only screen and (min-width: ${breakpoints['tablet-md']}) {
    flex: 0 1 188px;
  }
`;

const WatchlistStyles = css`
  display: flex;
  flex: 0 0 126px;
  text-align: left;
  justify-content: space-between;
  padding-left: 22px;

  @media only screen and (min-width: ${breakpoints['tablet-md']}) {
    padding-left: 40px;
    flex: 0 1 188px;
  }
`;

const NameStyles = css`
  flex: 1 1 286px;
  display: flex;
  justify-content: flex-start;
`;

const RaiseStyles = css`
  flex: 0 1 285px;
  display: none;
  text-align: left;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
  }
`;

const LocationStyles = css`
  flex: 0 1 285px;
  display: none;
  text-align: left;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
  }
`;

const TypeStyles = css`
  flex: 0 1 164px;
  display: none;
  text-align: left;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
  }
`;

const AskStyles = css`
  flex: 0 1 148px;
  display: none;
  text-align: left;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    display: flex;
  }
`;

const AlignmentStyles = css`
  flex: 0 1 140px;
  display: none;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    display: flex;
  }
`;

const getColumnStylesByType = type => {
  const styleMap = {
    WATCH_TITLE: WatchTitleStyles,
    WATCH: WatchlistStyles,
    NAME: NameStyles,
    RAISES: RaiseStyles,
    TYPE: TypeStyles,
    ASK: AskStyles,
    ALIGN: AlignmentStyles,
    LOCATION: LocationStyles
  };

  return styleMap[type];
};

export const ColumnItem = styled.div`
  flex-direction: column;
  padding: 10px 20px;
  ${props => getColumnStylesByType(props.columnType)}
`;

export const ProspectColumnItem = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  ${props => getColumnStylesByType(props.columnType)}
`;

export const ColumnTitle = styled.p`
  ${microBody(palette.pureBlack)};
  height: 18px;
  font-weight: 600;
  white-space: nowrap;
`;

export const ColumnSubTitle = styled.p`
  ${microBody(palette.pureBlack)};
  height: 18px;
`;

export const ProspectHorizontalItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.background};
  height: 72px;
`;

export const Avatar = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};

  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    ${props => xsBody(props.textColor)}
    font-weight: 600;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    background-color: ${palette.navyBackground};
  }
`;

export const ColumnItemText = styled.div`
  ${props => (props.color ? xsBody(props.color) : xsBody(palette.pureBlack))};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  width: 100%;
`;
