import React, { useState } from 'react';
import { NotificationToast } from 'components/ui-components';

export const NotificationContext = React.createContext();

const NotificationContextProvider = ({ children }) => {
  const [notificationQueue, setNotificationQueue] = useState([
    { title: 'item1', active: false, id: 1 },
    { title: 'item2', active: false, id: 2 }
  ]);

  const addNewNotification = ({ title, description, type }) => {
    const newNotes = [...notificationQueue];
    const firstFalse = newNotes.findIndex(note => note.active === false);
    const firstTrue = newNotes.findIndex(note => note.active === true);

    newNotes[firstFalse] = { ...newNotes[firstFalse] };
    newNotes[firstFalse].active = true;
    newNotes[firstFalse].title = title ? title : '';
    newNotes[firstFalse].description = description ? description : '';
    newNotes[firstFalse].type = type ? type : 'success';

    if (firstTrue >= 0) {
      newNotes[firstTrue] = { ...newNotes[firstTrue] };
      newNotes[firstTrue].active = false;
    }

    setNotificationQueue(newNotes);
  };

  const dismissNotifications = () => {
    let newNotes = [...notificationQueue];
    newNotes = newNotes.map(note => ({ ...note, active: false }));

    setNotificationQueue(newNotes);
  };

  const defaultContext = {
    notificationQueue,
    addNewNotification
  };

  return (
    <NotificationContext.Provider value={defaultContext}>
      <NotificationToast notificationQueue={notificationQueue} dismissNotifications={dismissNotifications} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
