import React, { useEffect } from 'react';
import { palette } from 'components/styles';
import { X, Check, AlertOctagon, AlertCircle } from 'react-feather';

import { CloseWrapper, MotionToastContent, MotionProgressBar, MotionIconWrapper, ContentWrapper } from './elements';

const transition = { type: 'spring', duration: 0.4, staggerChildren: 0.2 };
const rowVariant = {
  hidden: { opacity: 0, x: '-400px' },
  show: {
    transition: transition,
    opacity: 1,
    x: 0
  },
  close: { transition: { type: ' tween', duration: 0.3 }, opacity: 0, x: '-400px' }
};

const progressVariant = {
  start: { width: `100%` },
  progress: { transition: { type: ' tween', duration: 3 }, width: 0 }
};

interface ToastProps {
  id: string;
  title: string;
  description: string;
  type: string;
  closeToast: () => void;
}

const Toast: React.FC<ToastProps> = props => {
  const { id, title, description, type, closeToast } = props;

  useEffect(() => {
    const autoTimeout = setTimeout(() => {
      if (type !== 'error') {
        closeToast();
      }
    }, 2800);

    return () => clearTimeout(autoTimeout);
  }, [id, closeToast, type]);

  const getColorByType = (type: string) => {
    let color;

    switch (type) {
      case 'success':
        color = palette.brandBlue;
        break;
      case 'info':
        color = palette.warning;
        break;
      case 'error':
        color = palette.error;
        break;
      default:
        color = palette.brandBlue;
        break;
    }

    return color;
  };

  const getIconByType = (type: string) => {
    let icon;

    switch (type) {
      case 'success':
        icon = <Check size={24} stroke={getColorByType(type)} />;
        break;
      case 'info':
        icon = <AlertCircle size={24} stroke={getColorByType(type)} />;
        break;
      case 'error':
        icon = <AlertOctagon size={24} stroke={getColorByType(type)} />;
        break;
      default:
        icon = <Check size={24} stroke={getColorByType(type)} />;
        break;
    }

    return icon;
  };

  return (
    <MotionToastContent
      data-testid="toast-notification"
      key={id}
      variants={rowVariant}
      initial="hidden"
      animate="show"
      exit="close"
    >
      <MotionIconWrapper
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: [0, 1.4, 1], opacity: 1 }}
        transition={{ type: 'spring', duration: 0.3, delay: 0.4 }}
      >
        {getIconByType(type)}
      </MotionIconWrapper>
      <ContentWrapper>
        <p>{title}</p>
        <p>{description}</p>
      </ContentWrapper>
      <CloseWrapper onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => closeToast()}>
        <X size={12} />
      </CloseWrapper>
      {type !== 'error' && (
        <MotionProgressBar
          barColor={getColorByType(type)}
          initial="start"
          animate="progress"
          variants={progressVariant}
        />
      )}
    </MotionToastContent>
  );
};

export { Toast };
