import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';

export const DropdownContainer = styled('div')`
  position: relative;
  max-width: 256px;
  flex: 1;
  min-width: 0;
`;

export const ClearableDropdownHeader = styled('div')`
  ${xsBody(palette.pureBlack)}

  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${palette.pureWhite};
  border: 1px solid ${palette.gray3};
  border-radius: 8px;
  width: 100%;
`;

export const ClearableHeaderLabel = styled('p')`
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 6px;
  min-width: 75%;
  cursor: default;
`;

export const ClearableActionWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const ClearIcon = styled('button')`
  border: 0;
  background-color: ${palette.pureWhite};

  &:hover {
    background-color: ${palette.gray7};
    border-radius: 30%;
  }
`;

export const DropdownHeader = styled('button')`
  ${xsBody(palette.pureBlack)}

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${palette.pureWhite};
  border: 1px solid ${palette.gray3};
  border-radius: 8px;
  width: 100%;

  p {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 6px;
  }
`;

interface DropdownListContainerProps {
  position: string;
}

export const DropdownListContainer = styled('div')<DropdownListContainerProps>`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  right: ${props => (props.position === 'RIGHT' ? 0 : 'none')};
  left: ${props => (props.position === 'LEFT' ? 0 : 'none')};
  min-width: 100%;
  width: 100%;
`;

export const DropdownList = styled('ul')`
  border: 1px solid ${palette.gray3};
  background-color: ${palette.pureWhite};
  border-radius: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface ListItemProps {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 8px 10px;
  margin: 0;
  background-color: ${props => (props.selected ? palette.gray3 : palette.pureWhite)};
  cursor: pointer;

  &:hover {
    background-color: ${palette.gray3};
  }

  p,
  p:visited {
    color: ${xsBody(palette.pureBlack)};
  }
`;

export const StyledLabel = styled.label`
  ${xsBody(palette.pureBlack)}
  font-weight: 600;
`;
