import '@fontsource/barlow';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';

import { createGlobalStyle } from 'styled-components/macro';

import { palette } from '../styles';

const headerFont = 'Barlow';
const bodyFont = 'Barlow';

export const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    font-family: ${bodyFont};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    background-color: #EDEDED;   

    @media print {
        background-color: ${palette.pureWhite};   
    }
}

html {
    overflowY: scroll;
    scrollbar-width: none;
    scrollbar-height: none;

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
a:visited,
p,
button,
textarea,
label,
input {
    font-family: ${headerFont};
    font-weight: normal;
    font-style: normal;
    margin: 0;
}

p {
    margin: 0px;
}

a,
a:visited {
    color: ${palette.pureBlack};
    text-decoration: none;
}

*, *:before, *:after{
    box-sizing: border-box;
}
`;
