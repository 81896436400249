import React from 'react';

import ProspectSection from 'components/prospect-profile/prospect-section';
import { palette } from 'components/styles';
import { Avatar, AvatarChainLink, TileRow, ChainLink, ChainRelation, ChainText, ChainTextTitle } from './elements';
import ProspectProfile from 'models/ProspectProfile/ProspectProfile';
import ProspectMutualConnection, {
  MutualConnectionLink
} from 'models/ProspectMutualConnection/ProspectMutualConnection';

interface PrintProspectConnectionProps {
  prospect: ProspectProfile;
  id?: string;
}

const PrintProspectConnections: React.FC<PrintProspectConnectionProps> = props => {
  const { id, prospect } = props;

  const renderConnectionChain = (connectionData: ProspectMutualConnection) => {
    return connectionData.links.map((chainLink: MutualConnectionLink, index) => {
      const nextChild = connectionData.links[index + 1];
      const connectionReason = nextChild ? nextChild.strengthReason : connectionData.strengthToDonorReason;

      return (
        <div key={index}>
          <ChainLink>
            <ChainTextTitle>
              <strong>{chainLink.name}</strong>
            </ChainTextTitle>
            <ChainTextTitle>{chainLink.personDetails}</ChainTextTitle>
          </ChainLink>
          <ChainRelation>
            <ChainText fontStyle={'italic'}>{connectionReason}</ChainText>
          </ChainRelation>
        </div>
      );
    });
  };

  const renderConnectionRows = (connectionList: ProspectMutualConnection[]) => {
    let renderedTiles: any[] = [];

    if (connectionList.length > 0) {
      renderedTiles = connectionList.map((item: ProspectMutualConnection, index) => {
        return (
          <TileRow key={index}>
            {renderConnectionChain(item)}
            <AvatarChainLink>
              <Avatar backgroundColor={palette.brandDarkBlue} textColor={palette.pureWhite}>
                <div>
                  {prospect.photo && <img src={prospect.photo} alt="propspect-avatar" />}
                  {!prospect.photo && <p>{prospect.getDonorInitials()}</p>}
                </div>
              </Avatar>
            </AvatarChainLink>
          </TileRow>
        );
      });
    }

    return renderedTiles;
  };

  return (
    <div>
      <ProspectSection className="" id={id}>
        <ProspectSection.Title className="">Connections</ProspectSection.Title>
        {prospect.mutualConnections && prospect.mutualConnections.length > 0 && (
          <div>{renderConnectionRows(prospect.mutualConnections)}</div>
        )}
      </ProspectSection>
    </div>
  );
};

export default PrintProspectConnections;
