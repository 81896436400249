import React, { useContext, useEffect, useMemo } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { useMsalUserManager } from 'hooks/useManageMsalUser';

import { MainNav } from 'components/ui-components';
import Footer from 'components/footer';
import Routes from 'routes/routes';
import { scopes } from '../../config/authConfig';
import RouteConfig from 'routes/routes-config';
import { FlexMotionDiv } from './elements';
import { FeatureFlags } from 'context/flag-context';
import { clearStorage } from 'services/utilities';
import { sleep } from 'services/utilities';

const Root = () => {
  const { instance, brand, user } = useMsalUserManager();
  const { idTokenClaims } = user || {};
  const { city: orgId } = idTokenClaims || {};
  const { orgId: currentOrgId, setCurrentOrgId } = useContext(FeatureFlags);
  const history = useHistory();
  const loginRequest = useMemo(() => {
    return { scopes: [scopes.allAccess] };
  }, []);
  const fade = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 1 }
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      /**
       * FORGOT PASSWORD "CANCELLED" CALLBACK
       * The following callback detects a user clicking "cancel" when entering self-asserted
       * information. This ensures the user is directed back into the login flow
       */
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        // Pause for a second to make sure the tokens are successfully written to storage - possible cause of double login
        sleep(1000);
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        clearStorage();
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90091') > -1) {
          instance.loginRedirect(loginRequest);
        }
        history.push('/login-error', { message: event?.error?.message });
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, loginRequest, history, orgId]);

  /**
   * Lifecycle hook responsible for setting the title of the application according
   * to the dynamic brand/title evaluated via the user's email (within useMsalUserManager)
   */
  useEffect(() => {
    if (brand.name) {
      document.title = brand.name;
    }
    if (currentOrgId === '' && orgId) {
      setCurrentOrgId(orgId);
    }
  }, [brand, orgId, currentOrgId, setCurrentOrgId]);

  return (
    <Switch>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <FlexMotionDiv key="auth" {...fade}>
          <MainNav></MainNav>
          <Routes routeConfig={RouteConfig} />
          <Footer />
        </FlexMotionDiv>
      </MsalAuthenticationTemplate>
    </Switch>
  );
};

export default Root;
