import React, { useContext } from 'react';

import { FadeIn } from 'components/ui-components';
import { CardListWrapper, ProspectListCard } from './elements';
import { palette } from 'components/styles';
import { OrgContext } from 'context/org-context';

const cardAvatarBackgrounds = [
  { bg: palette.brandDarkBlue, text: palette.pureWhite },
  { bg: palette.brandLightBlue, text: palette.pureBlack },
  { bg: palette.navyBackground, text: palette.pureWhite }
];

export const ProspectCardList = props => {
  const { prospects } = props;
  const { organizationRaises } = useContext(OrgContext);

  const renderProspectCards = () => {
    let renderCards;
    let indexCounter = 0;

    if (prospects && prospects.length > 0) {
      renderCards = prospects.map((prospect, index) => {
        const background = cardAvatarBackgrounds[indexCounter].bg;
        const text = cardAvatarBackgrounds[indexCounter].text;

        if (!prospect.photo) {
          indexCounter = indexCounter < 2 ? (indexCounter += 1) : 0;
        }

        return (
          <FadeIn delay={0.1 * (index % 4)} key={prospect.id}>
            <ProspectListCard
              organizationRaises={organizationRaises}
              prospect={prospect}
              loading={prospect.loading}
              backgroundColor={background}
              textColor={text}
            />
          </FadeIn>
        );
      });
    }

    return renderCards;
  };
  return <CardListWrapper data-testid="prospect-list">{renderProspectCards()}</CardListWrapper>;
};

export default ProspectCardList;
