import React from 'react';
import styled from 'styled-components/macro';
import ProspectDetailPage from 'pages/prospect-detail-page';
import AuthPage from 'pages/auth-page';
import LoginError from 'pages/login-error';
import { Route, Switch } from 'react-router-dom';

const Root = () => {
  return (
    <RootWrapper>
      <Switch>
        <Route path="/preview" exact={true} component={ProspectDetailPage} />
        <Route path="/login-error" component={LoginError} />
        <Route path="/" component={AuthPage} />
      </Switch>
    </RootWrapper>
  );
};

const RootWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export default Root;
