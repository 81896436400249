import React, { useEffect, useState } from 'react';

interface FlagProps {
  children?: React.ReactNode;
}

type Feature = {
  featureName: string;
  featureValue: boolean;
};

interface IFlagContext {
  setCurrentOrgId: (id: string) => void;
  features: Feature[];
  orgId: string;
  isFeatureEnabled: (name: string) => boolean;
}

export const FeatureFlags = React.createContext<IFlagContext>({
  setCurrentOrgId: () => undefined,
  features: [] as Feature[],
  orgId: '',
  isFeatureEnabled: () => false
});

export const enum Flag {
  ShowRaiseTabs = 'ShowRaiseTabs',
  ShowRaiseDropdown = 'ShowRaiseDropdown'
};

const FeatureFlagsProvider = (props: FlagProps): JSX.Element => {
  const [features, setFeatures] = useState([] as Feature[]);
  const [isLoading, setIsLoading] = useState(true);
  const [orgId, setOrgId] = useState('');
  const { children } = props;

  const setCurrentOrgId = (id: string) => {
    setOrgId(id);
  };

  const isFeatureEnabled = (name: string): boolean => {
    if (!features.some(f => f.featureName === name)) {
      return false;
    }
    return features.find(f => f.featureName === name)!.featureValue;
  };

  const defaultContext: IFlagContext = {
    setCurrentOrgId,
    features,
    orgId,
    isFeatureEnabled
  };

  useEffect(() => {
    (async () => {
      try {
        setFeatures([
          { featureName: Flag.ShowRaiseDropdown, featureValue: true },
          { featureName: Flag.ShowRaiseTabs, featureValue: false }
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orgId]);

  return <FeatureFlags.Provider value={defaultContext}>{isLoading ? '' : children}</FeatureFlags.Provider>;
};

export default FeatureFlagsProvider;
