import React from 'react';
import { FooterWrapper } from './elements';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const Footer: React.FunctionComponent<Props> = ({ className, children, ...restProps }) => {
  return <FooterWrapper className={className}>{children}</FooterWrapper>;
};

export default Footer;
