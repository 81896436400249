import { css } from 'styled-components/macro';
import { palette } from 'components/styles';

export const baseButton = css`
  display: block;
  background-color: ${palette.pureWhite};
  text-decoration: none;
  border-radius: 100px;
  padding: 14px 20px;
  transition: all 1s ease-in;
  cursor: pointer;
`;

export const primaryButton = css`
  ${baseButton}
  background-color: ${palette.pureWhite};
  border: 1px solid ${palette.gray5};
`;

export const lightFilledButton = css`
  color: ${palette.brandBlueDark};
  cursor: pointer;
  border: none;
  background-color: ${palette.gray4};
  border-radius: 100px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 24px;
  text-decoration: none;
`;

export const primaryTextButton = css`
  color: ${palette.brandBlueDark};
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  border: none;
  padding: 0;
  background-color: transparent;
`;
