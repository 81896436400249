import React from 'react';
import { TabActiveIndicator, TabButton, TabItem } from './elements';

type TabProps = {
  id: string;
  activeTabId?: boolean;
  onTabClicked?: (id: string) => void;
  children: React.ReactNode;
};

const Tab = (props: TabProps): JSX.Element => {
  const { id, activeTabId = '', onTabClicked, children } = props;
  const isActive = activeTabId === id;

  const tabClickedHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && onTabClicked) {
      onTabClicked(id);
    }
  };

  return (
    <TabItem active={isActive}>
      <TabButton onClick={tabClickedHandler} active={isActive}>
        {children}
      </TabButton>
      <TabActiveIndicator active={isActive} />
    </TabItem>
  );
};

export default Tab;
