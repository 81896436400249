import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xlHeading } from 'components/mixins';

export const ProspectAvatarWrapper = styled.div`
  flex: 0 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProspectAvatarImage = styled.div`
  height: 160px;
  width: 160px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};

  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    background-color: ${palette.navyBackground};
  }

  p {
    ${props => xlHeading(props.textColor)}
  }
`;
