import React from 'react';
import { ContactTileWrapper, ContactInfoText } from './elements';

const ContactTileText = props => {
  const { children, fontStyle = 'normal', subText, fontWeight = 400 } = props;

  return (
    <ContactInfoText fontWeight={fontWeight} fontStyle={fontStyle}>
      {children}
      {subText && <span>{`(${subText})`}</span>}
    </ContactInfoText>
  );
};

export const ContactTile = props => {
  const { className, children } = props;

  return (
    <ContactTileWrapper className={className}>
      <div>{children}</div>
    </ContactTileWrapper>
  );
};

ContactTile.Text = ContactTileText;

export default ContactTile;
