import React from 'react';
import LiftUsWhite from 'assets/images/liftus_logo_white.png';
import { SvgLogo } from 'components/svg-components/svg-logo';
import { SvgLogoAlt } from 'components/svg-components';

interface Brand {
  [key: string]: BrandObject;
}

type BrandObject = {
  logo: React.ReactElement<HTMLImageElement>;
  logoAlt: React.ReactElement<HTMLImageElement>;
  name: string;
  capitalized: string;
};

export const BRAND_ASSETS: Brand = {
  LIFT_US: {
    logo: <img src={LiftUsWhite} alt="liftUS logo" />,
    logoAlt: <img src={LiftUsWhite} alt="liftUS logo" />,
    name: 'LiftUS',
    capitalized: 'LIFTUS'
  },
  ATTICUS: {
    logo: <SvgLogo />,
    logoAlt: <SvgLogoAlt />,
    name: 'Atticus',
    capitalized: 'ATTICUS'
  }
};
