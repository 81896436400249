import React from 'react';
import styled, { css } from 'styled-components/macro';
import { palette } from 'components/styles';
import { Link } from 'react-router-dom';
import { microBody } from 'components/mixins';

export const RootLI = styled.li`
  position: relative;
  padding: 8px 0;
`;

export const NavStyles = css`
  position: relative;
  font-size: 16px;
  color: ${palette.pureWhite};
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

interface StandardNavButtonProps {
  weight?: number;
  activeRoute: boolean;
}

export const StandardNavButton = styled.button<StandardNavButtonProps>`
  ${NavStyles}
  font-weight: ${props => props.weight};

  &:before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: ${props => (props.activeRoute ? '100%' : 0)};
    height: 1px;
    background-color: ${palette.pureWhite};
    transition: all 450ms ease-in-out;
  }
`;

export const StandardNavLink = styled(props => <Link {...props.linkProps} className={props.className} />)`
  ${NavStyles}

  &:visited {
    color: ${palette.pureWhite};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: ${props => (props.activeRoute ? '100%' : 0)};
    height: 1px;
    background-color: ${palette.pureWhite};
    transition: all 450ms ease-in-out;
  }
`;

export const StandardExternalLink = styled.a`
  ${NavStyles}

  &:visited {
    color: ${palette.pureWhite};
  }
`;

export const StandardNavLabel = styled.p`
  ${microBody(palette.pureWhite)};
`;
