import React from 'react';

const SvgAtticusIcon = ({ fill, stroke, fillAmount }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="atticus">
        <path
          d="M0.404541 19.75L10 0.562503L19.5955 19.75H16.0696L10.2236 8.0622L10 7.61518L9.77641 8.0622L3.93042 19.75H0.404541Z"
          fill="white"
          stroke="black"
          strokeWidth="1"
        />
      </clipPath>
      <path
        d="M0.404541 19.75L10 0.562503L19.5955 19.75H16.0696L10.2236 8.0622L10 7.61518L9.77641 8.0622L3.93042 19.75H0.404541Z"
        fill="white"
        stroke="black"
        strokeWidth=".5"
      />
      <rect x="0" y={`${fillAmount}%`} fill={fill} width="100%" height="100%" clipPath="url(#atticus)" />
    </svg>
  );
};

export { SvgAtticusIcon };
