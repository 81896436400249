import styled from 'styled-components';
import { Button } from 'components/ui-components';
import { xsBody } from 'components/mixins';
import { palette } from 'components/styles';

export const ToggleButton = styled(Button)`
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
`;

export const ToggleLabelWrapper = styled.div`
  margin-left: 8px;
  height: 20px;
  position: relative;
  width: 114px;
`;

export const ToggleLabel = styled.p`
  ${xsBody(palette.pureWhite)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(-10px)')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  transition: all 500ms ease-in;
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`;
