export const colors = {
  brandBlue: '#4FB0F9',
  brandBlueDark: '#06387D',
  primaryGreen: '#74EB87',
  primaryBlue: '#00A5CE',
  primaryYellow: '#EBD874',
  secondaryLightGreen: '#B5FFC1',
  secondaryLightBlue: '#70E2FF',
  tertiaryLightGreen: '#DFFFE4',
  tertiaryLightBlue: '#F2FAFC',
  tertiaryDarkBlue: '#D1E3E8',
  tertiaryDarkGreen: '#D0E7D4',
  illustrationBlue: '#CBF5FF',
  illustrationGreen: '#ACFFB9',
  illustrationRed: '#CD8987',
  lightSkyBlue: '#DEF8FF',
  lightAquaBlue: '#A1EDFF',
  lightGreenConfirmation: '#F4FFF6',
  error: '#eb8888',
  warning: '#C05850',
  pureWhite: '#FFFFFF',
  pureBlack: '#000000',
  gray1: '#333333',
  gray2: '#94959B',
  gray3: '#B0B0B0',
  gray4: '#F3F3F7',
  graySelected: '#FAFAFA',
  border: '#E6E6E6',
  darkBackground: '#051D44',
  app: '#E5E5E5'
};

export const palette = {
  brandBlue: '#007CFF',
  brandDarkBlue: '#1465BB',
  brandLightBlue: '#E7F0F8',
  fadedBlue: '#435673',
  pureWhite: '#FFFFFF',
  pureBlack: '#000000',
  gray1: '#333333',
  gray2: '#94959B',
  gray3: '#D0D0D0',
  gray4: '#CBCBCB',
  gray5: '#C4C4C4',
  gray6: '#A0A0A0',
  gray7: '#EAEAEA',
  graySelected: '#FAFAFA',
  border: '#E6E6E6',
  navyBackground: '#051D44',
  navyHover: '#082C66',
  background: '#EDEDED',
  error: '#C05850',
  warning: '#E79512',
  lightSkyBlue: '#DEF8FF'
};
