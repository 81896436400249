import styled from 'styled-components';
import { motion } from 'framer-motion';

import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';

export const ContactTileWrapper = styled(motion.div)`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  min-height: 72px;
  padding: 0 8px;
  justify-content: space-between;
  border-left: 1px solid ${palette.gray2};
`;

export const ContactInfoText = styled.p`
  ${xsBody(palette.pureBlack)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  min-height: 20px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    margin-left: 6px;
    font-weight: 400;
  }
`;

export const ContactInfoButton = styled.button`
  ${xsBody(palette.pureBlack)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  border: none;
  background-color: transparent;
  text-decoration: underline;
  text-align: left;
  font-style: italic;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`;
