import { motion } from 'framer-motion';
import React, { useState, useRef, useEffect } from 'react';

interface Props {
  id: string;
  className?: string;
  delay?: number;
}

const FadeIn: React.FC<Props> = props => {
  const { id, className, delay = 0, children } = props;
  const [isVisible, setVisible] = useState<boolean>(false);
  const [observe, setObserve] = useState<IntersectionObserver | undefined>();
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });

    // Invoke the observer
    const { current } = domRef;
    if (current) {
      observer.observe(current);
      setObserve(observer);
    }

    return () => {
      current && observer.unobserve(current);
    };
  }, []);

  // Remove observer after the animation has completed
  useEffect(() => {
    const { current } = domRef;
    if (current && isVisible !== false && observe) {
      observe.unobserve(current);
    }
  }, [observe, isVisible]);

  const animations = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 30 },
    transition: { delay: delay, duration: 0.6 }
  };

  return (
    <motion.div id={id} className={className} ref={domRef} {...animations}>
      {children}
    </motion.div>
  );
};

export { FadeIn };
