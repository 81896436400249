import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { atomic, xsBody } from 'components/mixins';

export const AvatarChainLink = styled.div`
  flex: 1;
  padding: 0 8px;
`;

interface AvatarProps {
  backgroundColor: string;
  textColor: string;
}

export const Avatar = styled.div<AvatarProps>`
  position: relative;
  width: 28px;
  height: 28px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};

  div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    ${props => xsBody(props.textColor)}
    font-weight: 600;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    background-color: ${palette.navyBackground};
  }
`;

export const TileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray3};
  break-inside: avoid;

  > div:first-child {
    padding-left: 0;
  }

  > div:last-child {
    padding-right: 0;
  }
`;

export const ChainLink = styled.div`
  flex: auto;
  flex-grow: 0;
  padding: 0 4px;
`;

export const ChainRelation = styled.div`
  flex: auto;
  flex-grow: 0;
  margin: 0 8px;
  position: relative;
  border-left: 1px solid ${palette.gray3};
  border-right: 1px solid ${palette.gray3};
  border-radius: 8px;
  background-color: ${palette.pureWhite};
  padding: 4px;
  text-align: center;
  max-width: 100px;

  &:before {
    content: '';
    width: calc(100% + 16px);
    position: absolute;
    top: 50%;
    left: -8px;
    height: 1px;
    background-color: ${palette.gray3};
    z-index: -1;
  }
`;

export const ChainTextTitle = styled.p`
  ${atomic(palette.pureBlack)};
  white-space: nowrap;
`;

interface ChainTextProps {
  fontStyle: string;
}

export const ChainText = styled.p<ChainTextProps>`
  ${atomic(palette.pureBlack)}
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
`;
