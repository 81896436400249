import React, { useState } from 'react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { ArrowRight } from 'react-feather';
import { BasicTile, CenterModal, Tooltip } from 'components/ui-components';
import {
  TitleTooltipWrapper,
  EngagementBulletList,
  SeeMoreButton,
  ModalContent,
  EngagementTileWrapper,
  EngagementBlockList
} from './elements';

const ProspectEngagementTile = props => {
  const { prospect } = props;
  const { assessment } = prospect;
  const [modalOpen, setModalOpen] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackViewEngagement = useTrackEvent(appInsights, 'PROFILE | See More Org Engagement', prospect);

  const dismissCenterModal = () => {
    setModalOpen(false);
  };

  const openCenterModal = () => {
    setModalOpen(true);
    trackViewEngagement();
  };

  const renderSeeMore = () => {
    let seeMoreRender;

    if (assessment.engagement && assessment.engagement.length > 3) {
      seeMoreRender = (
        <SeeMoreButton buttonStyle="secondary" onClick={openCenterModal}>
          <strong>SEE ALL ENGAGEMENT</strong>
          <ArrowRight size={18} />
        </SeeMoreButton>
      );
    }

    return seeMoreRender;
  };

  const renderEngagementCardLinks = () => {
    const engagementSublist =
      assessment.engagement.length > 3 ? assessment.engagement.slice(0, 3) : [...assessment.engagement];

    return (
      <>
        <EngagementBulletList items={engagementSublist} textStyle="XS_BODY" />
        {renderSeeMore()}
      </>
    );
  };

  const renderEngagementBlocks = () => {
    return (
      <EngagementBlockList>
        {assessment.engagement.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </EngagementBlockList>
    );
  };

  return (
    <>
      <BasicTile>
        <TitleTooltipWrapper>
          <BasicTile.SubTitle>Organization Engagement</BasicTile.SubTitle>
          <Tooltip>
            <Tooltip.Title>Organization Engagement</Tooltip.Title>
            <Tooltip.Paragraph>{`Details regarding the major donor’s interaction with your organization.  `}</Tooltip.Paragraph>
          </Tooltip>
        </TitleTooltipWrapper>
        <EngagementTileWrapper>{renderEngagementCardLinks()}</EngagementTileWrapper>
      </BasicTile>
      <BasicTile.Description>
        <strong>Interactions </strong> between the major donor and your Organization.
      </BasicTile.Description>
      <CenterModal opened={modalOpen}>
        <CenterModal.Header toggleOpened={dismissCenterModal}>
          <h2>Organization Engagement</h2>
        </CenterModal.Header>
        <ModalContent>{renderEngagementBlocks()}</ModalContent>
      </CenterModal>
    </>
  );
};

export default ProspectEngagementTile;
