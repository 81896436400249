import React, { useRef, useState } from 'react';
import { TooltipWrapper, TooltipContent, InfoWrapper } from './elements';

import { Info, X } from 'react-feather';
import Title from './title';
import Paragraph from './paragraph';

interface TooltipProps {
  className: string;
  children: React.ReactChild;
  iconSize?: number;
  iconStrokeWidth?: number;
  minWidth: number;
}

interface TooltipInterface extends React.FunctionComponent<TooltipProps> {
  Title?: any;
  Paragraph?: any;
}

const Tooltip: TooltipInterface = props => {
  const { className, children, iconSize = 24, iconStrokeWidth = 2, minWidth } = props;
  const [active, setActive] = useState(false);
  const [arrowLeft, setArrowLeft] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const showTip = () => {
    setActive(true);
    const infoWrapperElement = ref?.current ?? '';
    if (infoWrapperElement) {
      setArrowLeft(infoWrapperElement.offsetLeft);
    }
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <TooltipWrapper className={className} ref={ref} onMouseEnter={showTip} onMouseLeave={hideTip}>
      <InfoWrapper>
        <Info size={iconSize} strokeWidth={iconStrokeWidth} />
      </InfoWrapper>
      <TooltipContent active={active} arrowLeftOffset={arrowLeft} minWidth={minWidth}>
        <X size={12} />
        {children}
      </TooltipContent>
    </TooltipWrapper>
  );
};

Tooltip.Title = Title;
Tooltip.Paragraph = Paragraph;

export { Tooltip };
