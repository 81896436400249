import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'react-feather';
import { Button } from './element';

const isDocument = typeof document !== `undefined`;

interface ScrollToTopProps {
  threshold: number;
}

const ScrollToTop: React.FC<ScrollToTopProps> = props => {
  const { threshold = 1000 } = props;
  const [visible, setVisibile] = useState(false);

  useEffect(() => {
    if (isDocument) {
      const toggleVisibility = () => {
        const scrolled = document.documentElement.scrollTop;
        setVisibile(scrolled > threshold ? true : false);
      };

      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
    }
  }, [threshold]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Button onClick={scrollToTop} className={visible ? '' : 'hidden'}>
      <ArrowUp />
    </Button>
  );
};

export { ScrollToTop };
