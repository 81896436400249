import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'components/styles';

interface InputProps {
  id?: string;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
}

const StyledInput: React.FC<InputProps> = props => {
  return <input {...props} onChange={props.onChange} className={props.className} />;
};

const Input = styled(StyledInput)`
  font-size: 18px;
  line-height: 28px;
  padding: 24px;
  background: ${colors.pureWhite};
  border: 1px solid ${colors.border};
  box-sizing: border-box;
  border-radius: 8px;
`;

export { Input };
