import React from 'react';
import { X } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from 'components/ui-components';
import { InputWrapper, StyledInput } from './elements';
import { motionFadeUp } from 'components/mixins';
import { palette } from 'components/styles';

interface IconInputProps {
  id: string;
  icon: React.ReactElement;
  className?: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputClear: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  placeholder?: string;
  value: string;
}

const IconInput: React.FC<IconInputProps> = props => {
  const { id, className, icon, onInputChange, onInputClear, placeholder, value } = props;
  const inputValue = value ? value : '';

  /**
   * Function to evaluate if the clear input markup should be generate depending on the
   * existence of a value
   *
   * Click of the clear button emits a null "onChange" event which can be handled by the
   * parent to clear the value of the input
   * @return {jsx} Clear Button Markup
   */
  const renderClearButton = () => {
    let clearButton = null;

    if (value !== '') {
      clearButton = (
        <motion.div {...motionFadeUp}>
          <Button buttonStyle="secondary" onClick={onInputClear}>
            <X size={16} color={palette.gray3} />
          </Button>
        </motion.div>
      );
    }

    return clearButton;
  };

  return (
    <InputWrapper id={id} className={className}>
      {React.cloneElement(icon, {})}
      <StyledInput placeholder={placeholder} onChange={onInputChange} value={inputValue} />
      <AnimatePresence>{renderClearButton()}</AnimatePresence>
    </InputWrapper>
  );
};

export { IconInput };
