import styled, { css } from 'styled-components/macro';
import { microBody, jumboHeading } from 'components/mixins';
import { Container } from 'components/ui-components';
import { palette } from 'components/styles';

export const ConnectionWrapper = styled.div`
  svg {
    margin-right: 6px;
  }
`;

export const ProspectHeaderWrapper = styled.section`
  padding: 80px 0 32px 0;
  background-color: ${palette.navyBackground};
`;

export const ProspectHeaderContainer = styled(Container)`
  display: grid;
  flex-direction: row;
  column-gap: 24px;
  grid-template-columns: 224px 1fr;
  grid-template-areas: 'profilePicture profileData';
`;

export const LayoutLeftWrapper = styled.div`
  grid-area: 'profilePicture';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LayoutRightWrapper = styled.div`
  grid-area: 'profileData';
  color: ${palette.pureWhite};
  text-align: left;

  h1 {
    font-family: 'Effra';
    font-size: 32px;
    font-weight: 300;
    line-height: 50px;
    margin: 0px;
  }
`;
export const AvatarBlock = styled.div`
  background-color: ${palette.pureWhite};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 240px;
  width: 224px;
  bottom: -48px;
  margin-bottom: 0;
  box-shadow: 0px 1px 10px ${palette.gray5};
`;

export const AvatarBase = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${props => props.background};

  img {
    width: 100%;
    background-color: ${palette.gray3};
  }
`;

export const Avatar = styled.div`
  ${AvatarBase}
  p {
    ${jumboHeading(palette.pureWhite)}
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: ${palette.gray3};
  }
`;

export const ProspectDetails = styled.div`
  ${microBody(palette.pureWhite)}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ProspectDetailsGroup = styled.div`
  align-items: flex-start;
  border-left: 1px solid ${palette.gray2};
  display: flex;
  flex-direction: column;
  flex: 0 0 156px;
  padding-left: 20px;
  margin-bottom: 24px;

  &:first-child {
    flex: 1;
    padding-right: 8px;
    padding-left: 0;
    border-left: none;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  strong {
    margin-left: 4px;
  }

  svg {
    margin-right: 8px;
  }
`;
