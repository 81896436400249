import React, { useState, useEffect } from 'react';
import { ChevronDown, X } from 'react-feather';
import find from 'lodash/find';
import Label from './label';

import {
  ClearIcon,
  ClearableActionWrapper,
  ClearableDropdownHeader,
  ClearableHeaderLabel,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  ListItem
} from './elements';
import { useOuterClick } from 'utils';
import { SvgArrowDown } from 'components/svg-components';
import { palette } from 'components/styles';
import { SvgDropdownArrow } from 'components/svg-components/svg-dropdown-arrow';

type DropdownPropsShape = {
  value: string;
  label: string;
  key?: string;
  disabled?: boolean;
};

interface DropdownProps {
  id?: string;
  className?: string;
  options: Array<DropdownPropsShape>;
  onSelectionChange: (option: string) => void;
  value: string;
  dropPosition?: string;
  clearable?: boolean;
  iconPosition?: string;
  disabled?: boolean;
  useIcon?: boolean;
}

interface DropdownInterface extends React.FunctionComponent<DropdownProps> {
  Label?: any;
}

const Dropdown: DropdownInterface = props => {
  const {
    id,
    className,
    options,
    onSelectionChange,
    value,
    dropPosition = 'RIGHT',
    clearable,
    iconPosition = 'RIGHT',
    disabled,
    useIcon
  } = props;
  const [selectedOption, setSelectedOption] = useState<DropdownPropsShape>();
  const [isOpen, setIsOpen] = useState(false);

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    setSelectedOption(find(options, { value: value }));
  }, [value, options]);

  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const onOptionClicked = (option: DropdownPropsShape) => {
    setIsOpen(false);
    onSelectionChange(option.value);
  };

  return (
    <DropdownContainer id={id} className={className} ref={innerRef}>
      {!clearable && (
        <DropdownHeader aria-expanded={isOpen} onClick={toggling}>
          {iconPosition === 'LEFT' && !useIcon && <ChevronDown size={16} />}
          {iconPosition === 'LEFT' && useIcon && (
            <SvgDropdownArrow stroke={disabled ? palette.gray1 : palette.pureWhite} />
          )}
          <p>{selectedOption?.label}</p>
          {iconPosition === 'RIGHT' && !useIcon && <ChevronDown size={16} />}
          {iconPosition === 'RIGHT' && useIcon && (
            <SvgDropdownArrow stroke={disabled ? palette.gray1 : palette.pureWhite} />
          )}
        </DropdownHeader>
      )}
      {clearable && (
        <ClearableDropdownHeader aria-expanded={isOpen}>
          <ClearableHeaderLabel onClick={toggling}>{selectedOption?.label ?? 'Select one'}</ClearableHeaderLabel>
          <ClearableActionWrapper>
            {selectedOption && (
              <ClearIcon onClick={() => onSelectionChange('')}>
                <X size={16} />
              </ClearIcon>
            )}
            <ChevronDown size={16} onClick={toggling} />
          </ClearableActionWrapper>
        </ClearableDropdownHeader>
      )}
      {isOpen && (
        <DropdownListContainer position={dropPosition}>
          <DropdownList role="menu">
            {options.map(option => {
              return (
                <ListItem
                  onClick={() => onOptionClicked(option)}
                  key={option.value}
                  selected={selectedOption?.value === option.value}
                >
                  <p role="menuitem">{option.label}</p>
                </ListItem>
              );
            })}
          </DropdownList>
        </DropdownListContainer>
      )}
    </DropdownContainer>
  );
};

Dropdown.Label = Label;

export { Dropdown };
