import styled from 'styled-components/macro';
import { xsBody } from 'components/mixins';
import { Infotip } from 'components/ui-components';

export const ClassificationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Title = styled.p`
  ${props => xsBody(props.color)};
  font-weight: ${props => props.weight};
`;

export const ClassificationIcon = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ClassificationInfotip = styled(Infotip)`
  margin-left: 4px;
  margin-top: 2px;
`;
