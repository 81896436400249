import React from 'react';

import { PrimaryButton, SecondaryButton, TertiaryButton } from './elements';
import Label from './label';

interface ButtonProps {
  className?: string;
  buttonStyle?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactChild;
  dataTestId?: string;
}

interface ButtonInterface extends React.FunctionComponent<ButtonProps> {
  Label?: any;
}

const Button: ButtonInterface = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { buttonStyle, className, children, dataTestId, onClick } = props;

  const renderButton = () => {
    let renderButton = null;

    switch (buttonStyle) {
      case 'primary':
        renderButton = (
          <PrimaryButton className={className} ref={ref} onClick={onClick} data-testid={dataTestId}>
            {children}
          </PrimaryButton>
        );
        break;
      case 'secondary':
        renderButton = (
          <SecondaryButton className={className} ref={ref} onClick={onClick} data-testid={dataTestId}>
            {children}
          </SecondaryButton>
        );
        break;
      case 'tertiary':
        renderButton = (
          <TertiaryButton className={className} ref={ref} onClick={onClick} data-testid={dataTestId}>
            {children}
          </TertiaryButton>
        );
        break;
      default:
        renderButton = (
          <PrimaryButton className={className} ref={ref} onClick={onClick} data-testid={dataTestId}>
            {children}
          </PrimaryButton>
        );
        break;
    }

    return renderButton;
  };

  return renderButton();
});

Button.Label = Label;

export { Button };
