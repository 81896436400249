import styled from 'styled-components/macro';
import { body, microBody, xsBodyTall } from 'components/mixins';
import { palette } from 'components/styles';

const getTextStyle = style => {
  const textStyles = {
    BODY: body(palette.pureBlack),
    XS_BODY: xsBodyTall(palette.pureBlack),
    MICRO: microBody(palette.pureBlack)
  };

  return textStyles[style] ? textStyles[style] : textStyles['BODY'];
};

export const BulletList = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
`;

export const BulletItem = styled.li`
  p {
    ${props => getTextStyle(props.textStyle)};

    @media print {
      ${xsBodyTall(palette.pureBlack)};
    }
  }

  ul,
  li {
    ${xsBodyTall(palette.pureBlack)};
    line-height: 36px;

    @media print {
      ${xsBodyTall(palette.pureBlack)};
      line-height: 36px;
    }
  }

  a,
  a:visited {
    font-size: inherit;
    font-weight: 600;
    color: ${palette.brandBlue};
  }
`;
