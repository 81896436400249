import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import NotificationContext from 'context/notification-context';
import OrgContextProvider from 'context/org-context';

import Root from 'pages/root';
import { GlobalStyle } from 'components/ui-components';
import { palette } from 'components/styles';
import { SkeletonTheme } from 'react-loading-skeleton';

import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

import { SessionTimeout } from 'components/session-timeout';

import { IPublicClientApplication } from '@azure/msal-browser';
interface Props {
  instance: IPublicClientApplication;
}

const App: React.FC<Props> = ({ instance }) => {
  return (
    <>
      <GlobalStyle />
      <MsalProvider instance={instance}>
        <SessionTimeout />
        <AppInsightsContext.Provider value={reactPlugin}>
          <OrgContextProvider>
            <NotificationContext>
              <BrowserRouter>
                <SkeletonTheme color={palette.gray3} highlightColor={palette.gray4}>
                  <Root />
                </SkeletonTheme>
              </BrowserRouter>
            </NotificationContext>
          </OrgContextProvider>
        </AppInsightsContext.Provider>
      </MsalProvider>
    </>
  );
};

export default App;
