import React from 'react';
import styled from 'styled-components/macro';

import { Container } from 'components/ui-components';
import PrintProspectHeader from 'components/prospect-profile/print-prospect-header';
import PrintProspectBio from 'components/prospect-profile/print-prospect-bio';
import PrintProspectInsights from 'components/prospect-profile/print-prospect-insights';
import PrintProspectNews from 'components/prospect-profile/print-prospect-news';
import PrintProspectGiving from 'components/prospect-profile/print-prospect-giving';
import PrintProspectConnections from 'components/prospect-profile/print-prospect-connections';
import PrintProspectHighlights from 'components/prospect-profile/print-prospect-highlights';

export const ProspectDetailPrintPage = props => {
  const { componentRef, prospect } = props;

  const renderNewsSection = () => {
    if (prospect.articles && prospect.articles.length > 0) {
      return <PrintProspectNews prospect={prospect} />;
    } else if (prospect.videos && prospect.videos.length > 0) {
      return <PrintProspectNews prospect={prospect} />;
    }
    return null;
  };

  const renderConnectionsSection = () => {
    if (prospect.mutualConnections && prospect.mutualConnections.length > 0) {
      return <PrintProspectConnections prospect={prospect} />;
    }
    return null;
  };

  return (
    <>
      <PageWrapper ref={componentRef}>
        <PrintProspectHeader prospect={prospect} />
        <LayoutContainer>
          <PrintProspectHighlights prospect={prospect} />
          <PrintProspectBio prospect={prospect} />
          {renderConnectionsSection()}
          <GivingWrapper>
            <PrintProspectGiving prospect={prospect} />
          </GivingWrapper>
          <PrintProspectInsights prospect={prospect} />
          {renderNewsSection()}
        </LayoutContainer>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  flex: 1;
`;

const GivingWrapper = styled.div`
  page-break-after: always;
`;

const LayoutContainer = styled(Container)`
  position: relative;
  padding: 0 32px !important;
`;

export default ProspectDetailPrintPage;
