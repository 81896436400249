import React from 'react';
import { Video } from './elements';

interface BasicTileVideoProps {
  className: string;
  children: React.ReactChild;
  src: string;
}

export const BasicTileVideo: React.FC<BasicTileVideoProps> = ({ className, children, ...restProps }) => {
  return <Video src={restProps.src}>{children}</Video>;
};

export default BasicTileVideo;
