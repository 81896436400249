import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import ProspectSection from 'components/prospect-profile/prospect-section';
import { FadeIn } from 'components/ui-components';
import { BioContent, BioTitle, BioParagraph } from './elements';

const ProspectBio = props => {
  const { id, prospect } = props;
  const appInsights = useAppInsightsContext();
  const trackBioLink = useTrackEvent(appInsights, 'PROFILE | Link Click', prospect);

  const renderBioSection = () => {
    let renderBio;
    if (prospect.bio && prospect.bio !== '') {
      renderBio = (
        <>
          <BioTitle>{`Biography`}</BioTitle>
          <BioParagraph>
            <ReactMarkdown
              linkTarget="_blank"
              components={{
                a({ children, ...props }) {
                  return (
                    <a {...props} onClick={() => trackBioLink({ linkType: 'BIO' })}>
                      {children}
                    </a>
                  );
                }
              }}
            >
              {prospect.bio}
            </ReactMarkdown>
          </BioParagraph>
        </>
      );
    }

    return renderBio;
  };

  return (
    <>
      <ProspectSection id={id}>
        <FadeIn>
          <BioContent>{renderBioSection()}</BioContent>
        </FadeIn>
      </ProspectSection>
    </>
  );
};

export default ProspectBio;
