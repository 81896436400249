import ProspectRaise from 'models/ProspectRaise/ProspectRaise';
import moment from 'moment';

class Prospect {
  id: string;
  alignmentRank: number;
  donorClassification: string;
  onWatchlist: boolean;
  photo: string;
  alignment: string;
  alignmentReason: string;
  firstPublishedEpoch: number;
  lastPublishedEpoch: number;
  donor: any;
  raises: ProspectRaise[];
  raiseNames: string;

  constructor(data: any) {
    const { donor, raises } = data;
    this.id = data.id;
    this.photo = data?.donor?.photo ?? null;
    this.alignmentRank = data.alignment_rank ?? 0;
    this.donorClassification = data.donor_classification ? data.donor_classification.toUpperCase() : 'DEFAULT';
    this.onWatchlist = data.on_users_watchlist;
    this.alignment = data?.donor_alignment ?? 'Unknown';
    this.alignmentReason = data?.donor_alignment_reason ?? 'Reason Unknown';
    this.firstPublishedEpoch = data?.first_published_epoch ?? 0;
    this.lastPublishedEpoch = data?.last_published_epoch ?? 0;
    this.raiseNames = data?.raiseNames;
    this.raises = [];
    if (raises && raises.length > 0) {
      this.raises = raises.map((data: any) => {
        return new ProspectRaise(
          data.org_raise_id,
          data.donor_alignment,
          data.donor_alignment_reason,
          data.highlights,
          data.priority,
          data.prorated_ask_amount,
          data.single_ask_amount,
          data.raise_name
        );
      });
    }

    if (donor) {
      this.donor = {
        city: donor.city ?? null,
        employer: donor.employer ?? null,
        first_name: donor.first_name ?? 'John',
        middle_name: donor.middle_name ?? null,
        last_name: donor.last_name ?? 'Doe',
        nickname: donor.nickname ?? null,
        occupation: donor.occupation ?? null,
        state: donor.state ?? null,
        zip_code: donor.zip_code ?? null
      };
    }
  }

  get FullName(): string {
    return `${this.donor.first_name}${this.donor.nickname ? ` "${this.donor.nickname}"` : ''}${
      this.donor.middle_name ? ` ${this.donor.middle_name}` : ''
    } ${this.donor.last_name}`;
  }

  getFormattedJobTitleEmployer() {
    let formattedJobDesc = ``;

    if (this.donor.employer && this.donor.occupation) {
      formattedJobDesc = `${this.donor.occupation}, ${this.donor.employer}`;
    } else if (this.donor.employer || this.donor.occupation) {
      formattedJobDesc = `${this.donor.occupation}` || `${this.donor.employer}`;
    }

    return formattedJobDesc;
  }

  getFormattedLocation() {
    let formattedLocation = ``;

    if (this.donor.city && this.donor.state) {
      formattedLocation = `${this.donor.city}, ${this.donor.state}`;
    } else if (this.donor.city || this.donor.state) {
      formattedLocation = `${this.donor.city}` || `${this.donor.state}`;
    }

    return formattedLocation;
  }

  getDonorInitials() {
    const firstInitial = this.donor.first_name ? this.donor.first_name.substring(0, 1) : '';
    const lastInitial = this.donor.last_name ? this.donor.last_name.substring(0, 1) : '';

    return firstInitial.concat(lastInitial);
  }

  getFormattedDate(unformattedDate: number) {
    return moment(unformattedDate, 'YYYY-MM-DD').format('MMM Do, YYYY');
  }

  getFormattedPercentage(value: number, factor: number) {
    const rawPercent = (value / factor) * 100;
    return rawPercent.toFixed(1);
  }

  getFormattedDollars(unformattedVal: number | string) {
    // Nine Zeroes for Billions
    return unformattedVal === 0
      ? '--'
      : Math.abs(Number(unformattedVal)) >= 1.0e9
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e9).toFixed(2)) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(unformattedVal)) >= 1.0e6
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e6).toFixed(2)) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(unformattedVal)) >= 1.0e3
      ? parseFloat((Math.abs(Number(unformattedVal)) / 1.0e3).toFixed(2)) + 'K'
      : Math.abs(Number(unformattedVal));
  }

  getDonorRaiseDataByRaiseId(raiseId: string): ProspectRaise {
    const foundRaise = this.raises.find((raise: ProspectRaise) => raise.raiseId === raiseId);

    return foundRaise ?? new ProspectRaise();
  }
}

export default Prospect;
