import React from 'react';

const SvgPrinter = ({ fill = '#007CFF', stroke = '#FFFFFF' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={fill} />
      <path
        d="M6.3335 8.08333V4H13.3335V8.08333"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.4 13.3335H5.2C4.88174 13.3335 4.57652 13.2106 4.35147 12.9918C4.12643 12.773 4 12.4762 4 12.1668V9.25016C4 8.94074 4.12643 8.644 4.35147 8.42521C4.57652 8.20641 4.88174 8.0835 5.2 8.0835H14.8C15.1183 8.0835 15.4235 8.20641 15.6485 8.42521C15.8736 8.644 16 8.94074 16 9.25016V12.1668C16 12.4762 15.8736 12.773 15.6485 12.9918C15.4235 13.2106 15.1183 13.3335 14.8 13.3335H13.6"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 11.1665H6.5V15.8332H13.5V11.1665Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SvgPrinter };
