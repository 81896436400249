import React from 'react';
import { getOrdinalSuffix } from 'utils';
import { LeftColumn, ConnectionsNameList, ConnectionChainListItem } from './elements';
import { ChevronRight } from 'react-feather';
interface PeopleListProps {
  prospect?: any;
  activeChainIndex?: number;
  onActiveChainIndexClick?: (index: number, type: 'PEOPLE') => void;
}

const PeopleList: React.FC<PeopleListProps> = props => {
  const { prospect, activeChainIndex, onActiveChainIndexClick } = props;
  const { mutualConnections } = prospect;

  const renderConnectionChainList = () => {
    return (
      <ConnectionsNameList>
        {mutualConnections.map((connectionChain: any, index: number) => {
          const firstLink = connectionChain.links[0] ? connectionChain.links[0] : null;
          const isActive = activeChainIndex === index;
          if (firstLink) {
            return (
              <ConnectionChainListItem active={isActive} key={index}>
                <button
                  onClick={event => {
                    onActiveChainIndexClick && onActiveChainIndexClick(index, 'PEOPLE');
                  }}
                >
                  <div>
                    <p>{firstLink.name}</p>
                    <p>{`${getOrdinalSuffix(connectionChain.links.length)} degree connection`}</p>
                  </div>
                  <div>
                    <ChevronRight size={18} color="black" />
                  </div>
                </button>
              </ConnectionChainListItem>
            );
          }
          return null;
        })}
      </ConnectionsNameList>
    );
  };

  return (
    <>
      <LeftColumn>{renderConnectionChainList()}</LeftColumn>
    </>
  );
};

export default PeopleList;
