import React, { forwardRef } from 'react';
import { Wrapper } from './elements';

interface ContainerProps {
  id?: string;
  children: any;
  className?: string;
  wide?: boolean;
  type?: string;
}

const Container: React.FC<ContainerProps> = forwardRef((props: ContainerProps, ref: React.Ref<HTMLDivElement>) => {
  const { id, children, className, wide } = props;

  return (
    <Wrapper id={id} className={className} ref={ref} wide={wide}>
      {children}
    </Wrapper>
  );
});

Container.displayName = 'Container';

export { Container };
