import React, { useState, useContext } from 'react';
import { NotificationContext } from 'context/notification-context';
import { postWatchlistToggle } from 'services/services';
import { useMsalUserManager } from '../../hooks';
import { SvgPlus, SvgMinus } from 'components/svg-components';
import { palette } from 'components/styles';
import { Loader } from 'components/ui-components';
import { ToggleButton, ToggleLabelWrapper, ToggleLabel, IconWrapper } from './elements';

const WatchlistToggle = props => {
  const {
    className,
    prospect,
    matchId,
    displayLabel = false,
    offFill = palette.pureWhite,
    offStroke = palette.pureBlack,
    loadStroke = palette.pureBlack,
    onFill = palette.brandBlue,
    onStroke = palette.pureWhite
  } = props;
  const { user, instance } = useMsalUserManager();
  const [watchToggle, setWatchToggle] = useState(() => prospect.onWatchlist);
  const [loading, setLoading] = useState(false);
  const { addNewNotification } = useContext(NotificationContext);

  const dispatchNotification = toggle => {
    if (toggle) {
      addNewNotification({
        title: 'Watchlist Updated',
        description: 'Donor successfully added to watchlist.',
        type: 'success'
      });
    } else {
      addNewNotification({
        title: 'Watchlist Updated',
        description: 'Donor successfully removed from watchlist.',
        type: 'info'
      });
    }
  };

  const toggleClickedHandler = async event => {
    event.preventDefault();
    const newToggle = !watchToggle;
    setLoading(true);

    setTimeout(async () => {
      try {
        const results = await postWatchlistToggle(user, instance, matchId, newToggle);

        if (results) {
          setWatchToggle(newToggle);
          setLoading(false);
          dispatchNotification(newToggle);
        }
      } catch (error) {
        const my_error = error;
        addNewNotification({
          type: 'error',
          title: my_error.title,
          description: my_error.detail
        });
        setLoading(false);
      }
    }, 450);
  };

  return (
    <ToggleButton
      dataTestId={`watchlist-toggle-${watchToggle}`}
      className={className}
      onClick={toggleClickedHandler}
      disabled={loading}
    >
      <IconWrapper>
        {!loading && !watchToggle && <SvgPlus stroke={offStroke} fill={offFill} />}
        {loading && <Loader size={18} color={loadStroke} />}
        {!loading && watchToggle && <SvgMinus stroke={onStroke} fill={onFill} />}
      </IconWrapper>

      {displayLabel && (
        <ToggleLabelWrapper>
          <ToggleLabel visible={!watchToggle}>Add to My Watchlist</ToggleLabel>
          <ToggleLabel visible={watchToggle}>Remove from Watchlist</ToggleLabel>
        </ToggleLabelWrapper>
      )}
    </ToggleButton>
  );
};

WatchlistToggle.propTypes = {};

export default WatchlistToggle;
