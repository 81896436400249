import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Container } from 'components/ui-components';

export const MotionErrorWrapper = styled(motion.div)`
  position: fixed;
  overflow: visible;
  top: 0;
  left: 0;
  padding-top: 16px;
  background-color: transparent;
  z-index: 30;
`;

export const ErrorContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
`;
