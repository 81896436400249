import { palette } from 'components/styles';
import React from 'react';

interface SvgArrowDownProps {
  fill?: string;
}

const SvgArrowDown = (props: SvgArrowDownProps) => {
  const { fill = palette.pureWhite } = props;
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5L5 5.5L10 0.5H0Z" fill={fill} />
    </svg>
  );
};

export { SvgArrowDown };
