import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';

import { Checkbox } from 'components/ui-components/checkbox';
import {
  ClearSelectionsWrapper,
  ClearButton,
  MultiSelectWrapper,
  MultiSelectHeaderButton,
  MultiSelectListContainer,
  MultiSelectList,
  CheckboxListItem,
  CheckboxWrapper
} from './elements';
import { useOuterClick } from 'utils';

type Option = {
  key: string;
  label: string;
  value: string;
};

interface MultiSelectProps {
  id: string;
  className: string;
  options: Array<Option>;
  checkedValues: string;
  clearSelectionHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selectionHandler: (itemKey: string) => void;
  dropPosition?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = props => {
  const {
    id,
    className,
    options,
    checkedValues,
    clearSelectionHandler,
    selectionHandler,
    dropPosition = 'RIGHT'
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuHandler = () => {
    setIsOpen(!isOpen);
  };

  const renderSelectionText = () => {
    return checkedValues.length > 0 ? `${checkedValues.length} selected` : 'Select all that apply';
  };

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  return (
    <MultiSelectWrapper id={id} className={className} ref={innerRef}>
      <MultiSelectHeaderButton aria-expanded={isOpen} onClick={toggleMenuHandler} opened={isOpen}>
        <p>{renderSelectionText()}</p>
        <ChevronDown size={16} />
      </MultiSelectHeaderButton>
      {isOpen && (
        <MultiSelectListContainer position={dropPosition}>
          <div>
            <MultiSelectList role="menu">
              {options.map((option, index) => {
                return (
                  <CheckboxListItem key={option.key}>
                    <CheckboxWrapper key={option.key}>
                      <Checkbox
                        checked={checkedValues.includes(option.key)}
                        onChange={() => selectionHandler(option.key)}
                        key={option.key}
                      ></Checkbox>
                      <Checkbox.Label key={'l' + index}>{option.label}</Checkbox.Label>
                    </CheckboxWrapper>
                  </CheckboxListItem>
                );
              })}
            </MultiSelectList>
            <ClearSelectionsWrapper>
              <ClearButton
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => clearSelectionHandler(event)}
              >
                Clear All
              </ClearButton>
            </ClearSelectionsWrapper>
          </div>
        </MultiSelectListContainer>
      )}
    </MultiSelectWrapper>
  );
};

export { MultiSelect };
