import React, { useState } from 'react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import ProspectSection from 'components/prospect-profile/prospect-section';
import ConnectionsConfig from './connections-config';
import { ConnectionsTileContainer, ComponentContent, WrapperProspectSection, TileWrapper, Badge } from './elements';
import { FadeIn } from 'components/ui-components';
import EmptyState from './empty-state';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { breakpoints } from 'components/styles/breakpoints';
import ProspectProfile from 'models/ProspectProfile/ProspectProfile';
import { Tooltip } from 'react-tooltip';
import { palette } from 'components/styles';

interface ProspectConnectionsProps {
  id: string;
  prospect: ProspectProfile;
}

const ProspectConnections: React.FC<ProspectConnectionsProps> = props => {
  const { id, prospect } = props;
  const { mutualConnections } = prospect;
  const appInsights = useAppInsightsContext();
  const trackViewPersonConnection = useTrackEvent(appInsights, 'PROFILE | View Person Connection', { connection: {} });
  const { browserWidth } = useWindowDimensions();
  const isMobileView =
    browserWidth && browserWidth < parseInt(breakpoints['tablet-lg'].substring(0, breakpoints['tablet-lg'].length - 2));
  const [connectionIndex, setConnectionIndex] = useState(isMobileView ? -1 : 0);

  const onClickConnection = (index: number) => {
    if (mutualConnections?.length) {
      trackViewPersonConnection({ connection: mutualConnections[index] });
    }
    setConnectionIndex(index);
  };

  const renderActiveTab = () => {
    let render = false;

    if (mutualConnections?.length) {
      render = mutualConnections?.length > 0;
    }

    return render;
  };

  return (
    <WrapperProspectSection id={id} className={id}>
      <ProspectSection.Title className={id}>
        {`Connections`}
        <sup>
          <a
            data-tooltip-id="beta-header-tooltip"
            data-tooltip-content="This feature is experimental and still under development. Client usage and feedback is needed to understand how much value this feature adds to the platform. Please give us feedback on how well it works and how it might be improved!"
            data-tooltip-place="top"
          >
            <Badge>BETA</Badge>
          </a>
        </sup>
        <Tooltip
          id="beta-header-tooltip"
          style={{
            backgroundColor: palette.brandDarkBlue,
            width: 'calc(100% + 16px)',
            fontSize: '14px',
            lineHeight: '1',
            textAlign: 'left',
            color: palette.pureWhite,
            padding: '24px',
            fontFamily: 'sans-serif',
            transition: 'all 200ms ease-in',
            borderRadius: '10px',
            opacity: '1',
            zIndex: '50',
            maxWidth: '400px'
          }}
        />
      </ProspectSection.Title>
      <ProspectSection.SubTitle
        className={id}
      >{`People affiliated with your organization that might have something or someone in common with ${prospect.PreferredFirstName}.`}</ProspectSection.SubTitle>
      <FadeIn id="connections-content">
        <TileWrapper>
          <ConnectionsTileContainer>
            <ComponentContent>
              {renderActiveTab() ? (
                <>
                  {React.cloneElement(ConnectionsConfig['PEOPLE'].listComponent, {
                    prospect: prospect,
                    activeChainIndex: connectionIndex,
                    onActiveChainIndexClick: onClickConnection
                  })}
                  {React.cloneElement(ConnectionsConfig['PEOPLE'].connectionsComponent, {
                    prospect: prospect,
                    activeChainIndex: connectionIndex
                  })}
                </>
              ) : (
                <EmptyState connectionType="people" prospect={prospect} />
              )}
            </ComponentContent>
          </ConnectionsTileContainer>
        </TileWrapper>
      </FadeIn>
    </WrapperProspectSection>
  );
};

export default ProspectConnections;
