import React, { useRef } from 'react';

import { PrintButton } from './elements';
import ReactToPrint from 'react-to-print';
import ProspectDetailPrintPage from 'pages/prospect-detail-print-page';
import { SvgPrinter } from 'components/svg-components';
import { palette } from 'components/styles';

export const PrintProspect = props => {
  const { prospect, displayLabel = false } = props;
  const componentRef = useRef();
  return (
    <>
      <ReactToPrint
        trigger={() => {
          return (
            <PrintButton>
              <SvgPrinter stroke={palette.pureWhite} fill={palette.brandBlue} />
              <p>{displayLabel ? 'Print' : ''}</p>
            </PrintButton>
          );
        }}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }}>
        <ProspectDetailPrintPage componentRef={componentRef} prospect={prospect} />
      </div>
    </>
  );
};

PrintProspect.propTypes = {};

export default PrintProspect;
