import { css } from 'styled-components/macro';
import { colors } from 'components/styles';

export const atomic = color => {
  return css`
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: ${color};
  `;
};

export const nanoBody = color => {
  return css`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: ${color};
  `;
};

export const microBody = color => {
  return css`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${color};
  `;
};

export const xsBodyTall = color => {
  return css`
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: ${color};
  `;
};

export const xsBody = color => {
  return css`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${color};
  `;
};

export const body = color => {
  return css`
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: ${color};
  `;
};

export const bodyShort = color => {
  return css`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${color};
  `;
};

export const lgBody = color => {
  return css`
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: ${color};
  `;
};

export const headline = color => {
  return css`
    font-family: 'Effra';
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: ${color};
  `;
};

export const xlHeading = color => {
  return css`
    font-family: 'Barlow';
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
    color: ${color};
  `;
};

export const jumboHeading = color => {
  return css`
    font-family: 'Barlow';
    font-size: 64px;
    font-weight: bold;
    line-height: 72px;
    color: ${color};
  `;
};

export const lgHeading = css`
  color: ${colors.gray1};
  font-size: 44px;
  font-weight: 800;
  line-height: 55px;
`;

export const pageHeading = css`
  color: ${colors.gray1};
  font-size: 36px;
  font-weight: bold;
  line-height: 45px;
`;

export const pageSubHeading = css`
  color: ${colors.gray4};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const sectionHeading = css`
  color: ${colors.gray1};
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
`;

export const sectionSubHeading = css`
  color: ${colors.gray1};
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
`;

export const bodyContentLight = css`
  color: ${colors.gray3};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

export const bodyContentDark = css`
  color: ${colors.gray1};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

export const tileSubHeading = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;
