import styled from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';
import { BasicTile } from 'components/ui-components';

export const ArticleGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  > div {
    flex: 100%;
  }

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    > div {
      flex: 0 0 50%;
    }
  }
`;

export const BasicTileDateWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-between;

  p:first-child {
    flex: 1;
  }
  p:last-child {
    whitespace: no-wrap;
  }
`;

export const TileLink = styled.a`
  display: block;
  flex: 1;
  text-decoration: none;
  padding-bottom: 16px;
  margin: 12px;
  border-bottom: ${props => (props.border ? `1px solid ${palette.gray3}` : 'none')};
`;

export const ArticleSection = styled.div`
  margin-top: 48px;
`;

export const ArticleSnippet = styled(BasicTile.Description)`
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
