import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { motion } from 'framer-motion';
import { xsBody } from 'components/mixins';

export const TextUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 0;
`;

export const TextUnorderedListItem = styled(motion.li)`
  padding: 16px 0;
  border-bottom: 1px solid ${palette.gray3};
  max-width: 100%;
  min-width: 0;
`;

export const TextUnorderedListText = styled.p`
  ${xsBody(palette.pureBlack)}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
