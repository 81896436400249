import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import times from 'lodash/times';
import { ChevronLeft, ChevronRight, Search, Grid, AlignJustify } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { SORT_OPTIONS, SORT_DIR, FILTER_LIST } from 'constants/prospect-filters';
import { Container, IconInput, Dropdown, IconToggle } from 'components/ui-components';
import { palette, breakpoints } from 'components/styles';
import {
  PageWrapper,
  FilterControlsWrapper,
  FilterControlsContainer,
  FilterControls,
  PaginationControlsWrapper,
  PaginationControls,
  PaginationControl,
  PaginationPage,
  SortWrapper,
  MobileSortDropdown,
  ProspectListContainer,
  LogoWrapper,
  LogoCover,
  MotionLogo,
  MotionLine,
  FancyText,
  WelcomeContainer,
  ListTypeControlsWrapper
} from './elements';
import ProspectListFilters from 'components/prospect-list-filters';
import { useMsalUserManager, useWindowDimensions } from '../../hooks';
import { searchProspectsWithFilters } from 'services/services';
import Prospect from 'models/Prospect/Prospect';
import ProspectCardList from 'components/prospect-card-list';
import ProspectHorizontalList from 'components/prospect-horizontal-list';
import { SvgSort } from 'components/svg-components';
import { OrgContext } from 'context/org-context';
import { NotificationContext } from 'context/notification-context';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { getOrg } from 'services';
import { SvgLogoAlt } from 'components/svg-components';
import { motion } from 'framer-motion';
import { addCriteriaToUserPrefs, getCriteriaFromUserPrefs } from 'services/utilities';

const standardPageSize = '100';
const defaultSort = 'last_name--asc';

const generateSkeletonList = () => {
  const skeletonList = [];

  times(8, i => {
    skeletonList.push({ loading: true, id: i.toString() });
  });

  return skeletonList;
};

const initializeFilters = (location, raises) => {
  let baseOptions = [...FILTER_LIST];
  const urlParams = new URLSearchParams(location.search);

  baseOptions = baseOptions.map(optionGroup => {
    const newGroup = { ...optionGroup };
    let paramValues = urlParams.get(optionGroup.url_property);
    paramValues = paramValues ? paramValues.split(',') : [];
    newGroup.checkedValues = paramValues;

    return newGroup;
  });

  if (raises && raises.length > 0) {
    const raiseOptions = getRaisesFilter(raises.map(raise => ({ id: raise.raiseId, name: raise.raise })));
    baseOptions.splice(1, 0, raiseOptions);
  }

  return baseOptions;
};

const initializeSearch = location => {
  const urlParams = new URLSearchParams(location.search);
  const keyword = urlParams.get('searchTerm');

  return keyword ? keyword : '';
};

const getSortKey = (term, direction) => {
  const sortKey = direction === 'Descending' ? `${term}--dsc` : `${term}--asc`;
  return sortKey;
};

const initializeSort = location => {
  const urlParams = new URLSearchParams(location.search);
  const initOptions = [...SORT_OPTIONS];
  const sortTerm = urlParams.get('orderBy');
  const sortDir = urlParams.get('orderDirection');
  const sortKey = getSortKey(sortTerm, sortDir);
  const initialSort = find(initOptions, { value: sortKey });

  return initialSort ? initialSort.value : defaultSort;
};

const initializeCriteria = (location, orgId) => {
  if (orgId) {
    const storedCriteria = getCriteriaFromUserPrefs(orgId);
    if (storedCriteria) {
      return storedCriteria;
    }
  }
  const newCriteria = new SearchCriteria();
  newCriteria.setLocationSearchCriteria(location);
  return newCriteria;
};

const initializeListType = location => {
  const urlParams = new URLSearchParams(location.search);
  const defaultType = 'LIST';
  const currentType = urlParams.get('listType');

  return currentType ? currentType : defaultType;
};

const getRaisesFilter = raises => {
  const raiseOptions = {
    type: 'MULTI',
    label: 'Raises',
    url_property: 'raisesFilter',
    checkedValues: [],
    checks: raises.map(raise => ({ key: raise.id, label: raise.name }))
  };
  return raiseOptions;
};

class SearchCriteria {
  constructor() {
    this.pageNumber = '1';
    this.pageSize = standardPageSize;
    this.orderBy = 'last_name';
    this.orderDirection = 'Ascending';
  }

  setLocationSearchCriteria(location) {
    const urlParams = new URLSearchParams(location.search);

    urlParams.forEach((value, key) => {
      if (!isEmpty(value)) {
        this[key] = value;
      }
    });
  }
}

const ProspectListPage = props => {
  const history = useHistory();
  const location = useLocation();
  const debouncedFunctionRef = useRef();
  const { organizationRaises, initializeOrg, org } = useContext(OrgContext);
  const { addNewNotification } = useContext(NotificationContext);
  const appInsights = useAppInsightsContext();
  const { user, instance } = useMsalUserManager();
  const { browserWidth } = useWindowDimensions();
  const [prospectResults, setProspectResults] = useState({ loading: true, data: [...generateSkeletonList()] });
  const [pagination, setPagination] = useState({ totalCount: 0, activePage: 0, totalPages: 0, continuationToken: '' });
  const [sortValue, setSortValue] = useState(() => initializeSort(location));
  const [searchVal, setSearchVal] = useState(() => initializeSearch(location));
  const [filters, setFilters] = useState(() => initializeFilters(location, organizationRaises));
  const [searchCriteria, setSearchCriteria] = useState(() => null);
  const [newSearchCriteria, setNewSearchCriteria] = useState(() => initializeCriteria(location, org.id));
  const trackSortSearchFilter = useTrackEvent(appInsights, 'LIST | Sort, Filter, and/or Search');
  const sortOptions = [...SORT_OPTIONS];
  const [fetchingOrg, setFetchingOrg] = useState(false);
  const [welcomeComplete, setWelcomeComplete] = useState(organizationRaises && organizationRaises.length > 0);
  const [listType, setListType] = useState(() => initializeListType(location));
  const abortControllerRef = useRef(null);

  const fade = {
    initial: { y: '100%', opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.5, delay: 1 }
  };

  const expand = {
    initial: { width: 0 },
    animate: { width: '95%' },
    transition: { duration: 0.5, delay: 1.5 }
  };

  const appear = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 1, delay: 3 }
  };

  useEffect(() => {
    const getOrgInfo = async () => {
      try {
        const org = await getOrg(user, instance);
        if (org && org?.data) {
          initializeOrg(org.data);
          setNewSearchCriteria(initializeCriteria(location, org.data.id));
          if (org.data.raises.length > 0) {
            const raiseFilter = getRaisesFilter(org.data.raises);
            setFilters(filters =>
              filters.some(f => f.url_property === raiseFilter.url_property)
                ? filters
                : filters.toSpliced(1, 0, raiseFilter)
            );
          }
        }
        setFetchingOrg(false);
      } catch (error) {
        const my_error = error;
        setFetchingOrg(false);
        addNewNotification({
          type: 'error',
          title: my_error.title,
          description: my_error.detail
        });
      }
    };
    if (organizationRaises.length <= 0 && !fetchingOrg) {
      setFetchingOrg(true);
      getOrgInfo();
    }
  }, [organizationRaises, instance, user, fetchingOrg, addNewNotification, initializeOrg, location]);

  debouncedFunctionRef.current = (newCriteria, action) => {
    trackSortSearchFilter({ criteria: newCriteria, action: action });
    setNewSearchCriteria(newCriteria);
  };

  const urlSearcher = useCallback(
    (criteria, type = 'push') => {
      const urlParams = new URLSearchParams(criteria);

      const deleteParams = [];

      // Remove empty searchParams
      for (const param of urlParams) {
        if (param[1] === '') deleteParams.push(param[0]);
      }
      for (const name of deleteParams) {
        urlParams.delete(name);
      }

      if (type === 'replace') {
        history.replace({ search: urlParams.toString() });
      } else {
        history.push({ search: urlParams.toString() });
      }
    },
    [history]
  );

  const getProspectList = useCallback(
    async (criteria, continuationToken, instance, user, abortSignal) => {
      try {
        const results = await searchProspectsWithFilters(user, instance, continuationToken, criteria, abortSignal);
        const totalPages = Math.ceil(results.data.total_count / standardPageSize);
        const prospects = results.data.results.map(prospect => {
          const p = new Prospect(prospect);
          return p;
        });
        setProspectResults({ loading: false, data: prospects });
        setPagination({
          totalPossible: results.data.total_possible,
          totalCount: results.data.total_count,
          activePage: parseInt(criteria.pageNumber),
          totalPages: totalPages,
          continuationToken: results.data.continuationToken
        });
        setSearchCriteria({ ...criteria });
      } catch (error) {
        const my_error = error;
        addNewNotification({
          type: 'error',
          title: my_error.title,
          description: my_error.detail
        });
        setProspectResults({ loading: false, data: [] });
      }
    },
    [addNewNotification]
  );

  useEffect(
    () => {
      const applyContinuation = newSearchCriteria.pageNumber === pagination.activePage + 1;
      const continuationToken = applyContinuation ? pagination.continuationToken : null;

      const updateFilters = () => {
        const allFilters = initializeFilters(location, organizationRaises);
        const updatedFilters = allFilters.map(filter => {
          const currentFilter = { ...filter };
          const criteriaValue = newSearchCriteria[currentFilter.url_property];
          if (criteriaValue && criteriaValue.length > 0) {
            if (Array.isArray(criteriaValue)) {
              currentFilter.checkedValues = criteriaValue;
            } else if (
              Array.isArray(currentFilter.checkedValues) &&
              !currentFilter.checkedValues.some(f => f === criteriaValue)
            ) {
              currentFilter.checkedValues.push(criteriaValue);
            } else {
              currentFilter.checkedValues = [criteriaValue];
            }
          }
          return currentFilter;
        });
        setFilters(updatedFilters);
      };

      if (prospectResults.loading && JSON.stringify(newSearchCriteria) !== JSON.stringify(searchCriteria)) {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        urlSearcher(newSearchCriteria, 'replace');
        getProspectList(newSearchCriteria, continuationToken, instance, user, abortControllerRef.current.signal);
        if (org?.id) {
          addCriteriaToUserPrefs(newSearchCriteria, org.id);
          const sortKey = getSortKey(newSearchCriteria.orderBy, newSearchCriteria.orderDirection);
          setSortValue(sortKey);
          updateFilters();
        }
      }
    },
    // ADDED | To prevent redundant calls when the "user" object is added as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      newSearchCriteria,
      searchCriteria,
      pagination.activePage,
      pagination.continuationToken,
      prospectResults.loading,
      getProspectList,
      urlSearcher
    ]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pagination.activePage]);

  const debounceSearchWithSort = useMemo(() => debounce((...args) => debouncedFunctionRef.current(...args), 1), []);

  const onSortChange = selectedValue => {
    const [sortString, dirString] = selectedValue.split('--');
    const sortDirection = dirString === 'asc' ? SORT_DIR.asc : SORT_DIR.dsc;
    if (newSearchCriteria.orderDirection !== sortDirection || newSearchCriteria.orderBy !== sortString) {
      const newCriteria = new SearchCriteria();
      newCriteria.setLocationSearchCriteria(location);
      newCriteria.pageNumber = '1';
      newCriteria.orderBy = sortString;
      newCriteria.orderDirection = sortDirection;

      setPagination({ ...pagination, activePage: 1 });
      setSortValue(selectedValue);
      setProspectResults({ loading: true, data: [...generateSkeletonList()] });
      debounceSearchWithSort(newCriteria, 'SORT');
    }
  };

  const debounceSearchWithTerm = useMemo(() => debounce((...args) => debouncedFunctionRef.current(...args), 1000), []);

  const onSearchInputChanged = event => {
    const newCriteria = new SearchCriteria();
    newCriteria.setLocationSearchCriteria(location);
    const newValue = event ? event.target.value : '';
    newCriteria.pageNumber = '1';
    newCriteria.searchTerm = newValue;

    setSearchVal(newValue);
    setPagination({ ...pagination, activePage: 1 });
    setProspectResults({ loading: true, data: [...generateSkeletonList()] });
    debounceSearchWithTerm(newCriteria, 'SEARCH');
  };

  const debounceSearchWithFilters = useMemo(
    () => debounce((...args) => debouncedFunctionRef.current(...args), 1000),
    []
  );

  const toggleListTypeHandler = event => {
    const toggle = event.target.value;
    const urlParams = new URLSearchParams(searchCriteria);
    urlParams.set('listType', toggle);

    history.push({ search: urlParams.toString() });
    setListType(toggle);
  };

  const filterChangeHandler = (groupKey, value) => {
    const newFilters = [...filters];
    const newCriteria = new SearchCriteria();
    newCriteria.setLocationSearchCriteria(location);
    const groupIndex = findIndex(newFilters, { url_property: groupKey });
    const newChecks = [...newFilters[groupIndex].checkedValues];

    // Update Filter Checks
    if (newChecks.includes(value)) {
      remove(newChecks, i => i === value);
      newFilters[groupIndex].checkedValues = newChecks;
    } else {
      newChecks.push(value);
      newFilters[groupIndex].checkedValues = newChecks;
    }

    for (const filter of newFilters) {
      newCriteria[filter.url_property] = filter.checkedValues;
    }
    newCriteria.pageNumber = '1';

    setPagination({ ...pagination, activePage: 1 });
    setProspectResults({ loading: true, data: [...generateSkeletonList()] });
    setFilters(newFilters);
    debounceSearchWithFilters(newCriteria, 'FILTER');
  };

  const onAllFiltersClearedHandler = () => {
    if (filters.some(f => f.checkedValues && f.checkedValues.length > 0 && f.checkedValues[0] !== '')) {
      const newFilters = [...filters];
      const newCriteria = new SearchCriteria();
      const clearedFilters = newFilters.map(filter => ({ ...filter, checkedValues: [] }));

      setPagination({ ...pagination, activePage: 1 });
      setProspectResults({ loading: true, data: [...generateSkeletonList()] });
      setFilters(clearedFilters);
      debounceSearchWithFilters(newCriteria);
    }
  };

  const onFiltersClearedHandler = groupKey => {
    const newFilters = [...filters];
    const newCriteria = new SearchCriteria();
    newCriteria.setLocationSearchCriteria(location);
    const groupIndex = findIndex(newFilters, { url_property: groupKey });

    newFilters[groupIndex].checkedValues = [];
    newCriteria[groupKey] = [];

    setPagination({ ...pagination, activePage: 1 });
    setProspectResults({ loading: true, data: [...generateSkeletonList()] });
    setFilters(newFilters);
    debounceSearchWithFilters(newCriteria);
  };

  const paginationHandler = page => {
    if (page !== 0 && page <= pagination.totalPages) {
      const newCriteria = new SearchCriteria();
      newCriteria.setLocationSearchCriteria(location);
      newCriteria.pageNumber = page;

      setPagination({ ...pagination, activePage: page });
      setProspectResults({ loading: true, data: [...generateSkeletonList()] });
      debounceSearchWithFilters(newCriteria);
    }
  };

  const renderPaginationControls = () => {
    const { activePage, totalPages } = pagination;
    const lastPage = activePage + 2 > totalPages ? totalPages : activePage + 2;
    const middlePage = lastPage - 1 > 0 ? lastPage - 1 : null;
    const firstPage = lastPage - 2 > 0 ? lastPage - 2 : null;
    const pages = [firstPage, middlePage, lastPage];
    const prevEnabled = activePage - 1 !== 0;
    const nextEnabled = activePage + 1 <= totalPages;
    const pageItems = pages.map((page, index) => {
      return (
        page && (
          <PaginationPage key={index} active={page === activePage} onClick={() => paginationHandler(page)}>
            <p>{page}</p>
          </PaginationPage>
        )
      );
    });

    return (
      <PaginationControls>
        <PaginationControl enabled={prevEnabled} onClick={() => paginationHandler(activePage - 1)}>
          <ChevronLeft size={16} strokeWidth={1} />
        </PaginationControl>
        {pageItems}
        <PaginationControl enabled={nextEnabled} onClick={() => paginationHandler(activePage + 1)}>
          <ChevronRight size={16} strokeWidth={1} />
        </PaginationControl>
      </PaginationControls>
    );
  };

  const renderDonorPageCount = () => {
    const { activePage } = pagination;
    const rangeStart = (activePage - 1) * standardPageSize + 1;
    const rangeEnd = (activePage - 1) * standardPageSize + prospectResults.data.length;
    const renderCounts = <p>{`${rangeStart}-${rangeEnd} of ${pagination.totalCount} Prospects`}</p>;

    return prospectResults.loading ? <Skeleton width={150} /> : renderCounts;
  };

  return (
    <>
      {welcomeComplete && (
        <PageWrapper>
          <FilterControlsContainer>
            <IconInput
              value={searchVal}
              onInputChange={onSearchInputChanged}
              onInputClear={onSearchInputChanged}
              icon={<Search size={16} color={palette.brandBlueDark} />}
              placeholder={
                browserWidth < Number(breakpoints['tablet-sm'].replace('px', ''))
                  ? 'Search by name'
                  : 'Search for prospects by name'
              }
            />
            <FilterControls>
              <ProspectListFilters
                filterOptions={filters}
                onFilterChange={filterChangeHandler}
                onFilterClear={onFiltersClearedHandler}
                onAllFilterClear={onAllFiltersClearedHandler}
                totalCount={pagination.totalCount}
                searching={prospectResults.loading}
              />
              <MobileSortDropdown options={sortOptions} onChange={onSortChange} value={sortValue}>
                <SvgSort />
              </MobileSortDropdown>
            </FilterControls>
          </FilterControlsContainer>
          <Container>
            <FilterControlsWrapper>
              <div>{renderDonorPageCount()}</div>
              <SortWrapper>
                <Dropdown.Label>SORT BY: </Dropdown.Label>
                <Dropdown options={sortOptions} onSelectionChange={onSortChange} value={sortValue} />
              </SortWrapper>
            </FilterControlsWrapper>
          </Container>
          <Container>
            <ListTypeControlsWrapper>
              <IconToggle
                leftLabel="LIST"
                leftIcon={<AlignJustify size={20} />}
                rightLabel="CARD"
                rightIcon={<Grid size={20} />}
                toggleHandler={toggleListTypeHandler}
                activeToggle={listType}
              />
            </ListTypeControlsWrapper>
          </Container>
          <ProspectListContainer>
            {listType === 'LIST' && (
              <ProspectHorizontalList prospects={prospectResults.data} totalPossible={pagination.totalPossible} />
            )}
            {listType === 'CARD' && <ProspectCardList prospects={prospectResults.data} />}
          </ProspectListContainer>
          <Container>
            {pagination.totalCount > standardPageSize && (
              <PaginationControlsWrapper>{renderPaginationControls()}</PaginationControlsWrapper>
            )}
          </Container>
        </PageWrapper>
      )}
      {!welcomeComplete && (
        <motion.div key="logo" {...appear} transition={{ duration: 1, delay: 0 }}>
          <WelcomeContainer center={true}>
            <LogoWrapper>
              <MotionLogo {...fade}>
                <SvgLogoAlt fill={palette.navyBackground} />
              </MotionLogo>
              <MotionLine>
                <motion.div {...expand} />
              </MotionLine>
              <LogoCover />
            </LogoWrapper>
            <FancyText onAnimationComplete={() => setTimeout(() => setWelcomeComplete(true), 2000)} {...appear}>
              A SMARTER WAY TO DO GOOD{'\u00AE'}
            </FancyText>
          </WelcomeContainer>
        </motion.div>
      )}
    </>
  );
};

ProspectListPage.propTypes = {};

export default ProspectListPage;
