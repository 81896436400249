import React from 'react';
import styled from 'styled-components/macro';
import { breakpoints } from 'components/styles';

import { FadeIn } from 'components/ui-components';
import ProspectSection from 'components/prospect-profile/prospect-section';
import ProspectGivingTile from './prospect-giving-tile';
import ProspectEngagementTile from './prospect-engagement-tile';

const ProspectInsights = props => {
  const { id, prospect } = props;

  const conditionallyRenderEngagement = () => {
    const { assessment } = prospect;
    let renderEngagementTile = null;

    if (assessment.engagement && assessment.engagement.length > 0) {
      renderEngagementTile = (
        <FadeIn>
          <TileWrapper>
            <ProspectEngagementTile prospect={prospect} />
          </TileWrapper>
        </FadeIn>
      );
    }

    return renderEngagementTile;
  };

  return (
    <>
      <ProspectSection id={id}>
        <ProspectSection.Title>Highlighted Giving</ProspectSection.Title>
        <ProspectSection.SubTitle>{`Useful data that answers your most important questions about ${prospect.PreferredFirstName}.`}</ProspectSection.SubTitle>
        <InsightGrid>
          <FadeIn>
            <TileWrapper>
              <ProspectGivingTile prospect={prospect} />
            </TileWrapper>
          </FadeIn>
          {conditionallyRenderEngagement()}
        </InsightGrid>
      </ProspectSection>
    </>
  );
};

const InsightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin: 32px 0 56px 0;

  @media only screen and (min-width: ${breakpoints['tablet-md']}) {
    grid-template-columns: 1fr 1fr;
  }

  > div > div {
    display: flex;
    height: 100%;
  }
`;

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    max-width: 375px;
    margin-bottom: 16px;
  }

  > p {
    flex: 0 52px;
    max-width: 375px;
  }
`;

export default ProspectInsights;
