import { getEntitiesByUrl, postByUrl } from './utilities';
import { FILTER_LIST } from 'constants/prospect-filters';

const endpoints = {
  getProspectProfile: id => `${process.env.REACT_APP_DB_HOST}/api/profiles/${id}`,
  getOrgURL: () => `${process.env.REACT_APP_DB_HOST}/api/org`,
  searchProspectsURL: queryString => `${process.env.REACT_APP_DB_HOST}/api/profiles/list?${queryString}`,
  postWatchProspectsURL: queryString => `${process.env.REACT_APP_DB_HOST}/api/profiles/watch?${queryString}`,
  postUnwatchProspectsURL: queryString => `${process.env.REACT_APP_DB_HOST}/api/profiles/unwatch?${queryString}`,
  postHideProspectURL: queryString => `${process.env.REACT_APP_DB_HOST}/api/profiles/hide?${queryString}`,
  postRevealProspectURL: queryString => `${process.env.REACT_APP_DB_HOST}/api/profiles/unhide?${queryString}`
};

export const getProspectProfile = async (user, instance, prospectId) => {
  const entityUrl = endpoints.getProspectProfile(prospectId);
  return await getEntitiesByUrl(entityUrl, user, instance);
};

export const getOrg = async (user, instance) => {
  const entityUrl = endpoints.getOrgURL();
  return await getEntitiesByUrl(entityUrl, user, instance);
};

export const searchProspectsWithFilters = (user, instance, continuationToken, filtersObject, abortSignal) => {
  const { pageNumber, pageSize, searchTerm, orderBy, orderDirection, raisesFilter } = filtersObject;
  let entityUrl = '';
  const filterProperties = FILTER_LIST.map(group => group.url_property);
  const searchParams = new URLSearchParams();

  continuationToken && searchParams.set('ContinuationToken', continuationToken);
  searchTerm && searchParams.set('NamesFilter', searchTerm);
  pageNumber && searchParams.set('PageNumber', pageNumber);
  pageSize && searchParams.set('PageSize', pageSize);
  orderBy && searchParams.set('OrderByField', orderBy);
  orderDirection && searchParams.set('OrderByDirection', orderDirection);
  raisesFilter && searchParams.set('RaisesFilter', raisesFilter);

  filterProperties.forEach(prop => {
    if (filtersObject[prop] && filtersObject[prop].length > 0) {
      searchParams.set(prop, filtersObject[prop]);
    }
  });

  entityUrl = endpoints.searchProspectsURL(searchParams.toString());
  return getEntitiesByUrl(entityUrl, user, instance, abortSignal);
};

export const postWatchlistToggle = (user, instance, matchId, toggle) => {
  const searchParams = new URLSearchParams();
  matchId && searchParams.set('matchId', matchId);
  let entityUrl;

  if (toggle) {
    entityUrl = endpoints.postWatchProspectsURL(searchParams.toString());
  } else {
    entityUrl = endpoints.postUnwatchProspectsURL(searchParams.toString());
  }

  return postByUrl(entityUrl, user, instance);
};

export const postHideProspectToggle = (user, instance, matchId, toggle) => {
  const searchParams = new URLSearchParams();
  matchId && searchParams.set('matchId', matchId);
  let entityUrl;

  if (toggle) {
    entityUrl = endpoints.postHideProspectURL(searchParams.toString());
  } else {
    entityUrl = endpoints.postRevealProspectURL(searchParams.toString());
  }

  return postByUrl(entityUrl, user, instance);
};
