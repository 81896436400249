import React from 'react';
import { Description } from './elements';

interface BasicTileDescriptionProps {
  className: string;
  children: React.ReactChild;
  textStyle: string;
  fontWeight: string;
  textAlign: string;
}

const BasicTileDescription: React.FC<BasicTileDescriptionProps> = ({ className, children, ...restProps }) => {
  return (
    <Description
      className={className}
      textStyle={restProps.textStyle}
      fontWeight={restProps.fontWeight}
      textAlign={restProps.textAlign}
    >
      {children}
    </Description>
  );
};

export default BasicTileDescription;
