import React from 'react';
import { TileLink } from './elements';
import { ArrowRight } from 'react-feather';

interface BasicTileLinkProps {
  className: string;
  children: React.ReactChild;
  href: string;
  label: string;
  color: string;
}

const BasicTileLink: React.FC<BasicTileLinkProps> = ({ className, children, ...restProps }) => {
  const { href, label, color } = restProps;
  return (
    <TileLink to={href} color={color}>
      <strong>{label}</strong>
      <ArrowRight size={18} color={color} />
    </TileLink>
  );
};

export default BasicTileLink;
