import styled from 'styled-components/macro';
import { atomic, nanoBody } from 'components/mixins';
import { palette } from 'components/styles';
import { breakpoints } from 'components/styles';
import { colors } from 'components/styles';

export const LargeBadge = styled.p`
  ${nanoBody(colors.pureWhite)}
  background: ${palette.brandBlue};
  border-radius: 2px;
  padding: 4px 11px;
  height: 20px;
  width: 48px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (min-width: ${breakpoints['laptop']}) {
    margin: 0;
  }
`;

export const SmallBadge = styled.p`
  ${atomic(colors.pureWhite)}
  background: ${palette.brandBlue};
  border-radius: 2px;
  padding: 3px 5px;
  margin-left: 8px;
  font-weight: 700;
  width: 32px;
  height: 18px;
  display: flex;
  align-items: center;
`;
