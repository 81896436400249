import React from 'react';
import { StyledTile } from './elements';
import BasicTileContent from './content';
import BasicTileDescription from './description';
import BasicTileHeadline from './headline';
import BasicTileImg from './img';
import BasicTileLink from './link';
import BasicTileSubTitle from './subtitle';
import BasicTileTitle from './title';
import BasicTileVideo from './video';

interface BasicTileProps {
  className: string;
  children: React.ReactChild;
  bgColor?: string;
}
interface BasicTileInterface extends React.FunctionComponent<BasicTileProps> {
  Content?: any;
  Description?: any;
  Headline?: any;
  Img?: any;
  Link?: any;
  SubTitle?: any;
  Title?: any;
  Video?: any;
}

const BasicTile: BasicTileInterface = ({ className, children, ...restProps }) => (
  <StyledTile bgColor={restProps.bgColor} className={className}>
    {children}
  </StyledTile>
);

BasicTile.Content = BasicTileContent;
BasicTile.Description = BasicTileDescription;
BasicTile.Headline = BasicTileHeadline;
BasicTile.Img = BasicTileImg;
BasicTile.Link = BasicTileLink;
BasicTile.SubTitle = BasicTileSubTitle;
BasicTile.Title = BasicTileTitle;
BasicTile.Video = BasicTileVideo;

export { BasicTile };
