import styled from 'styled-components/macro';
import ProspectSection from 'components/prospect-profile/prospect-section';

export const HighlightsContent = styled.div`
  h2:first-of-type {
    margin-top: 0;
  }
`;

export const HighlightsTitle = styled(ProspectSection.Title)`
  display: block;
  margin-bottom: 32px;
  margin-top: 48px;
`;

export const AlignmentContent = styled.div`
  display: block;
  background-color: white;
  margin-bottom: 32px;
  padding: 25px;
  font-size: 20px;
`;
