import React from 'react';

import { LargeBadge, SmallBadge } from './elements';

interface NewDonorTagProps {
  className?: string;
  firstPublishedEpoch: number;
  size?: string;
}

/**
 * Component which renders a flag based on a provided Date Epoch. If the provided
 * Epoch is within the same year and month of the current date, the flag will be
 * displayed
 * @param {NewDonorTagProps} props
 * @return {jsx} New flag
 */
const NewDonorTag: React.FC<NewDonorTagProps> = props => {
  const { firstPublishedEpoch, size = 'small' } = props;
  let isNew = false;
  const today = new Date();
  const firstPublishedDateConverted = new Date(firstPublishedEpoch * 1000);

  if (today.getFullYear() === firstPublishedDateConverted.getFullYear()) {
    isNew = firstPublishedDateConverted.getMonth() === today.getMonth();
  }

  const renderSizedBadge = () => {
    return size === 'small' ? <SmallBadge>NEW</SmallBadge> : <LargeBadge>NEW</LargeBadge>;
  };

  return <>{isNew && <div>{renderSizedBadge()}</div>}</>;
};

export { NewDonorTag };
