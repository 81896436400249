import React from 'react';
import { TooltipParagraph } from './elements';

interface ParagraphProps {
  fontStyle: string;
  children?: React.ReactNode;
}

export const Paragraph: React.FunctionComponent<ParagraphProps> = ({ fontStyle, children }) => {
  return <TooltipParagraph fontStyle={fontStyle}>{children}</TooltipParagraph>;
};

export default Paragraph;
