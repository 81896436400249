import React, { useState } from 'react';

import { DropdownContainer, DropdownHeader, DropdownList, DropdownListContainer, ListItem } from './elements';

interface DropdownIconOptions {
  value: string;
  label: string;
}

interface DropdownIconProps {
  id: string;
  className: string;
  onChange: (selectedOption: string) => void;
  options: Array<DropdownIconOptions>;
  children: any;
}

const DropdownIcon: React.FC<DropdownIconProps> = props => {
  const { id, className, children, options, onChange } = props;
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: DropdownIconOptions) => () => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option.value);
  };

  return (
    <DropdownContainer id={id} className={className}>
      <DropdownHeader aria-expanded={isOpen} onClick={toggling}>
        {children}
      </DropdownHeader>
      {isOpen && (
        <DropdownListContainer>
          <DropdownList role="menu">
            {options.map(option => (
              <ListItem
                onClick={onOptionClicked(option)}
                key={option.value}
                selected={selectedOption.value === option.value}
              >
                <button role="menuitem">{option.label}</button>
              </ListItem>
            ))}
          </DropdownList>
        </DropdownListContainer>
      )}
    </DropdownContainer>
  );
};

export { DropdownIcon };
