import styled from 'styled-components/macro';
import { palette } from 'components/styles';
import { xsBody } from 'components/mixins';
import { DropVariants } from './definitions';
import { motion } from 'framer-motion';

export const DropdownContainer = styled('div')`
  position: relative;
  max-width: 256px;
  flex: 1;
  min-width: 0;
`;

interface DropdownInterface {
  variant: DropVariants;
  opened?: boolean;
}

export const DropdownHeader = styled('button')<DropdownInterface>`
  ${xsBody(palette.pureBlack)}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 11px;
  width: 100%;
  background-color: ${props => variantStyles[props.variant].backgroundColor};
  color: ${props => variantStyles[props.variant].color};
  border: ${props => variantStyles[props.variant].border};
  border-bottom: ${props =>
    props.opened ? `1px solid ${variantStyles[props.variant].backgroundColor}` : variantStyles[props.variant].border};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${props => (props.opened ? 0 : '8px')};
  border-bottom-right-radius: ${props => (props.opened ? 0 : '8px')};
  z-index: 11;
  transition: all 300ms ease;

  > p {
    color: ${props => variantStyles[props.variant].color};
    white-space: nowrap;
    overflow: hidden;
    margin-right: 6px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 8px;
    bottom: 0;
    width: calc(100% - 22px);
    height: ${props => (props.opened ? '1px' : '0')};
    background-color: ${palette.pureWhite};
    transition: all 300ms ease;
  }
`;

export const DropdownList = styled.ul<DropdownInterface>`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  ${props => variantStyles[props.variant].color};
  border-left: ${props => variantStyles[props.variant].border};
  border-right: ${props => variantStyles[props.variant].border};
  border-bottom: ${props => variantStyles[props.variant].border};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

interface DropdownListContainerProps {
  position: string;
}

export const DropdownListContainer = styled(motion.div)<DropdownListContainerProps>`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  right: ${props => (props.position === 'RIGHT' ? 0 : 'none')};
  left: ${props => (props.position === 'LEFT' ? 0 : 'none')};
  min-width: 100%;
  width: 100%;
`;

interface ListItemProps extends DropdownInterface {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 8px 11px;
  margin: 0;
  background-color: ${props =>
    props.selected ? variantStyles[props.variant].backgroundHover : variantStyles[props.variant].backgroundColor};
  cursor: pointer;

  &:hover {
    background-color: ${props => variantStyles[props.variant].backgroundHover};
  }

  p,
  p:visited {
    color: ${props => xsBody(variantStyles[props.variant].color)};
  }
`;

export const StyledLabel = styled.label`
  ${xsBody(palette.pureBlack)}
  font-weight: 600;
`;

const darkBGHover = `${palette.brandBlue}`;
const darkBGColor = `${palette.navyBackground}`;
const darkBorder = `1px solid ${palette.pureWhite};`;
const darkColor = `${palette.pureWhite}`;

interface IVariantStyles {
  color: any;
  backgroundColor: any;
  backgroundHover: any;
  border: any;
}

const variantStyles: Record<DropVariants, IVariantStyles> = {
  [DropVariants.DARK]: {
    color: darkColor,
    backgroundColor: darkBGColor,
    border: darkBorder,
    backgroundHover: darkBGHover
  },
  [DropVariants.LIGHT]: {
    color: darkColor,
    backgroundColor: darkBGColor,
    border: darkBGColor,
    backgroundHover: darkBGHover
  }
};
