import React from 'react';

import { ToggleField, ToggleInput, Toggle, ToggleLabel } from './elements';
import { palette } from 'components/styles';
import { motion } from 'framer-motion';

type Icon = {
  stroke: string;
  fill: string;
  props: any;
  type: string;
  key: string;
};

interface IconToggleProps {
  className?: string;
  leftIcon: Icon;
  rightIcon: Icon;
  leftLabel?: string;
  rightLabel?: string;
  toggleHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeToggle: string;
}

const IconToggle: React.FC<IconToggleProps> = props => {
  const { className, leftIcon, rightIcon, leftLabel, rightLabel, toggleHandler, activeToggle } = props;
  const variant = activeToggle === leftLabel ? 0 : 1;
  const toggleRight = activeToggle === rightLabel;
  const toggleLeft = activeToggle === leftLabel;

  return (
    <ToggleField className={className}>
      <ToggleInput
        type="radio"
        id="switch_left"
        name="switchToggle"
        value={leftLabel}
        onChange={toggleHandler}
        checked={toggleLeft}
      />
      <ToggleLabel htmlFor="switch_left">
        <motion.div animate={{ opacity: toggleLeft ? 0 : 1 }}>
          {React.cloneElement(leftIcon, { stroke: palette.gray3, fill: palette.gray3 })}
        </motion.div>
      </ToggleLabel>

      <ToggleInput
        type="radio"
        id="switch_right"
        name="switchToggle"
        value={rightLabel}
        onChange={toggleHandler}
        checked={toggleRight}
      />
      <ToggleLabel htmlFor="switch_right">
        <motion.div animate={{ opacity: toggleRight ? 0 : 1 }}>
          {React.cloneElement(rightIcon, { stroke: palette.gray3, fill: palette.gray3 })}
        </motion.div>
      </ToggleLabel>
      <Toggle animate={{ x: variant * 48 }} transition={{ x: { type: 'spring', damping: 12, stiffness: 100 } }}>
        {toggleLeft && (
          <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            {React.cloneElement(leftIcon, { stroke: palette.pureWhite, fill: palette.pureWhite })}
          </motion.div>
        )}
        {toggleRight && (
          <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            {React.cloneElement(rightIcon, { stroke: palette.pureWhite, fill: palette.pureWhite })}
          </motion.div>
        )}
      </Toggle>
    </ToggleField>
  );
};

export { IconToggle };
