import styled from 'styled-components/macro';
import { xsBody } from 'components/mixins';
import { palette } from 'components/styles';

export const CheckboxLabel = styled.p`
  ${xsBody(palette.pureBlack)}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${palette.pureWhite};
  strokewidth: 2px;
`;

interface StyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${props => (props.checked ? palette.pureBlack : palette.gray4)};
  border-radius: 2px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${palette.lightSkyBlue};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
