import styled from 'styled-components/macro';
import { palette, breakpoints } from 'components/styles';
import { Button, Card } from 'components/ui-components';
import { sectionHeading, body } from 'components/mixins';

export const HeaderWrapper = styled.div`
  display: flex;
  flex: 0 0 55px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid ${palette.gray3};

  h2 {
    ${body(palette.pureBlack)}
    font-weight: 700;

    @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
      ${sectionHeading}
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex: 0 0 70px;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid ${palette.gray3};
`;

export const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
  z-index: 99;
  background-color: ${palette.background};

  &.background.enter {
    opacity: 0;
  }
  &.background.enter-active {
    opacity: 0.6;
    transition: opacity 300ms;
  }
  &.background.exit {
    opacity: 0.6;
  }
  &.background.exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  &.modal.enter {
    opacity: 0;
    transform: translateY(100%);
  }
  &.modal.enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 300ms,
      transform 300ms;
  }
  &.modal.exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.modal.exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition:
      opacity 300ms,
      transform 300ms;
  }
`;

export const ModalCard = styled(Card)`
  position: relative;
  background-color: ${palette.pureWhite};
  max-width: calc(100% - 48px);
  max-height: 95vh;
  margin: 0 24px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: ${breakpoints['tablet-lg']}) {
    max-width: 692px;
    max-height: 80vh;
    padding: 32px;
  }
`;

export const CloseButton = styled(Button)`
  display: flex;
  padding: 8px;
  border: none;
`;
