import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'react-feather';
import find from 'lodash/find';
import Label from './label';
import { DropdownContainer, DropdownHeader, DropdownList, DropdownListContainer, ListItem } from './elements';
import { DropVariants } from './definitions';
import { AnimatePresence } from 'framer-motion';

type DropdownDarkOption = {
  value: string;
  label: string;
  key?: string;
  disabled?: boolean;
};

interface DropdownDarkProps {
  id?: string;
  className?: string;
  options: Array<DropdownDarkOption>;
  onSelectionChange: (option: string) => void;
  value: string;
  dropPosition?: string;
  variant?: DropVariants;
}

interface DropdownDarkInterface extends React.FunctionComponent<DropdownDarkProps> {
  Label?: any;
}

const DropdownDark: DropdownDarkInterface = props => {
  const {
    id,
    className,
    options,
    onSelectionChange,
    value,
    dropPosition = 'RIGHT',
    variant = DropVariants.DARK
  } = props;
  const [selectedOption, setSelectedOption] = useState<DropdownDarkOption>();
  const [isOpen, setIsOpen] = useState(false);
  const fade = {
    initial: { opacity: 0, y: -25 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -25 },
    transition: { duration: 0.3 }
  };

  useEffect(() => {
    setSelectedOption(find(options, { value: value }));
  }, [value, options]);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: DropdownDarkOption) => {
    setIsOpen(false);
    onSelectionChange(option.value);
  };

  return (
    <DropdownContainer id={id} className={className}>
      <DropdownHeader aria-expanded={isOpen} onClick={toggling} variant={variant} opened={isOpen}>
        <p>{selectedOption?.label}</p>
        <ChevronDown size={16} />
      </DropdownHeader>
      <AnimatePresence>
        {isOpen && (
          <DropdownListContainer key="dropContainer" position={dropPosition} {...fade}>
            <DropdownList variant={variant} role="menu">
              {options.map(option => {
                return (
                  <ListItem
                    variant={variant}
                    onClick={() => onOptionClicked(option)}
                    key={option.value}
                    selected={selectedOption?.value === option.value}
                  >
                    <p role="menuitem">{option.label}</p>
                  </ListItem>
                );
              })}
            </DropdownList>
          </DropdownListContainer>
        )}
      </AnimatePresence>
    </DropdownContainer>
  );
};

DropdownDark.Label = Label;

export { DropdownDark };
export * from './definitions';
