import React from 'react';
import styled from 'styled-components/macro';
import { xsBody } from 'components/mixins';
import { palette } from 'components/styles';

interface LabelProps {
  className?: string;
  fontWeight?: number;
  color?: string;
  children?: React.ReactNode;
}

export const StyledLabel: React.FunctionComponent<LabelProps> = ({ className, children }) => {
  return <label className={className}>{children}</label>;
};

const Label = styled(StyledLabel)`
  ${(props: LabelProps) => xsBody(props?.color || palette.pureBlack)}
  font-weight: ${(props: LabelProps) => props.fontWeight};
`;

export default Label;
