import React, { useState, useContext } from 'react';

import { NotificationContext } from 'context/notification-context';
import { postHideProspectToggle } from 'services/services';
import { useMsalUserManager } from '../../hooks';

import { palette } from 'components/styles';
import { Loader } from 'components/ui-components';
import { SvgInvisible, SvgVisible } from 'components/svg-components';
import { ToggleButton, ToggleLabelWrapper, ToggleLabel, IconWrapper } from './elements';

const HideProspectToggle = props => {
  const { className, prospect, matchId, displayLabel = false } = props;
  const { user, instance } = useMsalUserManager();
  const [hideToggle, setHideToggle] = useState(() => prospect.onHideList);
  const [loading, setLoading] = useState(false);
  const { addNewNotification } = useContext(NotificationContext);

  const dispatchNotification = toggle => {
    if (toggle) {
      addNewNotification({
        title: 'Donor Hidden',
        description: 'Donor successfully added to the Hidden Donors List.',
        type: 'success'
      });
    } else {
      addNewNotification({
        title: 'Donor Revealed',
        description: 'Donor now visible on the Donors list.',
        type: 'info'
      });
    }
  };

  const toggleClickedHandler = async event => {
    event.preventDefault();
    const newToggle = !hideToggle;
    setLoading(true);

    setTimeout(async () => {
      try {
        const results = await postHideProspectToggle(user, instance, matchId, newToggle);

        if (results) {
          setHideToggle(newToggle);
          setLoading(false);
          dispatchNotification(newToggle);
        }
      } catch (error) {
        const my_error = error;
        addNewNotification({
          type: 'error',
          title: my_error.title,
          description: my_error.detail
        });
        setLoading(false);
      }
    }, 450);
  };

  return (
    <ToggleButton className={className} onClick={toggleClickedHandler} disabled={loading}>
      <IconWrapper>
        {!loading && !hideToggle && <SvgVisible stroke={palette.pureWhite} fill={palette.brandBlue} />}
        {loading && <Loader size={18} color={palette.pureWhite} />}
        {!loading && hideToggle && <SvgInvisible stroke={palette.pureWhite} fill={palette.brandBlue} />}
      </IconWrapper>

      {displayLabel && (
        <ToggleLabelWrapper>
          <ToggleLabel visible={!hideToggle}>Hide Donor</ToggleLabel>
          <ToggleLabel visible={hideToggle}>Reveal Donor</ToggleLabel>
        </ToggleLabelWrapper>
      )}
    </ToggleButton>
  );
};

HideProspectToggle.propTypes = {};

export default HideProspectToggle;
