import React from 'react';
import { User } from 'react-feather';
import { palette } from 'components/styles';
import { AnimatePresence } from 'framer-motion';
import {
  ConnectionsHeader,
  Chains,
  RightColumn,
  ChainConnectorContent,
  ChainLinkWrapper,
  ChainLinkIcon,
  AvatarWrapper,
  Avatar
} from './elements';
import ProspectProfile from 'models/ProspectProfile/ProspectProfile';
import ProspectMutualConnection, {
  MutualConnectionLink
} from 'models/ProspectMutualConnection/ProspectMutualConnection';

interface PeopleConnectionsProps {
  prospect?: ProspectProfile;
  activeChainIndex?: number;
}

const PeopleConnections: React.FC<PeopleConnectionsProps> = props => {
  const { prospect, activeChainIndex } = props;

  const ChainConnector = (props: any) => {
    const { reason } = props;

    return <ChainConnectorContent>{reason}</ChainConnectorContent>;
  };

  const ChainLink = (props: any) => {
    const { chainData, color } = props;
    return (
      <ChainLinkWrapper>
        <ChainLinkIcon>
          <User size={18} color={color} />
        </ChainLinkIcon>
        <div>
          <p>{chainData.name}</p>
          <p>{chainData.personDetails}</p>
        </div>
      </ChainLinkWrapper>
    );
  };

  const ChainDonor = (props: any) => {
    const { prospect } = props;

    return (
      <AvatarWrapper>
        <Avatar backgroundColor={palette.brandDarkBlue} textColor={palette.pureWhite}>
          <div>
            {prospect.photo && <img src={prospect.photo} alt="prospect-avatar" />}
            {!prospect.photo && <p>{prospect.getDonorInitials()}</p>}
          </div>
        </Avatar>
        <p>{`${prospect.PreferredName}`}</p>
      </AvatarWrapper>
    );
  };

  const Header = (props: any) => {
    const { prospect, mutualConnection } = props;
    return (
      <ConnectionsHeader>
        {prospect.PreferredName}&apos;s connection to {mutualConnection.name}
      </ConnectionsHeader>
    );
  };

  const buildConnectionChain = () => {
    if (
      prospect?.mutualConnections &&
      activeChainIndex !== undefined &&
      prospect?.mutualConnections[activeChainIndex]
    ) {
      const currentChainData: ProspectMutualConnection = prospect?.mutualConnections[activeChainIndex];

      if (currentChainData) {
        const chain = currentChainData.links.map((chainData: MutualConnectionLink, index: number) => {
          return (
            <div key={`${index}-link`}>
              {index > 0 && <ChainConnector reason={chainData.strengthReason} />}
              {<ChainLink chainData={chainData} color={index > 0 ? palette.pureBlack : palette.brandBlue} />}
            </div>
          );
        });

        const lastLink = (
          <div key="last-link">
            <ChainConnector reason={currentChainData.strengthToDonorReason} />
            <ChainDonor key={'donor-link'} prospect={prospect} />
          </div>
        );

        const header = (
          <>
            <Header prospect={prospect} mutualConnection={currentChainData.links[0]}></Header>
          </>
        );

        const slideIn = {
          initial: { opacity: 0, translateX: 800, zIndex: -1 },
          animate: { opacity: 1, translateX: 0, zIndex: 0 },
          exit: { opacity: 0, translateX: 800 },
          transition: { duration: 0.3, ease: 'linear' }
        };

        return (
          <RightColumn key={activeChainIndex} {...slideIn}>
            <>
              {header}
              <Chains>
                {chain}
                {lastLink}
              </Chains>
            </>
          </RightColumn>
        );
      }
    }
  };

  return <AnimatePresence exitBeforeEnter>{buildConnectionChain()}</AnimatePresence>;
};

export default PeopleConnections;
