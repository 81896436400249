import styled from 'styled-components';
import { Button } from 'components/ui-components';
import { xsBody } from 'components/mixins';
import { palette } from 'components/styles';

export const PrintButton = styled(Button)`
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  p {
    ${xsBody(palette.pureWhite)};
    margin-left: 8px;
  }
`;
