import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import times from 'lodash/times';
import ProspectSection from 'components/prospect-profile/prospect-section';
import { BasicTile } from 'components/ui-components';
import { PrintMarginWrapper, BasicTileDateWrapper, TileLink, YearGroup, TileSource } from './elements';

const getGivingYears = () => {
  const thisYear = moment().year();
  const years = [];

  times(thisYear - 2018, index => {
    years.push(thisYear - index);
  });

  return years;
};
const givingYears = getGivingYears();

const PrintProspectGiving = props => {
  const { id, prospect } = props;
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const data = givingYears.map(year => {
      let formattedYearData = { gifts: [] };
      const foundYear = find(prospect.givingHistory, { year: year });
      formattedYearData = foundYear ? { ...foundYear } : { ...formattedYearData, year: year };
      return formattedYearData;
    });

    setYearList(data);
  }, [prospect.givingHistory]);

  const renderContentRows = contentList => {
    let renderedTiles = [];

    if (contentList.length > 0) {
      renderedTiles = contentList.map((item, index) => {
        return (
          <TileLink href="#" border={true} key={index}>
            <BasicTile.Description>
              <strong>{item.org_name}</strong>
            </BasicTile.Description>
            <BasicTileDateWrapper>
              <BasicTile.Description textStyle="italic">{item.date}</BasicTile.Description>
              <BasicTile.Description>{`$${prospect.getFormattedUSDollars(item.amount)}`}</BasicTile.Description>
            </BasicTileDateWrapper>
            <TileSource>{item.org_category}</TileSource>
          </TileLink>
        );
      });
    } else {
      renderedTiles = (
        <TileLink href="#" border={true}>
          <BasicTileDateWrapper>
            <BasicTile.Description textStyle="italic">Highlighted Giving Unavailable</BasicTile.Description>
          </BasicTileDateWrapper>
        </TileLink>
      );
    }

    return renderedTiles;
  };

  const renderYearGroups = () => {
    let yearGroups = [];

    yearGroups = yearList.map(year => {
      if (year.gifts) {
        const sorted = orderBy(year.gifts, ['amount'], ['desc']);
        return (
          <YearGroup key={year.year}>
            <ProspectSection.Title>{year.year}</ProspectSection.Title>
            <div>{renderContentRows(sorted)}</div>
          </YearGroup>
        );
      } else {
        return null;
      }
    });

    return yearGroups;
  };

  return (
    <PrintMarginWrapper>
      <ProspectSection id={id}>
        <ProspectSection.Title>Highlighted Giving</ProspectSection.Title>
        {prospect.givingHistory && prospect.givingHistory.length > 0 && <>{renderYearGroups()}</>}
      </ProspectSection>
    </PrintMarginWrapper>
  );
};

export default PrintProspectGiving;
