import React from 'react';
import PeopleConnections from './people-tab/people-connections';
import PeopleList from './people-tab/people-list';

type config = {
  [key: string]: {
    listComponent: JSX.Element;
    connectionsComponent: JSX.Element;
  };
};

export const ConnectionsConfig: config = {
  PEOPLE: {
    listComponent: <PeopleList />,
    connectionsComponent: <PeopleConnections />
  }
};

export default ConnectionsConfig;
