import styled from 'styled-components/macro';
import { BasicTile } from 'components/ui-components';
import Classification from 'components/classification';
import { baseButton, xsBody } from 'components/mixins';
import { palette } from 'components/styles';
import { motion } from 'framer-motion';

export const ProspectCardBasicTile = styled(BasicTile)`
  border-radius: 10px;
  position: relative;
  height: 100%;
  min-height: 452px;

  h2 {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  h2,
  > p {
    text-align: center;
  }
`;

export const ProspectContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ProspectContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

export const ProspectSkeletonWrapper = styled.div`
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

export const WatchlistToggleIconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const CardClassification = styled(Classification)`
  display: flex;
  width: 100%;
  justify-content: center;

  > p {
    margin-left: 4px;
  }
`;

export const DonorInfoTileContent = styled(BasicTile.Content)`
  flex-direction: column;
  justify-content: flex-end;

  > div {
    margin-bottom: 14px;
  }

  > div p {
    ${xsBody(palette.navyHover)};
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const DropDownButton = styled.button`
  ${baseButton};
  position: relative;
  padding: 10px;
  border: 1px solid ${palette.navyHover};
  min-width: 160px;

  > svg {
    position: absolute;
    top: 16px;
    right: 16px;
}
  }
`;

interface DropdownInterface {
  opened?: boolean;
}

export const DropdownListContainer = styled(motion.div)`
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 50%;
  left: 0;
  min-width: 100%;
  width: 100%;
  background-color: ${palette.navyBackground};
  border: 1px solid ${palette.navyHover};
  border-radius: 8px;
`;

export const DropdownList = styled.ul<DropdownInterface>`
  list-style: none;
  padding: 24px 0 0 0;
  margin: 0;
  overflow: hidden;
`;

interface ListItemProps extends DropdownInterface {
  selected: boolean;
}

export const ListItem = styled('li')<ListItemProps>`
  padding: 8px 11px;
  margin: 4px 0;
  background-color: ${props => (props.selected ? palette.brandBlue : palette.navyBackground)};
  cursor: pointer;
  transition: all 450ms ease-in-out;

  &:hover {
    background-color: ${palette.navyHover};
  }

  > p {
    color: ${xsBody(palette.pureWhite)};
  }
`;

export const ProspectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
