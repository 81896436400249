import React from 'react';
import { Img } from './elements';

interface BasicTileImgProps {
  className: string;
  children: React.ReactChild;
  src: string;
}

const BasicTileImg: React.FC<BasicTileImgProps> = ({ className, children, ...restProps }) => {
  return <Img src={restProps.src}>{children}</Img>;
};

export default BasicTileImg;
