import styled from 'styled-components/macro';
import { breakpoints } from 'components/styles';
import { Container } from 'components/ui-components';

export const PageWrapper = styled.div`
  flex: 1;
`;

export const LayoutContainer = styled(Container)`
  display: grid;
  position: relative;
  flex-direction: row;
  column-gap: 56px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'sidebar'
    'mainContent';

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    grid-template-areas: 'sidebar mainContent';
    grid-template-columns: 255px 1fr;
    column-gap: 32px;
  }

  @media only screen and (min-width: ${breakpoints['container']}) {
    grid-template-areas: 'sidebar mainContent';
    grid-template-columns: 334px 1fr;
    column-gap: 56px;
  }
`;

export const SidebarWrapper = styled.div`
  grid-area: 'sidebar';
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 20;

  @media only screen and (min-width: ${breakpoints['laptop']}) {
    position: inherit;
    padding: 75px 0;
  }
`;

export const MainContentWrapper = styled.div`
  grid-area: 'mainContent';
  padding: 0;
  min-width: 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 48px 0;
`;
