import styled from 'styled-components/macro';
import { xsBody, body } from 'components/mixins';
import { palette } from 'components/styles';
import ProspectSection from 'components/prospect-profile/prospect-section';

export const ContactInfoFlex = styled.div`
  margin-bottom: 24px;
`;

export const SocialLabel = styled.p`
  ${body(palette.pureBlack)}
  margin-top: 32px;
`;

export const SocialIconGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const SocialIconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${palette.brandBlue};
  margin-right: 16px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ModalSubtitle = styled.p`
  ${xsBody(palette.pureBlack)};
`;

export const KnownContactGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
`;

export const ContactTitle = styled(ProspectSection.Title)`
  display: block;
  margin-bottom: 16px;
  margin-top: 48px;
`;

export const ContactSubTitle = styled.h3`
  display: block;
  margin-bottom: 12px;
  margin-top: 12px;
  font-weight: 600;
`;

export const ContactList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  border-bottom: 1px solid ${palette.gray3};
  height: 400px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;
