import styled from 'styled-components/macro';

export const PrintMarginWrapper = styled.div`
  margin-top: -24px;
`;

export const InsightGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 8px;
  margin: 8px 0 8px 0;
  break-inside: avoid;
`;

export const TileWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  break-inside: avoid;

  > div {
    flex: 1;
    margin-top: 16px;
    margin-bottom: 8px;
    min-height: 375px;
  }

  > p {
    flex: 0 0 52px;
  }
`;
