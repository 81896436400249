import styled from 'styled-components';
import { Button } from 'components/ui-components';
import { CenterModal } from 'components/ui-components';
import { sectionHeading, xsBody } from 'components/mixins';
import { palette } from 'components/styles';

export const SessionTimeoutModal = styled(CenterModal)`
  > div > div {
    max-width: 100%;
    max-height: 80vh;
    padding: 32px 0px 32px 0px;
    margin: 0;
    border-radius: 0;
  }
`;

export const SessionTimeoutModalHeader = styled(CenterModal.Header)`
  flex-direction: column;
  justify-content: center;
  border-bottom: none;

  button {
    display: none;
  }

  h2 {
    ${sectionHeading};
  }
`;

export const SessionTimeoutModalFooter = styled(CenterModal.Footer)`
  border-top: none;
  justify-content: center;
`;

interface SessionTimeoutButtonProps {
  buttonStyle: string;
}

export const SessionTimeoutButton = styled(Button)<SessionTimeoutButtonProps>`
  margin-left: 10px;
  color: ${props => (props.buttonStyle === 'primary' ? palette.pureWhite : palette.pureBlack)};
`;

export const ModalContent = styled.div`
  margin: 0px 32px 0px 32px;
  text-align: center;
  p {
    ${xsBody(palette.pureBlack)};
  }
`;

export const SessionIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SessionTimerContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  margin-bottom: 24px;
`;
