import React from 'react';
import { palette } from 'components/styles';
import { HeaderWrapper, CloseButton } from './elements';
import { X } from 'react-feather';

interface Props {
  className?: string;
  children?: React.ReactNode;
  toggleOpened: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Header: React.FunctionComponent<Props> = ({ className, children, ...restProps }) => {
  const { toggleOpened } = restProps;
  return (
    <HeaderWrapper className={className}>
      {children}
      <CloseButton buttonStyle="secondary" onClick={toggleOpened}>
        <X size={18} color={palette.gray1} strokeWidth={2} />
      </CloseButton>
    </HeaderWrapper>
  );
};

export default Header;
