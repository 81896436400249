import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

import { palette } from 'components/styles';

export const ToggleInput = styled.input`
  opacity: 0;
  position: absolute;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
`;

export const ToggleLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
  width: 48px;
  padding: 14px;
`;

export const ToggleField = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${palette.pureWhite};
  border-radius: 8px;
  border: 1px solid ${palette.gray3};
`;

export const Toggle = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  height: 42px;
  width: 50%;
  border-radius: 8px;
  background-color: ${palette.navyBackground};
  position: absolute;
  left: 0;
  top: 0;
`;
