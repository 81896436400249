import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import { AnimatePresence } from 'framer-motion';
import { RootLI, StandardNavLink, ToggleWrapper, DropdownList, DropdownListContainer, ListItem } from './elements';
import { SvgArrowDown } from 'components/svg-components';

type DropdownDarkOption = {
  value: string;
  label: string;
  key?: string;
  disabled?: boolean;
};

interface NavItemProps {
  isActive?: boolean;
  className: string;
  label: string;
  content: string;
  type?: string;
  href: string;
  value?: string;
  options?: Array<DropdownDarkOption>;
  onSelectionChange?: (option: string) => void;
}

const NavItemDropdown: React.FC<NavItemProps> = props => {
  const { isActive = false, className, label, content, href, options, onSelectionChange, value } = props;
  const [selectedOption, setSelectedOption] = useState<DropdownDarkOption>();
  const [isOpen, setIsOpen] = useState(false);
  const fade = {
    initial: { opacity: 0, y: -25 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -25 },
    transition: { duration: 0.3 }
  };

  useEffect(() => {
    setSelectedOption(find(options, { value: value }));
  }, [value, options]);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: DropdownDarkOption) => {
    setIsOpen(false);
    if (onSelectionChange) {
      onSelectionChange(option.value);
    }
  };

  const renderStandardNav = () => {
    const isDropdown = options && options.length > 0 ? true : false;

    return (
      <StandardNavLink to={href} activeRoute={isActive} dropOpen={isOpen} isDropdown={isDropdown} role="menuitem">
        <p>{label}</p>
        <p>{content}</p>
      </StandardNavLink>
    );
  };

  const renderDropdownToggle = () => {
    let renderToggle;

    if (options && options.length > 0) {
      renderToggle = (
        <ToggleWrapper dropOpen={isOpen} onClick={toggling}>
          <SvgArrowDown />
        </ToggleWrapper>
      );
    }

    return renderToggle;
  };

  const renderDropdown = () => {
    let renderDropdown;

    if (options && options.length > 0) {
      renderDropdown = (
        <AnimatePresence>
          {isOpen && (
            <DropdownListContainer key="dropContainer" {...fade}>
              <DropdownList role="menu">
                {options.map(option => {
                  return (
                    <ListItem
                      onClick={() => onOptionClicked(option)}
                      key={option.value}
                      selected={selectedOption?.value === option.value}
                    >
                      <p role="menuitem">{option.label}</p>
                    </ListItem>
                  );
                })}
              </DropdownList>
            </DropdownListContainer>
          )}
        </AnimatePresence>
      );
    }

    return renderDropdown;
  };

  return (
    <RootLI className={className} role="none">
      {renderStandardNav()}
      {renderDropdownToggle()}
      {renderDropdown()}
    </RootLI>
  );
};

export { NavItemDropdown };
