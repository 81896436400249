import { useState, useEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

function getWindowDimensions() {
  const browserWidth = isBrowser ? window.innerWidth : null;
  const browserHeight = isBrowser ? window.innerHeight : null;
  return {
    browserWidth,
    browserHeight
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (isBrowser) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
}
